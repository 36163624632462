import { createContext, useEffect, useState } from "react"

export const AuthContext = createContext({})
export const AuthProvider = ({ children }) => {

    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const userToken = localStorage.getItem("user_token")
        if (userToken) {
            setUser(true)
        }
        setLoading(false)
    }, []);

    const signin = (data, result) => {

        if (result) {
            localStorage.setItem("user_token", JSON.stringify(data.token))
            localStorage.setItem("nome_completo", data.nome_completo)
            localStorage.setItem("foto", data.foto)
            localStorage.setItem("primeiro_login", data.primeiro_login)
            localStorage.setItem("nivel", data.nivel)
            localStorage.setItem("pesquisa_satisfacao", data.pesquisa_satisfacao)
            localStorage.setItem("concluiu_aula", data.concluiu_aula)
            setUser({
                ...data,
                pesquisa_satisfacao: data.pesquisa_satisfacao,
                concluiu_aula: data.concluiu_aula
            })
            return
        }

    };

    const signout = () => {
        setUser(null)
        localStorage.clear()
        return
    };

    return (
        <AuthContext.Provider
            value={{ user, signin, signout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};