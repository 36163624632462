import { Link, useNavigate, useParams } from "react-router-dom";
import Api from "../Auth/Api";
import { useEffect, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import { Modal, Toast } from 'react-bootstrap';
import { decodeJWT, formatTime } from '../Components/jwtUtils';
import TextareaAutosize from "react-textarea-autosize";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

function Modulos() {

    const token = JSON.parse(localStorage.getItem("user_token"))
    const nivel = decodeJWT(token).nivel;
    const navigate = useNavigate()
    const { id } = useParams()
    const [showModalEditarModulo, setShowModalEditarModulo] = useState(false)
    const [moduloEditarFile, setModuloEditarFile] = useState(false)
    const [uploadModuloProgress, setUploadModuloProgress] = useState(0)
    const [toast, setToast] = useState({})
    const [showToast, setShowToast] = useState(false)
    const [thumbEdit, setThumbEdit] = useState(false)
    const [showModalDeletarModulo, setShowModalDeletarModulo] = useState(false)
    const [moduloDeletar, setModuloDeletar] = useState([])
    const [previewUrl, setPreviewUrl] = useState(null);
    const isMobile = window.matchMedia('(max-width: 767px)').matches;

    const handleKeyPress = (e) => {
        const input = e.target;
        setTimeout(() => {
            input.value = formatTime(input.value);
        }, 0);
    };

    const handleThumbnailChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setPreviewUrl(e.target.result);
            }
            reader.readAsDataURL(file);
        }
    }

    const [dataModulos, setDataModulos] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-modulos/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataModulos(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token, id, showToast]);

    const [dataInfoCurso, setDataInfoCurso] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/info-curso/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataInfoCurso(response.data);
            } catch (error) {
                console.error(error.response.data.message)
                navigate("/");
            }
        }
        fetchData();
    }, [token, id, navigate]);

    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm();

    const onSubmitEditarModulo = async (data) => {
        const formData = new FormData();

        Object.keys(data).forEach(key => {
            const value = data[key];
            if (typeof value === 'object' && value !== null && !(value instanceof FileList)) {
                formData.append(key, JSON.stringify(value));
            } else if (value instanceof FileList) {
                Array.from(value).forEach(file => {
                    formData.append(key, file);
                });
            } else {
                formData.append(key, value);
            }
        });


        try {
            const response = await Api.post("/api/editar-modulo", formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setUploadModuloProgress(percentCompleted + "%")
                    if (percentCompleted === 100) {
                        setTimeout(() => {
                            setUploadModuloProgress('Aguarde... finalizando...')
                        }, 1000);
                    }
                }
            });

            setToast({ header: 'Sucesso!', body: response.data.message })
            setUploadModuloProgress(0)
            setShowModalEditarModulo(false)

        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao editar modulo:", error.response.data.message);
        }
        setShowToast(true);


    };

    const deletarModulo = async (id) => {
        try {
            const response = await Api.delete(`/api/deletar-modulo/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message })

        } catch (error) {
            console.error("Erro ao deletar modulo:", error.response.data.message);
            setToast({ header: 'Ops!', body: error.response.data.message })
        }
        setShowToast(true)
        setShowModalDeletarModulo(false)
    }

    // Card em formato de lista para visualização móvel - Design 2025
    const ModuleListItem = ({ value }) => {
        return (
            <div key={value.id} className="col-12 mb-3">
                <div className="card border-0 shadow-sm rounded-4 overflow-hidden">
                    <div className="row g-0">
                        <div className="col-12 position-relative">
                            <div style={{ height: '150px' }}>
                                <img 
                                    src={`${process.env.REACT_APP_IMG_URL}/${value.thumbnail}`} 
                                    className="w-100 h-100" 
                                    style={{ 
                                        objectFit: 'cover',
                                    }}
                                    alt={value.nome} 
                                />
                                {/* Overlay escuro para contraste */}
                                <div className="position-absolute w-100 h-100 top-0 start-0" 
                                    style={{ background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%)' }}>
                                </div>
                                
                                {/* Badge de tipo de aula */}
                                <div className="position-absolute top-0 end-0 m-2">
                                    <span className="badge bg-light bg-opacity-75 text-dark rounded-pill px-2 py-1 fs-9">
                                        {value.qtd_aulas} {value.qtd_aulas > 1 ? "aulas" : "aula"}
                                    </span>
                                </div>
                                
                                {/* Título do módulo sobre a imagem */}
                                <div className="position-absolute bottom-0 start-0 p-3 w-100">
                                    <h6 className="card-title fw-semibold text-white mb-0">{value.nome}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="card-body px-3 py-2">
                                <div className="d-flex justify-content-between align-items-center">
                                    {nivel === 'Aluno' && (
                                        <div className="d-flex align-items-center flex-grow-1 me-3">
                                            <div className="progress flex-grow-1" style={{ height: '6px', borderRadius: '3px' }}>
                                                <div 
                                                    className={`progress-bar ${parseInt(value.porcentagem_concluida) === 100 ? 'bg-success' : 'bg-primary'}`} 
                                                    style={{ width: parseInt(value.porcentagem_concluida) + '%' }}
                                                >
                                                </div>
                                            </div>
                                            <span className="ms-2 text-muted fs-8">{parseInt(value.porcentagem_concluida)}%</span>
                                        </div>
                                    )}
                                    
                                    <div className="d-flex gap-2 align-items-center justify-content-between w-100">
                                        <Link to={`/aulas/${value.id}`} className="btn btn-primary rounded-3 py-1 px-3">
                                            {nivel === "Aluno" ? "Assistir" : "Acessar"}
                                        </Link>

                                        {nivel === 'Administrador' && (
                                            <div className="btn-group">
                                                <Tippy content="Editar Módulo">
                                                    <button 
                                                        onClick={() => {
                                                            setShowModalEditarModulo(true)
                                                            setValue('id', value.id)
                                                            setValue('nome', value.nome)
                                                            setValue('descricao', value.descricao)
                                                            setValue('resumo', value.resumo)
                                                            setValue('carga_horaria', value.carga_horaria)
                                                            setValue('thumbnail', value.thumbnail)
                                                            setModuloEditarFile(null)
                                                            setThumbEdit(value.thumbnail)
                                                            setUploadModuloProgress(0)
                                                            setPreviewUrl(null)
                                                        }} 
                                                        className="btn btn-sm btn-light"
                                                    >
                                                        <i className="bi bi-pencil"></i>
                                                    </button>
                                                </Tippy>

                                                <Tippy content="Excluir Módulo">
                                                    <button 
                                                        onClick={() => { setShowModalDeletarModulo(true); setModuloDeletar({ id: value.id, nome: value.nome }) }} 
                                                        className="btn btn-sm btn-light"
                                                    >
                                                        <i className="bi bi-trash"></i>
                                                    </button>
                                                </Tippy>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    // Card tradicional para desktop
    const ModuleGridItem = ({ value }) => {
        return (
            <div key={value.id} className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-4">
                <div className="mb-4">
                    <div className="card text-white">
                        <div className="card border-0">
                            <img src={`${process.env.REACT_APP_IMG_URL}/${value.thumbnail}`} className="card-img img-banner" style={{ height: '20vh' }} alt={value.titulo} />
                            <div className="card-img-overlay gradient border-bottom-radius-0">
                                <span className="fs-5 fw-semibold text-white d-flex">{value.nome}</span>
                                <span className="text-white">{value.qtd_aulas} {value.qtd_aulas > 1 ? "aulas" : "aula"}</span>
                            </div>
                        </div>
                        <div className="card-body bg-dark">
                            {nivel === 'Aluno' &&
                                <div className="progress w-100 mb-3">
                                    <div className={`progress-bar ${parseInt(value.porcentagem_concluida) === 100 ? 'bg-success' : 'bg-primary'}`} style={{ width: parseInt(value.porcentagem_concluida) + '%' }}>{parseInt(value.porcentagem_concluida)}%</div>
                                </div>
                            }

                            <div className="d-flex justify-content-between">
                                <Link to={`/aulas/${value.id}`} className="btn btn-outline-light link rounded py-1 w-100">
                                    {nivel === "Aluno" ? "Assistir aulas" : "Acessar aulas"}
                                </Link>
                                {nivel === 'Administrador' &&
                                    <div className="d-flex">
                                        <Tippy content="Editar Módulo">
                                            <span onClick={() => {
                                                setShowModalEditarModulo(true)
                                                setValue('id', value.id)
                                                setValue('nome', value.nome)
                                                setValue('descricao', value.descricao)
                                                setValue('resumo', value.resumo)
                                                setValue('carga_horaria', value.carga_horaria)
                                                setValue('thumbnail', value.thumbnail)
                                                setModuloEditarFile(null)
                                                setThumbEdit(value.thumbnail)
                                                setUploadModuloProgress(0)
                                                setPreviewUrl(null)
                                            }} className="d-flex align-items-center rounded link ms-3 bg-white text-primary py-1 px-2 cursor-pointer">
                                                <i className="bi bi-pencil"></i>
                                            </span>
                                        </Tippy>

                                        <Tippy content="Excluir Módulo">
                                            <span onClick={() => { setShowModalDeletarModulo(true); setModuloDeletar({ id: value.id, nome: value.nome }) }} className="d-flex align-items-center rounded link ms-3 bg-white text-primary py-1 px-2 cursor-pointer">
                                                <i className="bi bi-trash"></i>
                                            </span>
                                        </Tippy>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    return (
        <>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide style={{ position: 'fixed', left: '95%', bottom: '10px', transform: 'translateX(-95%)', zIndex: 9999 }}>
                <Toast.Header>
                    <strong className="mr-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>

            <div className="fade-in w-100 mb-5">
                <Link onClick={() => navigate(-1)} className="btn text-primary link border-0 fs-6 ps-0 pb-2">
                    <i className="bi bi-chevron-left"></i> Voltar
                </Link>
                
                {dataInfoCurso[0] && (
                    <>
                        {/* Banner para desktop */}
                        <div className="card card-flag rounded-5 w-100 d-none d-md-block overflow-hidden mb-4 border-none">
                            <div className="position-absolute w-100 h-100" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
                            <img src={`${process.env.REACT_APP_IMG_URL}/${dataInfoCurso[0].thumbnail}`} className="card-img w-100 card-img-banner" alt={dataInfoCurso[0].nome} />
                            <div className="card-img-overlay d-flex flex-column justify-content-between">
                                <div className="text-white fs-4 text-center my-auto">
                                    {dataInfoCurso[0].nome}
                                    <p className="fs-6">{dataInfoCurso[0].resumo}</p>
                                </div>
                                <div className="d-flex info m-3 align-items-start justify-content-between">
                                    <div className="rounded-pill p-1 px-3 opacity fw-semibold me-3">
                                        <span className="text-white">Módulos</span>
                                    </div>
                                    {(nivel === 'Administrador' || nivel === 'Gestor' || nivel === 'RH') &&
                                        <div className="rounded-pill p-1 px-3 bg-white fw-semibold me-3">
                                            <Link to={`/listar-alunos/${id}`} className="text-primary">Análise geral dos alunos</Link>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        {/* Header moderno para mobile - Estilo 2025 */}
                        <div className="d-md-none mb-3">
                            <div className="position-relative rounded-4 overflow-hidden mb-3">
                                <img 
                                    src={`${process.env.REACT_APP_IMG_URL}/${dataInfoCurso[0].thumbnail}`} 
                                    className="w-100" 
                                    style={{ height: '200px', objectFit: 'cover' }} 
                                    alt={dataInfoCurso[0].nome} 
                                />
                                <div className="position-absolute w-100 h-100 top-0 start-0" 
                                    style={{ background: 'linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.8) 80%)' }}>
                                </div>
                                <div className="position-absolute w-100 h-100 top-0 start-0 d-flex align-items-center justify-content-center text-center">
                                    <h6 className="text-white fw-bold mb-0">{dataInfoCurso[0].nome}</h6>
                                </div>
                            </div>
                            
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className="d-flex align-items-center">
                                    <div className="badge bg-primary text-white rounded-pill px-3 py-2">
                                        <i className="bi bi-collection me-1"></i>
                                        Módulos ({dataModulos.length})
                                    </div>
                                </div>
                                
                                {(nivel === 'Administrador' || nivel === 'Gestor' || nivel === 'RH') && (
                                    <Link to={`/listar-alunos/${id}`} className="btn btn-outline-primary rounded-pill">
                                        <i className="bi bi-bar-chart-line me-1"></i> Análise
                                    </Link>
                                )}
                            </div>
                        </div>

                        <div className="px-0 py-1">
                            <section className="w-100 row g-2 mx-0">
                                {
                                    dataModulos.map((value) => (
                                        isMobile ? 
                                            <ModuleListItem key={value.id} value={value} /> : 
                                            <ModuleGridItem key={value.id} value={value} />
                                    ))
                                }
                            </section>
                        </div>
                    </>
                )}
            </div>

            <Modal size="lg" show={showModalEditarModulo} onHide={() => setShowModalEditarModulo(false)}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Editar módulo</span>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmitEditarModulo)}>
                        <input type="hidden" {...register('id', { required: true })} />
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="body">
                                        <div className="d-flex mb-5">
                                            <div className="d-flex flex-column w-100">
                                                <div className="row">

                                                    <div className="col-12 col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Nome</label>
                                                            <input type="text" {...register('nome', { required: true })} placeholder="Nome do curso" className="form-control border-primary" />
                                                            {errors.nome && <span className='text-danger fs-7'>Nome do curso é obrigatório.</span>}
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Descrição</label>
                                                            <TextareaAutosize {...register('descricao')} placeholder="Breve descrição do curso" className="form-control border-primary" />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-4">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Thumbnail</label>
                                                            <label htmlFor="thumbnail" className={`form-control label-input ${moduloEditarFile ? 'border-success bg-success' : 'border-primary bg-primary'} text-white fw-medium text-center`}><i className="bi bi-card-image me-2"></i> Trocar Imagem</label>
                                                            <Controller
                                                                control={control}
                                                                name="thumbnail"
                                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                    <input
                                                                        id='thumbnail'
                                                                        type="file"

                                                                        onBlur={onBlur}
                                                                        ref={ref}
                                                                        onChange={(e) => {
                                                                            const files = e.target.files;
                                                                            handleThumbnailChange(e);
                                                                            onChange(files);
                                                                            if (files && files.length > 0) {
                                                                                setModuloEditarFile(true);
                                                                            } else {
                                                                                setModuloEditarFile(false);
                                                                            }
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            {errors.thumbnail && <span className='text-danger fs-7'>Imagem do curso é obrigatório.</span>}
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-8">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Carga Horária <span className="text-dark">(em horas)</span></label>
                                                            <input type="text" maxLength="5" onKeyDown={handleKeyPress} {...register('carga_horaria')} placeholder="00:00" className="form-control border-primary" />
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="mb-3">
                                                            {previewUrl ?
                                                                <img src={previewUrl} className='card-img card-img-edit rounded-3' alt="Thumbnail" />
                                                                :
                                                                <img src={`${process.env.REACT_APP_IMG_URL}/${thumbEdit}`} className='card-img card-img-edit rounded-3' alt="Thumbnail" />
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Resumo</label>
                                                            <TextareaAutosize {...register('resumo')} placeholder="Informações gerais sobre o módulo" className="form-control border-primary" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="progress col-12">
                                                    <div className="progress-bar" role="progressbar" style={{ width: `${uploadModuloProgress}` }} aria-valuenow={uploadModuloProgress} aria-valuemin="0" aria-valuemax="100">{uploadModuloProgress}</div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center mb-4">
                                            <button type="submit" className="btn btn-primary text-white px-5 py-2">Salvar</button>
                                            <button type="button" className="btn btn-outline-primary text-primary px-5 py-2 ms-4" onClick={() => setShowModalEditarModulo(false)}> Cancelar </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </Modal.Body>
            </Modal>

            <Modal size='lg' show={showModalDeletarModulo} onHide={() => setShowModalDeletarModulo(false)}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Deletar curso</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="body">
                                    <div className="d-flex">
                                        <div className="d-flex flex-column w-100">
                                            <div className="row">
                                                <div className="col-12">
                                                    <p>Tem certeza que deseja deletar o módulo <b>{moduloDeletar.nome}</b>?</p>
                                                    <p>Essa ação ira deletar o módulo e todos seus módulos e aulas, e não poderá ser revertida.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center mb-4">
                                        <button type="submit" className="btn btn-primary text-white px-5 py-2" onClick={() => deletarModulo(moduloDeletar.id)}>Confirmar</button>
                                        <button type="button" className="btn btn-outline-primary text-primary px-5 py-2 ms-4" onClick={() => setShowModalDeletarModulo(false)}>Cancelar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default Modulos;