import React, { useState } from "react";
import { Table, Row, Col, Card, FormControl } from "react-bootstrap";
import './TableInfoAulaGrupo.css';
import Tippy from "@tippyjs/react";
import { formatDateTime } from "./jwtUtils";
import { Link } from "react-router-dom";

const TableInfoAulaGrupo = ({ data }) => {

	const [searchTerm, setSearchTerm] = useState("");
	const token = JSON.parse(localStorage.getItem("user_token"));

	if (!data || data.length === 0) {
		return <div className="d-flex justify-content-center align-items p-5">Nenhum dado disponivel para exibir.</div>;
	}

	const lesson = data;
	const { nomeAula, nomeModulo, nomeGestor, infoUsuarios, data: lessonDate, arquivo } = lesson;

	const filteredUsers = infoUsuarios.filter(user =>
		user.nome.toLowerCase().includes(searchTerm.toLowerCase())
	);

	//Função para fazer download do arquivo
	const fazerDownload = async (arquivo) => {
		const url = `${process.env.REACT_APP_FILES_URL}/${arquivo}&token=${token}`;
		try {
			const response = await fetch(url);
			const data = await response.blob();
			const downloadUrl = window.URL.createObjectURL(data);
			const link = document.createElement('a');
			link.href = downloadUrl;
			// Use o nome do arquivo diretamente ou extraia-o da URL
			const fileName = arquivo.split('/').pop();
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			link.remove();
			window.URL.revokeObjectURL(downloadUrl); // Limpa a URL criada
		} catch (error) {
			console.error('Erro ao fazer download do arquivo:', error);
		}
	};

	return (
		<>
			<Row>
				<Col>
					<div className="lista_grupo">
						<Card.Header>
							<div className="d-flex flex-column justify-content-between">
								<h2 className="d-flex justify-content-between align-items-center mb-0">
									<span>{nomeAula}</span>
									<div>
										<span className="fs-7">{formatDateTime(lessonDate)}</span>
										<div className="container p-0">
											<div className="circle-icon"></div>
											<Link onClick={() => fazerDownload(arquivo)} className="link fs-7 d-block fw-normal">Baixar lista de presença</Link>
										</div>
									</div>
								</h2>
							</div>
							<h6>{nomeModulo}</h6>
							<h6>Gestor: {nomeGestor}</h6>
						</Card.Header>
						<Card.Body>
							<FormControl
								type="text"
								placeholder="Buscar aluno..."
								className="search-input"
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
							/>
							<Table striped bordered hover>
								<thead className="thead-dark">
									<tr>
										<th>Código RE</th>
										<th>Nome</th>
										<th className="text-center">Prova</th>
									</tr>
								</thead>
								<tbody>
									{filteredUsers.map((user) => (
										<tr key={user.codre}>
											<td>{user.codre}</td>
											<td>{user.nome}</td>
											<td className="text-center">
												<Tippy content="Consultar prova">
													<div onClick={() => user.arquivo_prova && fazerDownload(user.arquivo_prova)} className={`fs-6 ${user.arquivo_prova ? 'link cursor-pointer' : 'cursor-disabled text-muted'}`}>Baixar prova</div>
												</Tippy>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Card.Body>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default TableInfoAulaGrupo;
