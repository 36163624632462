import { Link, useLocation } from "react-router-dom";
import { decodeJWT } from "../Components/jwtUtils";
import { useEffect, useState } from "react";
import Api from "../Auth/Api";
import { Modal } from "react-bootstrap";

function Sidebar() {
    const location = useLocation();
    const token = JSON.parse(localStorage.getItem("user_token"));
    const nivel = decodeJWT(token).nivel;
    const [mensagensNaoLidas, setMensagensNaoLidas] = useState([]);
    const [showModalRelatorios, setShowModalRelatorios] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/mensagens-nao-lidas`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setMensagensNaoLidas(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [token]);

    const isActive = (path) => location.pathname.includes(path);

    const menuItems = [
        {
            path: nivel === 'Aluno' ? '/aluno/dashboard' : '/dashboard',
            icon: 'bi-house-door-fill',
            label: 'Home',
            showFor: ['Aluno', 'Administrador', 'Gestor', 'RH'],
            isActive: (path) => isActive('/dashboard') || isActive('/aluno/dashboard')
        },
        {
            path: '/meus-cursos',
            icon: 'bi-book-fill',
            label: 'Cursos',
            showFor: ['Aluno', 'Administrador', 'Gestor', 'RH'],
            isActive: (path) => isActive('/meus-cursos') || isActive('/modulos/') || isActive('/aulas/') || isActive('/aula/')
        },
        {
            path: '/chat',
            icon: 'bi-chat-left-fill',
            label: 'Mensagens',
            showFor: ['Aluno', 'Administrador', 'Gestor', 'RH'],
            isActive: (path) => isActive('/chat'),
            badge: mensagensNaoLidas.mensagens_nao_lidas
        },
        {
            path: nivel === 'Aluno' ? '/aluno/certificado' : '/certificados',
            icon: 'bi-patch-check-fill',
            label: 'Certificado',
            showFor: ['Aluno', 'Administrador', 'Gestor', 'RH'],
            isActive: (path) => isActive('/certificados') || isActive('/aluno/certificado')
        },
        {
            path: '/biblioteca',
            icon: 'bi-hdd-fill',
            label: 'Biblioteca',
            showFor: ['Aluno', 'Administrador', 'Gestor', 'RH'],
            isActive: (path) => isActive('/biblioteca')
        },
        {
            path: '#',
            icon: 'bi-clipboard2-data-fill',
            label: 'Relatórios',
            showFor: ['Administrador', 'Gestor', 'RH'],
            isActive: (path) => isActive('/relatorios/grupo') || isActive('/relatorios/aluno') || isActive('/relatorios/acompanhamento'),
            onClick: () => setShowModalRelatorios(true)
        },
        {
            path: '/calendario',
            icon: 'bi-calendar-fill',
            label: 'Calendário',
            showFor: ['Aluno'],
            isActive: (path) => isActive('/calendario')
        }
    ];

    return (
        <>
            <div className={`sidebar-content`}>
                <div className="d-flex flex-md-column align-items-center sidenav">
                    <div className="nav-brand d-none d-md-flex">
                       
                    </div>

                    <div className="d-flex flex-md-column justify-content-around w-100">
                        {menuItems.map((item, index) => (
                            item.showFor.includes(nivel) && (
                                <Link
                                    key={index}
                                    to={item.path}
                                    onClick={item.onClick}
                                    className="sidebar-link"
                                    title={item.label}
                                >
                                    <div className={`menu-item-container ${item.isActive(item.path) ? 'menu-item-active' : ''}`}>
                                        <div className="icon-container position-relative">
                                            <i className={`menu-item bi ${item.icon}`}></i>
                                            {item.badge > 0 && (
                                                <span className="notification-badge">
                                                    {item.badge}
                                                </span>
                                            )}
                                        </div>
                                        <span className="menu-label">{item.label}</span>
                                    </div>
                                </Link>
                            )
                        ))}
                    </div>
                </div>
            </div>

            <Modal size="lg" show={showModalRelatorios} onHide={() => setShowModalRelatorios(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center w-100">
                        Relatórios
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: "#f9f9f9" }}>
                    <div className="container mt-2">
                        <div className="text-center mb-4">
                            <h5>Selecione um Relatório</h5>
                            <p className="text-muted">Escolha o tipo de relatório que você deseja visualizar.</p>
                        </div>
                        <div className="list-group">
                            <Link
                                to="/relatorios/grupo"
                                onClick={() => setShowModalRelatorios(false)}
                                className="list-group-item list-group-item-action d-flex align-items-center p-3"
                            >
                                <i className="bi bi-people-fill display-4 text-success me-3"></i>
                                <div>
                                    <h5 className="mb-0">Aulas em Grupo</h5>
                                    <small className="text-muted">Visualize o progresso das aulas em grupo.</small>
                                </div>
                            </Link>
                            <Link
                                to="/relatorios/aluno"
                                onClick={() => setShowModalRelatorios(false)}
                                className="list-group-item list-group-item-action d-flex align-items-center p-3"
                            >
                                <i className="bi bi-person-lines-fill display-4 text-primary me-3"></i>
                                <div>
                                    <h5 className="mb-0">Progresso do Aluno</h5>
                                    <small className="text-muted">Acompanhe o progresso individual dos alunos.</small>
                                </div>
                            </Link>
                            <Link
                                to="/relatorios/acompanhamento"
                                onClick={() => setShowModalRelatorios(false)}
                                className="list-group-item list-group-item-action d-flex align-items-center p-3"
                            >
                                <i className="bi bi-credit-card-2-front display-4 text-info me-3"></i>
                                <div>
                                    <h5 className="mb-0">Acompanhamento de Treinamentos</h5>
                                    <small className="text-muted">Monitore o andamento e desempenho dos treinamentos.</small>
                                </div>
                            </Link>
                            {(nivel === 'Administrador' || nivel === 'RH') && (
                                <Link
                                    to="/relatorios/feedback"
                                    onClick={() => setShowModalRelatorios(false)}
                                    className="list-group-item list-group-item-action d-flex align-items-center p-3"
                                >
                                    <i className="bi bi-graph-up display-4 text-warning me-3"></i>
                                    <div>
                                        <h5 className="mb-0">Estatísticas de Feedback</h5>
                                        <small className="text-muted">Visualize as estatísticas das pesquisas de satisfação.</small>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <button className="btn btn-outline-primary" onClick={() => setShowModalRelatorios(false)}>
                        Fechar
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Sidebar;