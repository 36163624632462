import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import styled from 'styled-components';
import { isSameDay } from 'date-fns';
import { Modal, Toast } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
import Api from '../Auth/Api';
import { Linkify, decodeJWT } from '../Components/jwtUtils';
import TextareaAutosize from "react-textarea-autosize";

function Calendario() {

    const customStyles = {
        control: (base, state) => ({
            ...base,
            fontSize: "1rem",
            backgroundColor: "#ffffff",
            borderRadius: "0.25rem",
            boxShadow: state.isFocused ? "0 0 0 0.25rem #27485b73" : null,
            borderColor: "#233734",
            "&:hover": {
                borderColor: "#3a6883"
            },
        }),
        singleValue: (base) => ({
            ...base,
            color: '#000',
        }),
        input: (base) => ({
            ...base,
            color: '#adb5bd',
            height: "52px",
            margin: '0px',
            padding: '0px !important',

        }),
        menu: (base) => ({
            ...base,
            backgroundColor: '#fff',
        }),
        option: (base, state) => ({
            ...base,
            color: state.isSelected ? '#3a5a6c' : null,
            backgroundColor: state.isFocused ? '#27485b73' : null,
            "&:active": {
                backgroundColor: "#27485b73"
            },
        }),
    };

    const [showModalDeletarAgendamento, setShowModalDeletarAgendamento] = useState();
    const [agendamentoDeletar, setAgendamentoDeletar] = useState([]);
    const [value, onChange] = useState(new Date());
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showModalAddAgenda, setShowModalAddAgenda] = useState(false);
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [dataEventos, setDataEventos] = useState([]);

    const token = JSON.parse(localStorage.getItem("user_token"));
    const nivel = decodeJWT(token).nivel;

    // Abreviar o dia da semana
    const formatShortWeekday = (locale, date) => {
        // Retorna os primeiros 3 caracteres do dia da semana
        return date.toLocaleString(locale, { weekday: 'short' }).slice(0, 3);
    };

    // Lista todos os eventos
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-agendamentos`, { headers: { Authorization: `Bearer ${token}` } });
                const updatedData = response.data.map(item => ({
                    ...item,
                    datetime: new Date(item.datetime)
                }));
                setDataEventos(updatedData);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token, toast]);

    const handleDeleteAgendamento = async (id) => {
        try {
            const response = await Api.delete(`/api/deletar-agendamento/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message })

        } catch (error) {
            console.error("Erro ao deletar agendamento:", error.response.data.message);
            setToast({ header: 'Ops!', body: error.response.data.message })
        }
        setShowToast(true)
        setShowModalDeletarAgendamento(false)
        setShowModal(false)
    }

    const MeuCalendario = styled.div`
        .react-calendar__month-view__days__day {
            width: 70px;
            min-height: 120px;
        }
        @media (max-width: 769px) {
            .react-calendar__month-view__days__day {
                width: 70px;
                min-height: 50px;
            }
        }
        `;

    function openModal(event) {
        setSelectedEvent(event);
        setShowModal(true);
    }

    function closeModal() {
        setShowModal(false);
    }

    const {
        register: register_A,
        handleSubmit: handleSubmit_A,
        control: control_A,
        reset: reset_A,
        // setValue: setValue_A,
        formState: { errors: errors_A },
    } = useForm();

    const onSubmitAddAgenda = async (data) => {

        try {
            const response = await Api.post("/api/novo-agendamento", data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message })
            reset_A()

        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao criar novo agendamento:", error);
        }
        setShowToast(true)
    };

    const [dataCursos, setDataCursos] = useState([]);
    useEffect(() => {

        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-cursos`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataCursos(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();


    }, [token]);

    const [dataGestor, setDataGestor] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-usuarios/nivel/Gestor/todos`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataGestor(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [token]);

    return (
        <div className="fade-in w-100">
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide style={{ position: 'fixed', left: '95%', bottom: '10px', transform: 'translateX(-95%)', zIndex: 9999 }}>
                <Toast.Header>
                    <strong className="mr-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>

            <div className="header d-flex flex-column flex-md-row justify-content-between mt-4 mt-md-0">
                <div className="d-flex flex-column">
                    <h1 className="fw-bold mb-0">Calendário</h1>
                    <p>Consulte os eventos, avisos, atividades e provas programadas.</p>
                </div>

                {nivel !== "Aluno" &&
                    <button className="btn btn-primary px-4 text-white align-self-start" onClick={() => { setShowModalAddAgenda(true) }}>
                        Marcar evento
                    </button>
                }

            </div>
            <div className="body rounded bg-white px-3 py-3 mb-5">
                <section className="w-100">
                    <MeuCalendario>
                        <Calendar
                            onChange={onChange}
                            value={value}
                            formatShortWeekday={formatShortWeekday}
                            tileContent={({ date }) => {
                                const eventsInDay = dataEventos.filter(e => isSameDay(e.datetime, date));
                                return eventsInDay.map(event => (
                                    <div
                                        key={event.nome_agendamento}
                                        className="event-button"
                                        onClick={() => openModal(event)}
                                    >
                                        {event.nome_agendamento}
                                    </div>
                                ));
                            }}
                        />
                    </MeuCalendario>

                    {showModal &&
                        <EventModal
                            event={selectedEvent}
                            nivel={nivel}
                            closeModal={closeModal}
                            showModal={showModal}
                            setShowModalDeletarAgendamento={setShowModalDeletarAgendamento}
                            setAgendamentoDeletar={setAgendamentoDeletar}
                        />
                    }
                </section>
            </div>

            <Modal size="lg" show={showModalAddAgenda} onHide={() => { setShowModalAddAgenda(false) }}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Agendar um aviso</span>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit_A(onSubmitAddAgenda)}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="body">
                                    <div className="d-flex mb-5">
                                        <div className="d-flex flex-column w-100">

                                            <div className="row mb-3">
                                                <div className="col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label text-primary fw-medium">Titulo</label>
                                                        <input type="text" {...register_A('nome', { required: true })} placeholder="Título" className="form-control border-primary" />
                                                        {errors_A.nome && <span className='text-danger fs-7'>Título é obrigatório.</span>}
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label text-primary fw-medium">Descrição</label>
                                                        <TextareaAutosize rows="4" {...register_A('descricao')} placeholder="Descrição do evento" className="form-control border-primary" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label text-primary fw-medium">Curso</label>
                                                        <Controller
                                                            name="cursos"
                                                            control={control_A}
                                                            defaultValue={[]}
                                                            rules={{ required: true }}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={dataCursos.map((value) => {
                                                                        return {
                                                                            value: value.id,
                                                                            label: `${value.nome}`,
                                                                        };
                                                                    })}
                                                                    value={field.value}
                                                                    styles={customStyles}
                                                                    onChange={value => field.onChange(value)}
                                                                    placeholder="Selecione os cursos"
                                                                />
                                                            )}
                                                        />
                                                        {errors_A.cursos && <span className='text-danger fs-7'>Selecione pelo menos um curso.</span>}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label text-primary fw-medium">Gestor</label>
                                                        <Controller
                                                            name="idGestor"
                                                            control={control_A}
                                                            defaultValue={[]}
                                                            rules={{ required: true }}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={dataGestor.map((value) => {
                                                                        return {
                                                                            value: value.id,
                                                                            label: `${value.nome_completo}`,
                                                                        };
                                                                    })}
                                                                    value={field.value}
                                                                    styles={customStyles}
                                                                    onChange={value => field.onChange(value)}
                                                                    placeholder="Selecione o Gestor"
                                                                    isSearchable
                                                                    noOptionsMessage={() => "Nenhum tutor encontrado."}
                                                                />
                                                            )}
                                                        />
                                                        {errors_A.idGestor && <span className='text-danger fs-7'>Gestor é obrigatório.</span>}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label text-primary fw-medium">Data do evento</label>
                                                        <input
                                                            // type="datetime-local"
                                                            type="date"
                                                            className="form-control border-primary"
                                                            {...register_A('datetime', { required: true })}
                                                        />
                                                        {errors_A.datetime && <span className='text-danger fs-7'>Data é obrigatório.</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="d-flex justify-content-center mb-4">
                            <button type="submit" className="btn btn-primary text-white px-5 py-2">Salvar</button>
                            <button type="button" className="btn btn-outline-primary text-primary px-5 py-2 ms-4" onClick={() => { setShowModalAddAgenda(false) }}> Cancelar </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>

            <Modal size='lg' show={showModalDeletarAgendamento} onHide={() => setShowModalDeletarAgendamento(false)}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Deletar agendamento</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="body">
                                    <div className="d-flex">
                                        <div className="d-flex flex-column w-100">
                                            <div className="row">
                                                <div className="col-12">
                                                    <p>Tem certeza que deseja deletar o agendamento <b>{agendamentoDeletar.nome}</b>?</p>
                                                    <p>Essa ação ira deletar o curso e todos seus módulos e aulas, e não poderá ser revertida.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center mb-4">
                                        <button type="submit" className="btn btn-primary text-white px-5 py-2" onClick={() => handleDeleteAgendamento(agendamentoDeletar.id)}>Confirmar</button>
                                        <button type="button" className="btn btn-outline-primary text-primary px-5 py-2 ms-4" onClick={() => setShowModalDeletarAgendamento(false)}>Cancelar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    );

}

function EventModal({ event, nivel, closeModal, showModal, setShowModalDeletarAgendamento, setAgendamentoDeletar }) {

    return (
        <Modal size="lg" show={showModal} onHide={closeModal}>

            <Modal.Header closeButton>
                <Modal.Title>
                    <span onClick={() => { setShowModalDeletarAgendamento(true); setAgendamentoDeletar({ id: event.id, nome: event.nome_agendamento }) }} className='cursor-pointer'><i className={`bi bi-trash3-fill ${nivel === "Aluno" && 'd-none'}`}></i></span> {event.nome_agendamento} - {event.nome_curso}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>
                    <Linkify text={event.descricao}/>
                </p>
            </Modal.Body>
            <Modal.Footer className="bg-light">
                <p>por {event.nome_tutor}</p>
            </Modal.Footer>

        </Modal>
    );
}


export default Calendario;