import { useState, useEffect } from "react";
import Select from "react-select";
import Api from "../../Auth/Api";
import * as XLSX from 'xlsx';

function RelatorioAluno() {
	const token = JSON.parse(localStorage.getItem("user_token"));

	const [alunos, setAlunos] = useState([]);
	const [filteredAlunos, setFilteredAlunos] = useState([]);
	const [selectedAluno, setSelectedAluno] = useState(null);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [hasFiltered, setHasFiltered] = useState(false);

	// Carregar todos os alunos disponíveis na primeira vez
	useEffect(() => {
		async function fetchAlunos() {
			try {
				const response = await Api.get(`/api/show/usuarios`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				const alunosData = response.data.map(aluno => ({
					value: aluno.codigo,
					label: `${aluno.nome_completo} (${aluno.codigo})`
				}));
				setAlunos(alunosData);
			} catch (error) {
				console.log("Erro ao carregar alunos:", error.response.data.message);
			}
		}
		fetchAlunos();
	}, [token]);

	const handleInputChange = (newValue) => {
		const value = newValue.toLowerCase();
		if (value.length >= 3) {
			setFilteredAlunos(
				alunos.filter(aluno =>
					aluno.label.toLowerCase().includes(value)
				)
			);
		} else {
			setFilteredAlunos([]);
		}
	};

	const handleAlunoChange = async (selectedOption) => {
		setSelectedAluno(selectedOption);
		setLoading(true);
		try {
			const response = await Api.get(`/api/relatorio/aluno/${selectedOption.value}`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});
			setData(response.data);
			setHasFiltered(true);
		} catch (error) {
			console.log("Erro ao carregar o relatório do aluno:", error.response.data.message);
		} finally {
			setLoading(false);
		}
	};

	// Função para exportar para Excel
	const handleExportExcel = () => {
		if (!data) return;

		const dataForExcel = data.treinamentos.flatMap(treinamento =>
			treinamento.modulos.map(modulo => ({
				"Nome do Treinamento": treinamento.nome_treinamento,
				"Tipo de Treinamento": treinamento.tipo_treinamento.charAt(0).toUpperCase() + treinamento.tipo_treinamento.slice(1),
				"Nome do Módulo": modulo.nome_modulo,
				"Unidade": modulo.unidade,
				"Status Treinamento": modulo.status_treinamento,
				"Etapa Teórica": modulo.etapa_teorica,
				"Data Teoria": modulo.data_teoria || "-",
				"Nota Avaliação": modulo.nota_avaliacao,
				"Data Avaliação": modulo.data_avaliacao || "-",
				"Data Prática": treinamento.praticas?.[0]?.data_pratica || "-",
				"Emissão Certificado": treinamento.certificado.emissao_certificado,
				"Data Emissão Certificado": treinamento.certificado.data_emissao_certificado || "-"
			}))
		);

		// Cria uma nova planilha
		const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, `Relatorio_${data.aluno.matricula}`);

		// Gera um arquivo Excel e faz o download
		XLSX.writeFile(workbook, `relatorio_aluno_${data.aluno.matricula}.xlsx`);
	};

	return (
		<>
			<div className="fade-in w-100 mt-3" style={{ marginBottom: '100px' }}>
				<span className="text-primary fs-1">Relatório do Aluno</span>
				<p className="fs-6">Selecione um aluno para visualizar e exportar os detalhes de desempenho e certificações.</p>

				{/* Filtro para selecionar o aluno */}
				<div className="filters mb-4">
					<div className="row">

						<div className="col-md-6">
							<Select
								name="aluno"
								options={filteredAlunos}
								classNamePrefix="select"
								placeholder="Digite pelo menos 3 dígitos para buscar..."
								value={selectedAluno}
								onChange={handleAlunoChange}
								onInputChange={handleInputChange}
								isLoading={loading}
								noOptionsMessage={() => "Digite pelo menos 3 dígitos para buscar"}
							/>
						</div>
					</div>
				</div>
				{hasFiltered && (
					<button className="btn btn-secondary mb-3" onClick={handleExportExcel}>
						<i className="bi bi-table"></i> | Baixar Excel
					</button>
				)}

				{loading && (
					<div className="d-flex justify-content-center">
						<div className="loader"></div>
					</div>
				)}

				{hasFiltered && data && (
					<>
						{/* Cabeçalho do Aluno */}
						<div className="mb-4">
							<h4>{data.aluno.nome_aluno}</h4>
							<p><strong>Matrícula:</strong> {data.aluno.matricula}</p>
							<p><strong>Unidade:</strong> {data.aluno.unidade}</p>
						</div>

						{/* Tabela de Módulos */}
						{data.treinamentos.map((treinamento, idx) => (
							<div className="table-full-width mt-3 mb-3" key={idx}>
								<div className="table-responsive">
									<div className="mb-5">

										<table className="table align-middle mt-1 table-striped table-hover">
											<thead>
												<tr>
													<th colSpan="7" className='bg-primary text-white rounded-top'>{treinamento.nome_treinamento} ({treinamento.tipo_treinamento})</th>
												</tr>
											</thead>
											<thead>
												<tr className='border border-white text-nowrap'>
													<th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Nome do Módulo</th>
													<th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Unidade</th>
													<th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Status Treinamento</th>
													<th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Etapa Teórica</th>
													<th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Data Teoria</th>
													<th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted text-center'>Nota Avaliação</th>
													<th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Data Avaliação</th>
												</tr>
											</thead>
											<tbody>
												{treinamento.modulos.map((modulo, modIdx) => (
													<tr key={modIdx}>
														<td>{modulo.nome_modulo}</td>
														<td>{modulo.unidade}</td>
														<td>{modulo.status_treinamento}</td>
														<td>{modulo.etapa_teorica}</td>
														<td>{modulo.data_teoria || "-"}</td>
														<td className="text-center">{modulo.nota_avaliacao}</td>
														<td>{modulo.data_avaliacao || "-"}</td>
													</tr>
												))}
											</tbody>
											<tfoot>
												<tr>
													<th colSpan="7" className='bg-primary text-white fw-normal rounded-bottom'>
														<div className="table-footer d-flex justify-content-between align-items-center">
															<div>
																{treinamento.praticas?.[0] && (
																	<>
																		Prática: {treinamento.praticas[0].data_pratica} | Local: {treinamento.praticas[0].local} | Gestor: {treinamento.praticas[0].gestor}
																	</>
																)}
															</div>
															<div className="d-flex align-items-center">
																Certificado: {treinamento.tipo_treinamento === 'misto' && !treinamento.praticas?.[0] ? 'Não' : treinamento.certificado.emissao_certificado}
																{treinamento.certificado.emissao_certificado === 'Sim' && 
																  (treinamento.tipo_treinamento !== 'misto' || 
																   (treinamento.tipo_treinamento === 'misto' && treinamento.praticas?.[0])) && (
																	<div className="ms-3">Data de Emissão: {treinamento.certificado.data_emissao_certificado}</div>
																)}
															</div>
														</div>
													</th>
												</tr>
											</tfoot>
										</table>

									</div>
								</div>
							</div>
						))}
					</>
				)}
			</div>
		</>
	);
}

export default RelatorioAluno;