import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Api from "../Auth/Api";
import 'vidstack/styles/defaults.css';
import 'vidstack/styles/community-skin/video.css';

import { MediaCommunitySkin, MediaOutlet, MediaPlayer, MediaPoster } from '@vidstack/react';


function Aula() {

    const navigate = useNavigate();
    const token = JSON.parse(localStorage.getItem("user_token"));
    const { id } = useParams();
    const tempoAula = process.env.REACT_APP_TEMPO_AULA;
    const isMobileAndHorizontal = window.matchMedia('(orientation: landscape)').matches;
    const isMobile = window.matchMedia('(max-width: 767px)').matches;
    const [isFullscreen, setIsFullscreen] = useState(false);

    const [dataInfoAula, setDataInfoAula] = useState([]);
    const arquivoPrincipal = dataInfoAula[0] && JSON.parse(dataInfoAula[0].arquivos)[0]
    const validExtensions = ["mp4", "avi", "mkv", "mov"]

    let urlPrincipal = '';
    const html_externo = dataInfoAula[0] && dataInfoAula[0].html_externo;
    if (html_externo) {
        urlPrincipal = html_externo;
    } else {
        urlPrincipal = `${process.env.REACT_APP_FILES_URL}/${arquivoPrincipal}&token=${token}`
    }

    let listaArquivos = [];

    if (dataInfoAula[0] && dataInfoAula[0].arquivos) {
        const parsedData = JSON.parse(dataInfoAula[0].arquivos);
        if (Array.isArray(parsedData)) {
            listaArquivos = parsedData;
        }
    }

    // Agora, você pode prosseguir com as operações no array, como filtragem:
    listaArquivos = listaArquivos.filter(item => item);

    // Remover o item com índice 0
    listaArquivos.splice(0, 1);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/info-aula/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataInfoAula(response.data);
            } catch (error) {
                console.error(error.response.data.message)
                navigate("/");
            }
        }

        fetchData();
    }, [token, id, navigate]);


    useEffect(() => {
        const arquivo = dataInfoAula[0] && JSON.parse(dataInfoAula[0].arquivos)[0];
        if (arquivo !== undefined) {
            setTimeout(async () => {
                await Api.post(`api/progresso-aula/${id}`, { data: 'assistida' }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            }, tempoAula * 60 * 1000);
        }
    }, [dataInfoAula, id, token, tempoAula]);

    const toggleFullscreen = () => {
        setIsFullscreen(!isFullscreen);
    };

    return (
        <>
            {dataInfoAula[0] &&
                <div className="fade-in w-100 mb-5">

                    {!isFullscreen && (
                        <Link onClick={() => navigate(-1)} className="btn text-primary link border-0 fs-6 my-3 my-md-0">
                            <i className="bi bi-chevron-left"></i> Voltar
                        </Link>
                    )}

                    {arquivoPrincipal !== undefined &&
                        <div className={`body ${isFullscreen ? 'fullscreen-body' : ''}`}>
                            <section className="w-100 position-relative">
                                {validExtensions.includes(dataInfoAula[0].extensao) ? (
                                    <div className="video-container rounded-3 overflow-hidden w-100 bg-dark position-relative">
                                        <div className="video-player-container" style={{ zoom: 0.8 }}>
                                            <MediaPlayer
                                                title={dataInfoAula[0].titulo}
                                                src={urlPrincipal}
                                                aspectRatio={16 / 9}
                                                crossorigin="allow"
                                            >
                                                <MediaOutlet>
                                                    <MediaPoster alt={dataInfoAula[0].titulo} />
                                                </MediaOutlet>
                                                <MediaCommunitySkin />
                                            </MediaPlayer>
                                        </div>
                                    </div>
                                ) : (
                                    <div 
                                        className="iframe-container rounded-3 overflow-hidden w-100 bg-dark" 
                                        style={{
                                            position: isFullscreen ? 'fixed' : 'relative',
                                            top: isFullscreen ? 0 : 'auto',
                                            left: isFullscreen ? 0 : 'auto',
                                            width: isFullscreen ? '100%' : '100%',
                                            height: isFullscreen ? '100%' : 'auto',
                                            zIndex: isFullscreen ? 9999 : 'auto',
                                        }}
                                    >
                                        <div className="responsive-iframe-wrapper" 
                                             style={{
                                                 position: 'relative',
                                                 paddingTop: isFullscreen ? '100vh' : (isMobile && isMobileAndHorizontal ? '80vh' : '56.25%'),
                                                 width: '100%',
                                                 backgroundColor: '#000',
                                                 maxHeight: isMobile && isMobileAndHorizontal ? '100vh' : 'none'
                                             }}>
                                            <iframe
                                                className="responsive-iframe"
                                                src={urlPrincipal}
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    border: 'none',
                                                    objectFit: 'contain'
                                                }}
                                                title={dataInfoAula[0].titulo}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                        <button 
                                            onClick={toggleFullscreen}
                                            className="btn btn-sm btn-primary"
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '10px',
                                                zIndex: 10000,
                                                opacity: 0.7,
                                            }}
                                        >
                                            {isFullscreen ? (
                                                <i className="bi bi-fullscreen-exit"></i>
                                            ) : (
                                                <i className="bi bi-fullscreen"></i>
                                            )}
                                        </button>
                                    </div>
                                )}
                            </section>
                        </div>
                    }

                    {!isFullscreen && (
                        <div className="card mt-3 mb-5 py-3 px-4 bg-primary text-white back-image border-none">
                            <div className="d-flex align-items-center row g-0">
                                {
                                    dataInfoAula[0].arquivos &&
                                    listaArquivos.map((value, index) => {
                                        return (
                                            <div key={index} className="col-md-3 overflow-hidden bg-white text-center rounded m-2">
                                                <span className="d-flex bg-grey w-100 space-grey"></span>
                                                <Link target="_blank" to={`${process.env.REACT_APP_FILES_URL}/${value}&token=${token}`}>
                                                    <button type="button" className="btn fs-6 text-primary link py-3">
                                                        Baixar conteúdo <i className="bi bi-download ms-2"></i>
                                                    </button>
                                                </Link>
                                            </div>
                                        )
                                    })
                                }


                                <div className="col-md-3">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                            <span className="fs-4 fw-500">{dataInfoAula[0].titulo}</span>
                                        </div>
                                        <span className="fw-light text-break">
                                            {dataInfoAula[0].descricao}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div >
            }

            <style jsx>{`
                .fullscreen-body {
                    position: relative;
                    z-index: 9998;
                }
                
                @media (max-width: 767px) {
                    .btn-primary {
                        padding: 0.25rem 0.5rem;
                        font-size: 0.875rem;
                    }
                }
            `}</style>
        </>
    )
}

export default Aula;