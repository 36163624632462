import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'
import React from 'react';
import { decodeJWT } from './Components/jwtUtils';

//Auth
import useAuth from "./Auth/hook_useAuth";
import { setupInterceptors } from './Auth/Api';

//Context
import { AjustesProvider, useAjustes } from './Context/AjustesContext';

//Components
import LoadingScreen from './Components/LoadingScreen';

//Layout
import Header from './Layout/Header';
import Content from './Components/Content';
import Sidebar from './Layout/Sidebar';
import ContainerFlex from './Components/ContainerFlex';
import ContainerBox from './Components/ContainerBox';

//Paginas
import Dashboard from './Pages/Dashboard';
import ListaAlunos from './Pages/ListaAlunos';
import MeusCursos from './Pages/MeusCursos';
import Calendario from './Pages/Calendario';
import Questionario from './Pages/Questionario';
import Modulos from './Pages/Modulos';
import Aulas from './Pages/Aulas';
import Aula from './Pages/Aula';
import Certificados from './Pages/Certificados';
import Biblioteca from './Pages/Biblioteca';
import Configuracoes from './Pages/Configuracoes';
import Chat from './Pages/Chat';
import Login from './Pages/Login';
import Provas from './Pages/Provas';
import SemPermissao from './Pages/SemPermissao';
import ListaUsuarios from './Pages/ListaUsuarios';
import Recuperar from './Pages/Recuperar';
import PrimeiroLogin from './Pages/PrimeiroLogin';
import Topicos from './Pages/Topicos';
import TrocarSenha from './Pages/TrocarSenha';
import AulaGrupo from './Pages/AulaGrupo';
import RelatorioGrupo from './Pages/Relatorios/Grupo';
import RelatorioAcompanhamento from './Pages/Relatorios/Acompanhamento';
import RelatorioAluno from './Pages/Relatorios/Aluno';
import PesquisaSatisfacao from './Pages/PesquisaSatisfacao';
import RelatorioFeedback from './Pages/Relatorios/Feedback';
import Ajustes from './Pages/Ajustes';

//Paginas Aluno
import DashboardAluno from './Pages/Aluno/DashboardAluno';
import Certificado from './Pages/Aluno/Certificado';
import HistoricoEscolar from './Pages/HistoricoEscolar';
import HistoricoEscolarAluno from './Pages/Aluno/HistoricoEscolarAluno';

//Paginas Gestor
import ListaUsuariosGestor from './Pages/Gestor/ListaUsuariosGestor';
import Resetar from './Pages/Resetar';

function MultiRoleRoute({ allowedRoles, children }) {
    const token = JSON.parse(localStorage.getItem("user_token"));
    const primeiro_login = localStorage.getItem("primeiro_login");
    const pesquisa_satisfacao = localStorage.getItem("pesquisa_satisfacao");
    const nivel = decodeJWT(token).nivel;
    const currentPath = window.location.pathname;
    const concluiu_aula = localStorage.getItem("concluiu_aula") === "true";
    
    // Verifica se está na rota correta para primeiro login
    if ((primeiro_login === '0' || primeiro_login === 'null') && currentPath !== '/primeiro-login') {
        window.location.href = '/primeiro-login';
        return null;
    }

    // Verifica se está na rota correta para trocar senha
    if (primeiro_login === '1' && currentPath !== '/trocar-senha') {
        window.location.href = '/trocar-senha';
        return null;
    }

    // Só verifica pesquisa de satisfação se não for Administrador e se já passou pelo primeiro login
    if (nivel !== 'Administrador' && primeiro_login === '2') {
        if (
            concluiu_aula === true && 
            (pesquisa_satisfacao === null || 
            pesquisa_satisfacao === 'null' || 
            pesquisa_satisfacao === undefined || 
            pesquisa_satisfacao === 'undefined' || 
            pesquisa_satisfacao === '')
        ) {
            if (currentPath !== '/pesquisa-satisfacao') {
                window.location.href = '/pesquisa-satisfacao';
                return null;
            }
        }
    }

    return allowedRoles.includes(nivel) ? children : <SemPermissao />;
}

function RouteChangeListener() {
    const location = useLocation();

    React.useEffect(() => {
        const lastAccess = localStorage.getItem('ultimo_acesso');
        const userToken = localStorage.getItem('user_token');
        const now = new Date().toISOString();

        if (lastAccess && userToken) {
            const lastAccessDate = new Date(lastAccess);
            const nowDate = new Date(now);
            const diffInSeconds = (nowDate - lastAccessDate) / 1000;

            if (diffInSeconds > process.env.REACT_APP_TOKEN_TEMPO_EXPIRACAO) {
                localStorage.clear();
                window.location.href = "/login";
            }
        }
        if (userToken) {
            localStorage.setItem('ultimo_acesso', now);
        }
    }, [location]);

    return null;
}

function AuthenticatedRoutes() {
    const token = JSON.parse(localStorage.getItem("user_token"));
    const nivel = decodeJWT(token).nivel;
    
    return (
        <Router>
            <RouteChangeListener />
            <Content>
                <Header />
                <ContainerFlex>
                    <Sidebar />
                    <ContainerBox>
                        <Routes>
                            <Route path="*" element={nivel === "Aluno" ? <DashboardAluno /> : <Dashboard />} />
                            <Route path="/primeiro-login" element={<PrimeiroLogin />} />
                            <Route path="/trocar-senha" element={<TrocarSenha />} />

                            <Route path="/listar-alunos/:id" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'RH']}><ListaAlunos /></MultiRoleRoute>} />
                            <Route path="/meus-cursos" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Gestor', 'Administrador', 'RH']}><MeusCursos /></MultiRoleRoute>} />
                            <Route path="/calendario" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'RH']}><Calendario /></MultiRoleRoute>} />
                            <Route path="/questionario/:id" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'RH']}><Questionario /></MultiRoleRoute>} />
                            <Route path="/questionario/p2/:id" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'RH']}><Questionario /></MultiRoleRoute>} />
                            <Route path="/questionario/curso/:id" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'RH']}><Questionario /></MultiRoleRoute>} />
                            <Route path="/questionario/extra/:id" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'RH']}><Questionario /></MultiRoleRoute>} />
                            <Route path="/modulos/:id" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'RH']}><Modulos /></MultiRoleRoute>} />
                            <Route path="/aulas/:id" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'RH']}><Aulas /></MultiRoleRoute>} />
                            <Route path="/aula/:id" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'RH']}><Aula /></MultiRoleRoute>} />
                            <Route path="/configuracoes" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'RH']}><Configuracoes /></MultiRoleRoute>} />
                            <Route path="/biblioteca" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'RH']}><Biblioteca /></MultiRoleRoute>} />
                            <Route path="/provas-extras" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'RH']}><Provas /></MultiRoleRoute>} />
                            <Route path="/chat" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'RH']}><Chat /></MultiRoleRoute>} />
                            <Route path="/pesquisa-satisfacao" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'RH']}><PesquisaSatisfacao /></MultiRoleRoute>} />

                            {/* Rotas só de Admin */}
                            <Route path="/listar-topicos" element={<MultiRoleRoute allowedRoles={['Administrador', 'RH']}><Topicos /></MultiRoleRoute>} />
                            <Route path="/relatorios/feedback" element={<MultiRoleRoute allowedRoles={['Administrador', 'RH']}><RelatorioFeedback /></MultiRoleRoute>} />
                            <Route path="/ajustes" element={<MultiRoleRoute allowedRoles={['Administrador']}><Ajustes /></MultiRoleRoute>} />

                            {/* Rotas só de Aluno */}
                            <Route path="/aluno/dashboard" element={<MultiRoleRoute allowedRoles={['Aluno']}><DashboardAluno /></MultiRoleRoute>} />
                            <Route path="/aluno/certificado" element={<MultiRoleRoute allowedRoles={['Aluno']}><Certificado /></MultiRoleRoute>} />
                            <Route path="/aluno/historico-escolar" element={<MultiRoleRoute allowedRoles={['Aluno']}><HistoricoEscolarAluno /></MultiRoleRoute>} />

                            {/* Rotas só de Gestor */}
                            <Route path="/gestor/listar-usuarios" element={<MultiRoleRoute allowedRoles={['Gestor']}><ListaUsuariosGestor /></MultiRoleRoute>} />

                            {/* Rotas Gestor e Admin */}
                            <Route path="/relatorios/acompanhamento" element={<MultiRoleRoute allowedRoles={['Gestor', 'Administrador', 'RH']}><RelatorioAcompanhamento /></MultiRoleRoute>} />
                            <Route path="/relatorios/grupo" element={<MultiRoleRoute allowedRoles={['Gestor', 'Administrador', 'RH']}><RelatorioGrupo /></MultiRoleRoute>} />
                            <Route path="/relatorios/aluno" element={<MultiRoleRoute allowedRoles={['Gestor', 'Administrador', 'RH']}><RelatorioAluno /></MultiRoleRoute>} />
                            <Route path="/aula-grupo/:id" element={<MultiRoleRoute allowedRoles={['Gestor', 'Administrador', 'RH']}><AulaGrupo /></MultiRoleRoute>} />
                            <Route path="/dashboard" element={<MultiRoleRoute allowedRoles={['Gestor', 'Administrador', 'RH']}><Dashboard /></MultiRoleRoute>} />
                            <Route path="/listar-usuarios" element={<MultiRoleRoute allowedRoles={['Gestor', 'Administrador', 'RH']}><ListaUsuarios /></MultiRoleRoute>} />
                            <Route path="/certificados" element={<MultiRoleRoute allowedRoles={['Gestor', 'Administrador', 'RH']}><Certificados /></MultiRoleRoute>} />
                            <Route path="/historico-escolar" element={<MultiRoleRoute allowedRoles={['Gestor', 'Administrador', 'RH']}><HistoricoEscolar /></MultiRoleRoute>} />

                        </Routes>
                    </ContainerBox>
                </ContainerFlex>
            </Content>
        </Router>
    )
}

function NotAthenticatedRoutes() {
    return (
        <Router>
            <Routes>
                <Route path="*" element={<Login />} />
                <Route path="/recuperar" element={<Recuperar />} />
                <Route path="/resetar" element={<Resetar />} />
            </Routes>
        </Router>
    )
}

function AppContent() {
    const { user, loading: authLoading } = useAuth();
    const { loading: ajustesLoading } = useAjustes();
    
    // Se estiver carregando autenticação ou ajustes, mostra a tela de loading
    if (authLoading || ajustesLoading) {
        return <LoadingScreen message="Carregando a plataforma..." />;
    }
    
    const user_token = localStorage.getItem("user_token");
    if (user_token === 'undefined') {
        localStorage.clear();
    }
    
    return user ? <AuthenticatedRoutes /> : <NotAthenticatedRoutes />;
}

function App() {
    React.useEffect(() => {
        setupInterceptors();
    }, []);

    return (
        <AjustesProvider>
            <AppContent />
        </AjustesProvider>
    );
}

export default App;
