import React, { useState } from 'react';
import useAuth from '../Auth/hook_useAuth';
import Api from '../Auth/Api';
import { Toast, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import loginAnimation from '../assets/animations/login-animation.json';
import { useAjustes } from '../Context/AjustesContext';

function Login() {
    const navigate = useNavigate();
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [codigo, setCodigo] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { signin } = useAuth();
    const { ajustes } = useAjustes();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await Api.post("/login", { codigo, password });
            console.log(response.data);
            signin({ token: response.data.token, nome_completo: response.data.nome_completo, nivel: response.data.nivel, foto: response.data.foto, primeiro_login: response.data.primeiro_login, pesquisa_satisfacao: response.data.pesquisa_satisfacao, concluiu_aula: response.data.concluiu_aula }, true);

            if (response.data.primeiro_login === 0 || response.data.primeiro_login === null) {
                navigate("/primeiro-login");
            } else if (
                response.data.nivel !== 'Administrador' &&
                response.data.primeiro_login !== null &&
                response.data.concluiu_aula === true &&
                (response.data.pesquisa_satisfacao === null || response.data.pesquisa_satisfacao === undefined)
            ) {
                navigate("/pesquisa-satisfacao");
            } else {
                if (response.data.nivel === 'Aluno') {
                    navigate("/aluno/dashboard");
                } else {
                    navigate("/dashboard");
                }
            }
            localStorage.setItem("primeiro_login", response.data.primeiro_login);

        } catch (error) {
            setToast({ header: 'Ocorreu um erro!', body: error.response.data.message })
            setShowToast(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Toast
                show={showToast}
                onClose={() => setShowToast(false)}
                delay={3000}
                className="position-fixed bottom-0 start-50 translate-middle-x mb-4"
                style={{ zIndex: 1060 }}
            >
                <Toast.Header>
                    <strong className="me-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>

            <div className="login-container">
                <div className="login-content">
                    <div className="login-animation-container d-none d-lg-flex">
                        <Lottie
                            animationData={loginAnimation}
                            loop={true}
                            className="login-animation"
                        />
                    </div>

                    <div className="login-form-container">
                        <div className="login-header text-center">
                            {ajustes.logo ? (
                                <img
                                    src={ajustes.logo}
                                    alt={ajustes.titulo || "Logo"}
                                    className="login-logo"
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = "./../assets/logo.png";
                                    }}
                                />
                            ) : (
                                <img
                                    src="./../assets/logo.png"
                                    alt="Logo"
                                    className="login-logo"
                                />
                            )}
                            {ajustes.titulo && (
                                <h4 className="mt-2 text-primary">{ajustes.titulo}</h4>
                            )}
                        </div>

                        <form onSubmit={handleSubmit} className="login-form">
                            <div className="form-group mb-4">
                                <input
                                    type="text"
                                    className="form-control form-control-lg custom-input"
                                    placeholder="Matrícula"
                                    value={codigo}
                                    onChange={e => setCodigo(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="form-group mb-4">
                                <div className="position-relative">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        className="form-control form-control-lg custom-input"
                                        placeholder="Senha"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-link password-toggle"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? 'Ocultar' : 'Mostrar'}
                                    </button>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="rememberMe"
                                    />
                                    <label className="form-check-label" htmlFor="rememberMe">
                                        Lembrar-me
                                    </label>
                                </div>
                                <button
                                    type="button"
                                    onClick={() => navigate("/recuperar")}
                                    className="btn btn-link p-0 text-decoration-none"
                                >
                                    Esqueceu a senha?
                                </button>
                            </div>

                            <button
                                type="submit"
                                className="btn btn-primary btn-lg w-100 mb-3 d-flex align-items-center justify-content-center"
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                        />
                                        Entrando...
                                    </>
                                ) : (
                                    'Entrar'
                                )}
                            </button>
                        </form>
                    </div>
                </div>

                <div className="version-info">
                    versão {process.env.REACT_APP_API_VERSION}
                </div>
            </div>

            <style>{`
                .login-container {
                    min-height: 100vh;
                    background: linear-gradient(135deg, #f5f7fa 0%,rgb(226, 210, 195) 100%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 20px;
                    position: relative;
                }

                .login-content {
                    display: flex;
                    background: white;
                    border-radius: 20px;
                    box-shadow: 0 10px 30px rgba(0,0,0,0.1);
                    overflow: hidden;
                    width: 100%;
                    max-width: 1200px;
                }

                .login-animation-container {
                    flex: 1;
                    background: #f8f9fa;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 40px;
                }

                .login-animation {
                    width: 100%;
                    max-width: 500px;
                }

                .login-form-container {
                    flex: 1;
                    padding: 40px;
                    max-width: 500px;
                    margin: 0 auto;
                }

                .login-logo {
                    max-width: 180px;
                    margin-bottom: 30px;
                }

                .custom-input {
                    border: 1px solid #e1e1e1;
                    border-radius: 10px;
                    padding: 12px 20px;
                    font-size: 16px;
                    transition: all 0.3s ease;
                }

                .custom-input:focus {
                    border-color: var(--bs-primary);
                    box-shadow: 0 0 0 0.2rem rgba(var(--bs-primary-rgb), 0.15);
                }

                .password-toggle {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: #6c757d;
                    font-size: 14px;
                    text-decoration: none;
                }

                .btn-primary {
                    border-radius: 10px;
                    padding: 12px;
                    font-weight: 500;
                    transition: all 0.3s ease;
                }

                .btn-primary:hover {
                    transform: translateY(-2px);
                    box-shadow: 0 5px 15px rgba(var(--bs-primary-rgb), 0.2);
                }

                .version-info {
                    position: absolute;
                    bottom: 20px;
                    left: 20px;
                    color: #6c757d;
                    font-size: 14px;
                }

                @media (max-width: 991.98px) {
                    .login-content {
                        flex-direction: column;
                        max-width: 500px;
                    }

                    .login-form-container {
                        padding: 30px 20px;
                    }

                    .login-logo {
                        max-width: 150px;
                        margin-bottom: 20px;
                    }
                }
            `}</style>
        </>
    );
}

export default Login;