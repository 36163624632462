import React from 'react';
import '../styles/LoadingScreen.css';

const LoadingScreen = ({ message = 'Carregando...' }) => {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center vh-100 loading-screen">
            <div className="loading-circle"></div>
            <div className="loading-circle-outer"></div>
            <p className="loading-text">{message}</p>
        </div>
    );
};

export default LoadingScreen; 