function ContainerBox(props) {
    return (
        <div className="d-flex justify-content-center align-items-center mt-5">
            <div className="d-block flex-fill container-default pb-5">
                <div className="px-4 pt-1 pt-md-4 w-100 mt-3">
                    <div className="row">
                        <div className="col-md-12">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContainerBox;