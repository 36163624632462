import React, { useEffect, useMemo, useState } from 'react';
import Api from "../Auth/Api";
import TableProvaExtra from "../Components/TableProvaExtra";
import { Modal } from "react-bootstrap";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { Controller, useForm } from "react-hook-form";
import Select from 'react-select';
import { Toast } from 'react-bootstrap';
import TextareaAutosize from "react-textarea-autosize";
import { truncateString } from '../Components/jwtUtils';
import { useCallback } from "react";
import TableUsuariosProvas from "../Components/TableUsuariosProvas";


function Provas() {
    const token = JSON.parse(localStorage.getItem("user_token"));
    const [showModalEnviarProva, setShowModalEnviarProva] = useState(false);
    const [showModalAddAvaliacao, setShowModalAddAvaliacao] = useState(false);
    const [showModalEditarAvaliacao, setShowModalEditarAvaliacao] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [idAvaliacaoExtra, setIdAvaliacaoExtra] = useState(null);
    const [idsUsuarios, setIdsUsuarios] = useState([]);
    const [showModalDeletarProva, setShowModalDeletarProva] = useState(false);

    const { register: register_A, handleSubmit: handleSubmit_A, control: control_A, setValue: setValue_A, reset: reset_A, formState: { errors: errors_A } } = useForm();
    const [selectedValue, setSelectedValue] = useState(null);
    const [showModalAvaliacao, setShowModalAvaliacao] = useState(false);
    const [dataQuestoes, setDataQuestoes] = useState([]);
    const [correctAnswer, setCorrectAnswer] = useState('');
    const [isCreating, setIsCreating] = useState(false);
    const [reloadQuestoes, setReloadQuestoes] = useState(false);
    const [alternatives, setAlternatives] = useState([]);
    const [selectedQuestao, setSelectedQuestao] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [cursoEditarFile, setCursoEditarFile] = useState(false);

    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);

    const [dataProvas, setDataProvas] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`/api/listar-avaliacoes-extras`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataProvas(response.data);
            } catch (error) {
                setDataProvas([]); // Se não tiver dados, limpa a lista
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token, toast])

    // Filtro para seleção do módulo
    const [selectedModule, setSelectedModule] = useState(null);
    const FilterDataProvas = selectedModule ? dataProvas.filter(item => item.idModulo === selectedModule) : dataProvas;

    const [dataTodosModulos, setDataTodosModulos] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`/api/listar-todos-modulos`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataTodosModulos(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token])

    const onSubmitEditAvaliacao = async (data) => {
        try {
            const response = await Api.post(`/api/editar-avaliacao-extra/${data.id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message })
            reset();
            setShowModalEditarAvaliacao(false)
        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao editar avaliação:", error.response.data.message)
        }
        setShowToast(true)
    }

    const { register, handleSubmit, control, reset, setValue, formState: { errors } } = useForm()


    const columns = useMemo(
        () => [
            {
                Header: () => null, // Sem título para esta coluna
                id: 'icon',
                accessor: row => row.id,
                Cell: () => (
                    <div className="d-flex align-items-center justify-content-center">
                        <i className="bi bi-list-stars"></i>
                    </div>
                )
            },
            {
                Header: 'Titulo da prova',
                accessor: 'nome'
            },
            {
                Header: 'Módulo',
                accessor: 'nomeModulo'
            },
            {
                Header: () => null, // Sem título para esta coluna
                id: 'id',
                accessor: row => row.id,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div>
                        <button onClick={() => {
                            setShowModalAvaliacao(true);
                            setIdAvaliacaoExtra({ id: original.id, nome: original.nome });
                            setSelectedOption({ value: original.idModulo, label: original.nomeModulo });
                        }} className="btn btn-outline-primary justify-content-center align-items-center rounded px-2 m-1">
                            <i className="bi bi-list-task" />
                        </button>
                        <button onClick={() => {
                            reset()
                            setValue('id', original.id)
                            setValue('nome', original.nome)
                            setValue('idModulo', { value: original.idModulo, label: original.nomeModulo }); // Isso é pra setar o valor do idModulo inicialmente
                            setSelectedOption({ value: original.idModulo, label: original.nomeModulo });
                            setShowModalEditarAvaliacao(true)
                        }} className="btn btn-outline-primary justify-content-center align-items-center rounded px-2 m-1">
                            <i className="bi bi-pencil-square" />
                        </button>
                        <button onClick={() => {
                            setShowModalEnviarProva(true);
                            setIdAvaliacaoExtra({ id: original.id, nome: original.nome });
                        }} className="btn btn-outline-primary justify-content-center align-items-center rounded px-2 m-1" {...(original.quantidadeQuestoes > 0 ? {} : { disabled: true })}>
                            <i className="bi bi-send" />
                        </button>
                        <button onClick={() => {
                            setShowModalDeletarProva(true);
                            setIdAvaliacaoExtra({ id: original.id, nome: original.nome });
                        }} className="btn btn-outline-primary justify-content-center align-items-center rounded px-2 m-1">
                            <i className="bi bi-trash" />
                        </button>
                    </div>
                )
            }
        ],
        [reset, setValue, setShowModalEnviarProva]
    )

    const onSubmitAddAvaliacao = async (data) => {

        try {
            const response = await Api.post("/api/criar-avaliacao-extra", data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message })
            reset();

        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao criar novo usuário:", error.response.data.message);
        }

        setShowModalAddAvaliacao(false)
        setShowToast(true);
    }

    const customStyles = {
        control: (base, state) => ({
            ...base,
            fontSize: "1rem",
            backgroundColor: "#ffffff",
            borderRadius: "0.25rem",
            boxShadow: state.isFocused ? "0 0 0 0.25rem #27485b73" : null,
            borderColor: "#233734",
            "&:hover": {
                borderColor: "#3a6883"
            },
        }),
        singleValue: (base) => ({
            ...base,
            color: '#000',
        }),
        input: (base) => ({
            ...base,
            color: '#adb5bd',
            height: "52px",
            margin: '0px',
            padding: '0px !important',

        }),
        menu: (base) => ({
            ...base,
            backgroundColor: '#fff',
        }),
        option: (base, state) => ({
            ...base,
            color: state.isSelected ? '#3a5a6c' : null,
            backgroundColor: state.isFocused ? '#27485b73' : null,
            "&:active": {
                backgroundColor: "#27485b73"
            },
        }),
    };

    //Vai setar a selectOption para buscar no select e depois setar o valor
    const handleSelectChange = (option) => {
        setSelectedOption(option);
        setValue('idModulo', { value: option.value, label: option.label });
    }

    const handleCreateNewQuestion = () => {
        setSelectedQuestao(null);
        setIsCreating(true); // Entre no modo de criação
        setAlternatives([]); // Limpa a lista de alternativas
        setValue_A("questao", ''); // Limpe o campo de questão
        setValue_A("resposta", ''); // Limpe a resposta correta
        setValue_A("imagem", '');
        setCursoEditarFile(false);
        setPreviewUrl(null);
        setCorrectAnswer('');
        setSelectedValue(null);
        for (let index = 0; index < 10; index++) {
            setValue_A(`alternativa_${index}`, '');
        }
        async function fetchData() {
            try {
                const response = await Api.get(`api/configuracao-avaliacao-extra/${idAvaliacaoExtra.id}`, { headers: { Authorization: `Bearer ${token}` } });
                setValue_A("data_disponibilidade", response.data[0].data_disponibilidade);
                setValue_A("data_disponibilidade_fim", response.data[0].data_disponibilidade_fim);
                setValue_A("tempo", response.data[0].tempo);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    };

    useEffect(() => {
        if (selectedQuestao) {
            setAlternatives(JSON.parse(selectedQuestao.alternativas));
            // Use setValue para definir a alternativa correta
            setValue_A("resposta", selectedQuestao.resposta ? parseInt(selectedQuestao.resposta) : '');
        }
    }, [selectedQuestao, setValue_A, dataQuestoes]);

    //Adiciona uma nova alternativa em branco
    const addAlternative = () => {
        setAlternatives([...alternatives, '']);
    };

    // Atualize a função removeAlternative para manter a resposta correta atualizada
    const removeAlternative = (index) => {
        const updatedAlternatives = [...alternatives];
        updatedAlternatives.splice(index, 1);
        setAlternatives(updatedAlternatives);
        setValue_A(`alternativa_${index}`, '');

        // Atualize a resposta correta se a alternativa removida for a correta
        if (index + 1 === correctAnswer) {
            setCorrectAnswer('');
        }
    };

    // Atualize setSelectedValues para definir o valor correto para correctAnswer
    const setSelectedValues = useCallback((selected) => {
        setValue_A("questao", selected.questao);
        setAlternatives(JSON.parse(selected.alternativas));
        setCorrectAnswer(selected.resposta ? parseInt(selected.resposta) : '');
    }, [setValue_A]);

    useEffect(() => {
        if (selectedQuestao) {
            setSelectedValues(selectedQuestao);
            setAlternatives(JSON.parse(selectedQuestao.alternativas));
            JSON.parse(selectedQuestao.alternativas).forEach((alt, index) => {
                setValue_A(`alternativa_${index}`, alt);
            });
        }
    }, [selectedQuestao, setSelectedValues, setValue_A]);

    const handleDeleteQuestion = (id) => {

        setSelectedQuestao(null);
        setIsCreating(false);
        setAlternatives([]); // Limpa a lista de alternativas
        setValue_A("questao", ''); // Limpe o campo de questão
        setValue_A("resposta", ''); // Limpe a resposta correta
        setCorrectAnswer(''); // Limpa questao correta
        setSelectedValue(null); // Limpe o valor selecionado
        for (let index = 0; index < 20; index++) {
            setValue_A(`alternativa_${index}`, '');
        }
        async function fetchData() {
            try {
                const response = await Api.delete(`api/deletar-questao-extra/${id}`, { headers: { Authorization: `Bearer ${token}` } });
                setSelectedValue(null)
                setReloadQuestoes(prevState => !prevState)
                setToast({ header: 'Sucesso!', body: response.data.message })
                setShowToast(true)
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }

    const onSubmit_A = async (data) => {

        const alternativas = alternatives.map((_, index) => data[`alternativa_${index}`]);
        const resultJson = {
            questao: data.questao,
            alternativas: JSON.stringify(alternativas),
            resposta: data.resposta.toString(),
            imagem: data.imagem,
            tempo: data.tempo,
            data_disponibilidade: data.data_disponibilidade,
            data_disponibilidade_fim: data.data_disponibilidade_fim,
            idAvaliacao: idAvaliacaoExtra.id
        };

        const formData = new FormData();

        Object.keys(resultJson).forEach(key => {
            const value = resultJson[key];  // Use resultJson aqui
            if (typeof value === 'object' && value !== null && !(value instanceof FileList)) {
                formData.append(key, JSON.stringify(value));
            } else if (value instanceof FileList) {
                Array.from(value).forEach(file => {
                    formData.append(key, file);
                });
            } else {
                formData.append(key, value);
            }
        });

        if (isCreating) {
            // Lógica para criar uma nova questão
            try {
                const response = await Api.post(`api/criar-questao-extra/${idAvaliacaoExtra.id}`, formData, { headers: { Authorization: `Bearer ${token}` } });
                setToast({ header: 'Sucesso!', body: response.data.message });
            } catch (error) {
                setToast({ header: 'Erro!', body: error.response.data.message });
                console.log(error);
            }

        } else {
            // Lógica para editar uma questão existente
            try {
                const response = await Api.post(`api/editar-questao-extra/${selectedQuestao.id}`, formData, { headers: { Authorization: `Bearer ${token}` } });
                setToast({ header: 'Sucesso!', body: response.data.message });
            } catch (error) {
                setToast({ header: 'Erro!', body: error.response.data.message });
                console.log(error);
            }
        }
        setShowToast(true);
        setReloadQuestoes(prevState => !prevState)
        setSelectedQuestao(null);
        setIsCreating(false);
        setAlternatives([]); // Limpa a lista de alternativas
        setValue_A("questao", ''); // Limpe o campo de questão
        setValue_A("resposta", ''); // Limpe a resposta correta
        setCorrectAnswer(''); // Limpa questao correta
        setSelectedValue(null); // Limpe o valor selecionado
        for (let index = 0; index < 20; index++) {
            setValue_A(`alternativa_${index}`, '');
        }
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/questoes-extra/${idAvaliacaoExtra.id}`, { headers: { Authorization: `Bearer ${token}` } });
                setDataQuestoes(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        setDataQuestoes([]); // Limpa a questão antes de ler a api
        idAvaliacaoExtra && fetchData();
    }, [token, reloadQuestoes, idAvaliacaoExtra]);

    const handleThumbnailChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setPreviewUrl(e.target.result);
            }
            reader.readAsDataURL(file);
        }
    }

    const [dataUsuarios, setDataUsuarios] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`/api/listar-alunos-provas/${idAvaliacaoExtra.id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataUsuarios(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        idAvaliacaoExtra && fetchData();
    }, [token, idAvaliacaoExtra])

    // Função pra gravar o id do usuario quando seleciona o checkbox
    const handleCheckboxChange = (id, isChecked) => {
        if (isChecked) {
            // Se o checkbox está marcado, adiciona o ID à lista
            setIdsUsuarios(prevState => [...prevState, id]);
        } else {
            // Se o checkbox não está marcado, remove o ID da lista
            setIdsUsuarios(prevState => prevState.filter(userId => userId !== id));
        }
    };

    const handleDeletarProva = () => {
        async function fetchData() {
            try {
                const response = await Api.delete(`api/deletar-prova/${idAvaliacaoExtra.id}`, { headers: { Authorization: `Bearer ${token}` } });
                setToast({ header: 'Sucesso!', body: response.data.message });
            } catch (error) {
                setToast({ header: 'Erro!', body: error.response.data.message });
                console.log(error);
            }
        }

        fetchData();
        setShowModalDeletarProva(false)
        setShowToast(true);
    }

    const columnsUsuarios = useMemo(
        () => [
            {
                Header: () => null, // Sem título para esta coluna
                accessor: row => row.nome_completo,
                id: '1',
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className="d-flex">
                        <div className="picture text-white bg-grey" style={{ cursor: 'pointer', fontSize: '22px', height: '42px', marginRight: '0px', width: '42px' }}>
                            <i className="bi bi-person" />
                        </div>
                    </div>
                )
            },
            {
                Header: () => null, // Sem título para esta coluna
                accessor: row => row.nome_completo,
                id: '2',
                Cell: ({ cell: { value }, row: { original } }) => value

            },
            {
                Header: () => null, // Sem título para esta coluna
                accessor: row => row.email,
                id: '3',
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className="fw-bold"> {value} </div>
                )

            },
            {
                Header: () => null, // Sem título para esta coluna
                accessor: row => row.celular,
                id: '4',
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className="fw-bold"> {value || '-'} </div>
                )

            },
            {
                Header: () => null, // Sem título para esta coluna
                id: 'id',
                accessor: row => row.id,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div>
                        <input onChange={e => handleCheckboxChange(original.id, e.target.checked)} className="form-check-input border-primary" type="checkbox" />
                    </div>
                )
            }
        ],
        []
    )

    //criar funcao para enviar os dados via api
    const handleEnviarProvas = async () => {

        const data = {
            idAvaliacao: idAvaliacaoExtra.id,
            idsUsuarios: idsUsuarios,
        }
        setIdsUsuarios([]);

        try {
            const response = await Api.post(`api/nova-pendencia-prova-extra`, data, { headers: { Authorization: `Bearer ${token}` } });
            setToast({ header: 'Sucesso!', body: response.data.message })
        }
        catch (error) {
            setToast({ header: 'Erro!', body: error.response.data.message });
            console.log(error);
        }

        setShowModalEnviarProva(false);
        setShowToast(true)
    }

    return (
        <>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide style={{ position: 'fixed', left: '95%', bottom: '10px', transform: 'translateX(-95%)', zIndex: 9999 }}>
                <Toast.Header>
                    <strong className="mr-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>

            <div className="fade-in w-100">
                <div className="d-flex justify-content-between align-items-center">
                    <span className="text-primary fs-1">Provas Extras</span>
                    <button onClick={() => { setShowModalAddAvaliacao(true); reset() }} className="d-flex align-items-center justify-content-center btn btn-primary text-white fw-semibold"><i className="bi bi-plus fs-4"></i> Adicionar prova</button>
                </div>
                <p className="fs-6">
                    Lista de provas extras. Adicione, edite e visualize suas provas.
                </p>

                <TableProvaExtra columns={columns} data={FilterDataProvas} filterSelect={dataTodosModulos} setSelectedModule={setSelectedModule} titulo="Lista de Provas Extras" />

            </div>

            {/* Adicionar prova extra */}
            <Modal size="lg" show={showModalAddAvaliacao} onHide={() => setShowModalAddAvaliacao(false)}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Adicionar prova extra</span>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmitAddAvaliacao)}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="body">
                                        <div className="d-flex mb-5">
                                            <div className="d-flex flex-column w-100">

                                                <div className="row mb-3">

                                                    <div className="col-12 col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Nome da prova</label>
                                                            <input type="text" {...register("nome", { required: true })} placeholder="Nome da prova" className="form-control border-primary" />
                                                            {errors.nome && <span className='text-primary fs-7'>Nome da prova.</span>}
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Selecione a módulo</label>
                                                            <Controller
                                                                name="idModulo"
                                                                control={control}
                                                                rules={{ required: true }}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        options={dataTodosModulos.map((value) => {
                                                                            return {
                                                                                value: value.id,
                                                                                label: `${value.nome}`,
                                                                            };
                                                                        })}
                                                                        value={field.value}
                                                                        styles={customStyles}
                                                                        placeholder="Selecione o módulo para essa prova"
                                                                        noOptionsMessage={() => "Nenhumo módulo encontrada."}
                                                                    />
                                                                )}
                                                            />
                                                            {errors.idModulo && <span className='text-primary fs-7'>Módulo é obrigatório</span>}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mb-4">
                            <button type="submit" className="btn btn-primary text-white px-5 py-2">Salvar</button>
                            <button type="button" className="btn btn-outline-primary text-primary px-5 py-2 ms-4" onClick={() => setShowModalAddAvaliacao(false)}> Cancelar </button>
                        </div>

                    </form>
                </Modal.Body>
            </Modal>

            {/* Enviar prova */}
            <Modal size="lg" show={showModalEnviarProva} onHide={() => setShowModalEnviarProva(false)}>
                <Modal.Header closeButton>
                    <div className='row'>
                        <span className="fw-medium fs-4 text-primary">Enviar {idAvaliacaoExtra && idAvaliacaoExtra.nome} para...</span>
                        <span>Selecione os alunos que irão receber essa prova.</span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <section className="w-100 mt-2">
                        <div className="d-flex flex-column">
                            <div className="row d-flex align-items-center justify-content-between">
                                <TableUsuariosProvas columns={columnsUsuarios} data={dataUsuarios} titulo="Lista de alunos" search={true} />
                            </div>
                        </div>
                    </section>
                    <div className="d-flex justify-content-center mb-4">
                        <button type="submit" onClick={() => handleEnviarProvas()} disabled={idsUsuarios.length === 0} className="btn btn-primary text-white px-3 py-2">Enviar</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Deletar prova */}
            <Modal size="lg" show={showModalDeletarProva} onHide={() => setShowModalDeletarProva(false)}>
                <Modal.Header closeButton>
                    <div className='row'>
                        <span className="fw-medium fs-4 text-primary">Deletar a prova {idAvaliacaoExtra && idAvaliacaoExtra.nome} ?</span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <section className="w-100 mt-2">
                        <div className="d-flex flex-column">
                            <span>Apagar essa prova, irá deletar todo seu conteúdo e avaliações relacionadas a ela.</span>
                        </div>
                    </section>
                    <div className="d-flex justify-content-center mb-4 mt-4">
                        <button type="submit" onClick={() => handleDeletarProva()} className="btn btn-primary text-white px-3 py-2">Confirmar</button>
                        <button type="button" className="btn btn-outline-primary text-primary px-5 py-2 ms-4" onClick={() => setShowModalDeletarProva(false)}>Cancelar</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Editar prova extra */}
            <Modal size="lg" show={showModalEditarAvaliacao} onHide={() => setShowModalEditarAvaliacao(false)}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Editar Prova Extra</span>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmitEditAvaliacao)}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="body">
                                        <div className="d-flex mb-5">
                                            <div className="d-flex flex-column w-100">

                                                <div className="row mb-3">

                                                    <div className="col-12 col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Nome da prova</label>
                                                            <input type="text" {...register("nome", { required: true })} placeholder="Nome da prova" className="form-control border-primary" />
                                                            {errors.nome && <span className='text-primary fs-7'>Nome da prova é obrigatório.</span>}
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Selecione o módulo</label>
                                                            <Controller
                                                                name="idModulo"
                                                                control={control}
                                                                rules={{ required: true }}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        value={selectedOption}
                                                                        options={dataTodosModulos.map((value) => {
                                                                            return {
                                                                                value: value.id,
                                                                                label: `${value.nome}`,
                                                                            };
                                                                        })}
                                                                        onChange={handleSelectChange}
                                                                        styles={customStyles}
                                                                        placeholder="Selecione a módulo para essa prova"
                                                                        noOptionsMessage={() => "Nenhum módulo encontrado."}
                                                                    />
                                                                )}
                                                            />
                                                            {errors.idModulo && <span className='text-primary fs-7'>Módulo é obrigatório</span>}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mb-4">
                            <button type="submit" className="btn btn-primary text-white px-5 py-2">Salvar</button>
                            <button type="button" className="btn btn-outline-primary text-primary px-5 py-2 ms-4" onClick={() => setShowModalEditarAvaliacao(false)}> Cancelar </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            {/* Editar questões da prova extra */}
            <Modal size="lg" show={showModalAvaliacao}
                onHide={() => {
                    setShowModalAvaliacao(false);
                    setSelectedQuestao(null);
                    setIsCreating(false);
                    setSelectedValue(null);
                    reset_A()
                    for (let index = 0; index < 10; index++) {
                        setValue_A(`alternativa_${index}`, '');
                    }
                }}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Avaliação Extra <span className="text-dark fs-5">({idAvaliacaoExtra && idAvaliacaoExtra.nome})</span></span>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit_A(onSubmit_A)}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="body">
                                    <div className="d-flex mb-1">
                                        <div className="d-flex flex-column w-100">

                                            <div className="row mb-3">

                                                <div className="col-12">
                                                    {!isCreating && (
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Selecione a questão</label>

                                                            <Controller
                                                                name="questaoSelecionada"
                                                                control={control_A}
                                                                defaultValue={[]}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        options={dataQuestoes.map((value, index) => {
                                                                            return {
                                                                                value: value.id,
                                                                                label: `${index + 1} - ${truncateString(value.questao)}`,
                                                                            };
                                                                        })}
                                                                        value={selectedValue} // Use o estado aqui
                                                                        styles={customStyles}
                                                                        onChange={(value) => {
                                                                            if (value && selectedQuestao && value.value === selectedQuestao.id) return;
                                                                            field.onChange(value);
                                                                            setPreviewUrl(null);
                                                                            setCursoEditarFile(false);
                                                                            setSelectedValue(value); // Atualize o estado aqui
                                                                            const selected = dataQuestoes.find((q) => q.id === value.value);
                                                                            setSelectedQuestao(selected);
                                                                            setValue_A("tempo", dataQuestoes[0].tempo);
                                                                            setValue_A("data_disponibilidade", dataQuestoes[0].data_disponibilidade);
                                                                            setValue_A("data_disponibilidade_fim", dataQuestoes[0].data_disponibilidade_fim);
                                                                        }}
                                                                        placeholder="Selecione uma questão"
                                                                        isSearchable
                                                                        noOptionsMessage={() => "Nenhuma questão encontrada."}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    )}

                                                    <p className="btn-link fs-7 ms-2 cursor-pointer" onClick={handleCreateNewQuestion}>Adicionar nova questão</p>

                                                </div>


                                            </div>
                                            {(selectedQuestao || isCreating) && (
                                                <>
                                                    <div className="row mb-3">
                                                        <div className="col-12 col-md-12">
                                                            <div className="mb-3">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <label className="form-label text-primary fw-medium mb-0">Questão</label>
                                                                    {!isCreating && (
                                                                        <>
                                                                            <Tippy content="Deletar essa questão">
                                                                                <i className="bi bi-trash cursor-pointer" onClick={() => handleDeleteQuestion(selectedQuestao.id)}></i>
                                                                            </Tippy>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <TextareaAutosize
                                                                    rows={3}
                                                                    {...register_A("questao", { required: true, defaultValue: selectedQuestao ? selectedQuestao.questao : '' })}
                                                                    className="form-control border-primary"
                                                                />
                                                                {errors_A.questao && <span className='text-primary fs-7'>Questão é obrigatório.</span>}
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-12">
                                                            <div className="mb-3">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <label className="form-label text-primary fw-medium mb-0">Imagem</label>
                                                                </div>

                                                                <label htmlFor="imagem" className={`form-control label-input ${cursoEditarFile ? 'border-success bg-success' : 'border-primary bg-primary'} text-white fw-medium text-center`}>
                                                                    <i className="bi bi-card-image me-2"></i> Enviar Imagem
                                                                </label>

                                                                <Controller
                                                                    control={control_A}
                                                                    name="imagem"
                                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                        <input
                                                                            id='imagem'
                                                                            type="file"
                                                                            onBlur={onBlur}
                                                                            ref={ref}
                                                                            onChange={(e) => {
                                                                                const files = e.target.files;
                                                                                handleThumbnailChange(e);
                                                                                onChange(files);
                                                                                if (files && files.length > 0) {
                                                                                    setCursoEditarFile(true);
                                                                                } else {
                                                                                    setCursoEditarFile(false);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )}
                                                                />

                                                                {previewUrl ?
                                                                    <div className="d-flex justify-content-center mt-2">
                                                                        <img src={previewUrl} className='card-img-question-edit rounded-3' alt="Thumbnail" />
                                                                    </div>
                                                                    :
                                                                    <div className="d-flex justify-content-center mt-2">
                                                                        {selectedQuestao && selectedQuestao.imagem &&
                                                                            <img src={`${process.env.REACT_APP_IMG_URL}/${selectedQuestao.imagem}`} className='card-img-question-edit rounded-3' alt="Thumbnail" />
                                                                        }
                                                                    </div>
                                                                }


                                                            </div>
                                                        </div>

                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium col-12">Alternativas</label>
                                                            {alternatives.map((_, index) => (
                                                                <div className="input-group mb-3" key={index}>
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text bg-primary text-white" id="basic-addon1">{index + 1}</span>
                                                                    </div>
                                                                    <input type="text" {...register_A(`alternativa_${index}`, { required: true })} className="form-control border-primary" />
                                                                    <button type="button" className="btn btn-primary text-white" onClick={() => removeAlternative(index)}>-</button>
                                                                </div>
                                                            ))}
                                                            <button className="btn btn-primary text-white" type="button" onClick={addAlternative}>+</button>
                                                        </div>


                                                        <div className="col-12 col-md-6">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Alternativa Correta</label>
                                                                <select
                                                                    {...register_A("resposta")}
                                                                    value={correctAnswer} // Use correctAnswer aqui
                                                                    onChange={(e) => setCorrectAnswer(Number(e.target.value))}
                                                                    aria-label="Default select example"
                                                                    className="form-select border-primary"
                                                                >
                                                                    <option value="">Selecione a alternativa correta</option>
                                                                    {alternatives.map((_, index) => (
                                                                        <option key={index} value={index + 1}>
                                                                            Alternativa {index + 1}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-12 col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Tempo da avaliação</label>
                                                                <input
                                                                    type="number"
                                                                    {...register_A("tempo", { required: true })}
                                                                    className="form-control border-primary"
                                                                    placeholder="Digite o tempo da avaliação em minutos"
                                                                />
                                                                <label className="form-label text-dark fw-normal fs-7">em minutos</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Data de Disponibilidade</label>
                                                                <input
                                                                    type="date"
                                                                    {...register_A("data_disponibilidade", { required: true })}
                                                                    className="form-control border-primary"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Disponível até</label>
                                                                <input
                                                                    type="date"
                                                                    {...register_A("data_disponibilidade_fim", { required: true })}
                                                                    className="form-control border-primary"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {(selectedQuestao || isCreating) && (

                            <div className="d-flex justify-content-center mb-3">
                                <button type="submit" className="btn btn-primary text-white px-5 py-2">
                                    Salvar
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary text-primary px-5 py-2 ms-4"
                                    onClick={() => {
                                        setShowModalAvaliacao(false);
                                        setSelectedQuestao(null);
                                        setIsCreating(false);
                                        reset_A();
                                        setSelectedValue(null);
                                        for (let index = 0; index < 10; index++) {
                                            setValue_A(`alternativa_${index}`, '');
                                        }
                                    }}
                                >
                                    Voltar
                                </button>
                            </div>
                        )}


                    </form>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default Provas;