import { useState, useEffect, useMemo } from "react";
import TableCertificado from "../Components/TableCertificados";
import { formatDateTime } from "../Components/jwtUtils";
import Api from "../Auth/Api";
import { pdf } from "@react-pdf/renderer";
import GerarCertificado from "../Components/GerarCertificado";
import { useCallback } from "react";
import { Modal, Toast } from "react-bootstrap";
import Tippy from "@tippyjs/react";
import { useAjustes } from "../Context/AjustesContext";

function Certificados() {

    const token = JSON.parse(localStorage.getItem("user_token"));
    const { ajustes } = useAjustes();
    const [selectedTipo, setSelectedTipo] = useState('favoritos');
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);

    const filterTipo = [
        { value: 'favoritos', label: 'Favoritos' },
        { value: 'todos', label: 'Todos' }
    ]

    //listar todos os cursos
    const [dataTodosCursos, setDataTodosCursos] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`/api/listar-todos-cursos`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataTodosCursos(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token])

    // listar certificados
    const [dataCertificados, setDataCertificados] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`/api/listar-certificados/${selectedTipo}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataCertificados(response.data);
            } catch (error) {
                setDataCertificados([]); // Se não tiver dados, limpa a lista
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token, selectedTipo])

    // Filtro para seleção por curso
    const [selectCurso, setSelectCurso] = useState(null);
    const [selectUnidade, setSelectUnidade] = useState(null);
    const FilterDataProvas = selectCurso ? dataCertificados.filter(item => item.idCurso === selectCurso) : dataCertificados;
    const FilterDataUnidade = selectUnidade ? FilterDataProvas.filter(item => item.unidade === selectUnidade) : FilterDataProvas;

    const unidades = useMemo(() => {
        const allUnidades = dataCertificados.map(item => item.unidade);
        return [...new Set(allUnidades)].map(unidade => ({ value: unidade, label: unidade }));
    }, [dataCertificados]);

    // Gerar PDF
    const loadPDFData = useCallback(async (data) => {
        try {
            // Buscar os dados para gerar o PDF
            const response = await Api.get(`api/info-certificado/${data}`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            // Gerar o PDF e obter como Blob
            const blob = await pdf(
                <GerarCertificado 
                    data={{ 
                        id: response.data[0].id, 
                        nomeAluno: response.data[0].nomeAluno, 
                        prazo_validade: response.data[0].prazo_validade, 
                        nomeCurso: response.data[0].nomeCurso, 
                        data: response.data[0].data, 
                        nomeGestor: response.data[0].nomeGestor, 
                        certificado: response.data[0].certificado, 
                        nomeTopico: response.data[0].nomeTopico, 
                        cargaHorariaTotal: response.data[0].cargaHorariaTotal, 
                        unidade: response.data[0].unidade 
                    }} 
                    diplomaUrl={ajustes.diploma}
                    empresa={ajustes.titulo}

                />
            ).toBlob();

            // Criar uma URL para o Blob e abrir em uma nova aba
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');
        } catch (error) {
            console.error("Erro ao gerar o PDF:", error.message || error);
            setToast({ header: 'Erro!', body: "Erro ao gerar o PDF. Tente novamente." });
            setShowToast(true);
        }
    }, [token, ajustes.diploma, ajustes.titulo]);

    const [showModalDetalhes, setShowModalDetalhes] = useState(false);
    const [certificadoSelecionado, setCertificadoSelecionado] = useState(null);

    const columns = useMemo(
        () => [
            {
                Header: () => null, // Sem título para esta coluna
                id: 'foto',
                accessor: row => row.foto,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className="d-flex align-items-center">
                        <div className="picture text-white bg-grey" style={{ fontSize: '22px', height: '42px', marginRight: '0px', width: '42px' }}>
                            {value ? <img className="object-fit-cover" src={`${process.env.REACT_APP_API_URL}/fotos/${value}`} alt="Profile" style={{ height: '42px', width: '42px' }} /> : <i className="bi bi-person-fill-check fs-3"></i>}
                        </div>
                    </div>
                )
            },
            {
                Header: () => null,
                accessor: 'nomeAluno',
                Cell: ({ cell: { value }, row: { original } }) => (
                    <>
                        <div className=''>{value}</div>
                        <div className='badge bg-primary'>{original.codigo}</div>
                        <div className='badge bg-success ms-1'>{original.unidade}</div>
                    </>
                )
            },
            {
                Header: 'Curso',
                accessor: 'nomeCurso'
            },
            {
                Header: () => 'Gestor', // Sem título para esta coluna
                id: 'Gestor',
                accessor: row => row.nomeGestor,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className="fs-6">
                        {original.idGestor !== 0 ? value : 'Automático'}
                    </div>
                )
            },
            {
                Header: () => null, // Sem título para esta coluna
                id: 'data',
                accessor: row => row.data,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className="fs-7 text-center text-nowrap">
                        {formatDateTime(value)}
                    </div>
                )
            },
            {
                Header: () => null,
                id: 'acoes',
                accessor: row => row.id,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className="d-flex align-items-center justify-content-center gap-2">
                        <Tippy content="Baixar Certificado">
                            <button
                                onClick={() => loadPDFData(original.id)}
                                className="btn btn-outline-primary text-primary rounded px-2"
                            > 
                                <i className="bi bi-box-arrow-in-down"></i>
                            </button>
                        </Tippy>
                        <Tippy content="Ver Detalhes">
                            <button
                                onClick={() => {
                                    setCertificadoSelecionado(original);
                                    setShowModalDetalhes(true);
                                }}
                                className="btn btn-outline-primary text-primary rounded px-2"
                            >
                                <i className="bi bi-eye"></i>
                            </button>
                        </Tippy>
                    </div>
                )
            },
            {
                Header: 'Codigo',
                accessor: 'codigo',
                visible: false
            }
        ],
        [loadPDFData]
    )

    return (
        <>
            <div className="fade-in w-100 mt-3" style={{ marginBottom: '100px' }}>
                <span className="text-primary fs-1">Certificados</span>
                <p className="fs-6">Lista com todos os certificados disponíveis</p>
                <TableCertificado 
                    columns={columns} 
                    data={FilterDataUnidade} 
                    filterSelect={dataTodosCursos} 
                    setSelectCurso={setSelectCurso} 
                    filterTipo={filterTipo} 
                    setSelectedTipo={setSelectedTipo} 
                    setSelectUnidade={setSelectUnidade} 
                    unidades={unidades}
                    titulo="Lista de Certificados" 
                />
            </div>

            <Modal size="lg" show={showModalDetalhes} onHide={() => setShowModalDetalhes(false)}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Detalhes do Certificado</span>
                </Modal.Header>
                <Modal.Body>
                    {certificadoSelecionado && (
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card border-0 shadow-sm">
                                        <div className="card-body">
                                            <div className="mb-3">
                                                <label className="text-primary fw-medium">Aluno</label>
                                                <p className="mb-0">{certificadoSelecionado.nomeAluno}</p>
                                            </div>
                                            <div className="mb-3">
                                                <label className="text-primary fw-medium">Curso</label>
                                                <p className="mb-0">{certificadoSelecionado.nomeCurso}</p>
                                            </div>
                                            <div className="mb-3">
                                                <label className="text-primary fw-medium">Gestor</label>
                                                <p className="mb-0">{certificadoSelecionado.nomeGestor}</p>
                                            </div>
                                            <div className="mb-3">
                                                <label className="text-primary fw-medium">Data de Emissão</label>
                                                <p className="mb-0">{formatDateTime(certificadoSelecionado.data)}</p>
                                            </div>
                                            <div className="mb-3">
                                                <label className="text-primary fw-medium">Unidade</label>
                                                <p className="mb-0">{certificadoSelecionado.unidade}</p>
                                            </div>
                                            <div className="mb-3">
                                                <label className="text-primary fw-medium">Código</label>
                                                <p className="mb-0">{certificadoSelecionado.codigo}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer className="bg-light">
                    <div className="d-flex justify-content-center gap-2">
                        <button 
                            className="btn btn-primary text-white px-4"
                            onClick={() => loadPDFData(certificadoSelecionado.id)}
                        >
                            Baixar Certificado
                        </button>
                        <button 
                            className="btn btn-outline-primary"
                            onClick={() => setShowModalDetalhes(false)}
                        >
                            Fechar
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Toast 
                show={showToast} 
                onClose={() => setShowToast(false)} 
                delay={3000} 
                autohide 
                style={{ position: 'fixed', left: '95%', bottom: '10px', transform: 'translateX(-95%)', zIndex: 9999 }}
            >
                <Toast.Header className={toast.header === 'Erro!' ? 'bg-danger text-white' : 'bg-success text-white'}>
                    <strong className="me-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>
        </>
    )
}

export default Certificados;