import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useEffect, useState } from "react";
import Api from "../Auth/Api";
import { Toast } from "react-bootstrap";
import { decodeJWT } from "../Components/jwtUtils";
import { useAjustes } from "../Context/AjustesContext";

function Ajustes() {
    const navigate = useNavigate();
    const token = JSON.parse(localStorage.getItem("user_token"));
    const nivel = decodeJWT(token).nivel;
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [ajustes, setAjustes] = useState({});
    const [loading, setLoading] = useState(true);
    const [corPrimaria, setCorPrimaria] = useState("#000000");
    const [corSecundaria, setCorSecundaria] = useState("#000000");
    const { recarregarAjustes } = useAjustes();

    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    
    // Observar mudanças nos valores dos campos de cor
    const watchCorPrimaria = watch("cor_primaria");
    const watchCorSecundaria = watch("cor_secundaria");

    // Atualizar as variáveis de estado quando os valores dos campos mudarem
    useEffect(() => {
        if (watchCorPrimaria) setCorPrimaria(watchCorPrimaria);
    }, [watchCorPrimaria]);

    useEffect(() => {
        if (watchCorSecundaria) setCorSecundaria(watchCorSecundaria);
    }, [watchCorSecundaria]);

    // Verificar se o usuário é administrador
    useEffect(() => {
        if (nivel !== 'Administrador') {
            navigate('/sem-permissao');
        }
    }, [nivel, navigate]);

    // Carregar ajustes da plataforma
    useEffect(() => {
        async function fetchAjustes() {
            try {
                setLoading(true);
                const response = await Api.get(`api/ajustes`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setAjustes(response.data);
                
                // Preencher o formulário com os dados
                setValue('titulo', response.data.titulo);
                setValue('cor_primaria', response.data.cor_primaria || "#3a5a6c");
                setValue('cor_secundaria', response.data.cor_secundaria || "#6c8a9c");
                setCorPrimaria(response.data.cor_primaria || "#3a5a6c");
                setCorSecundaria(response.data.cor_secundaria || "#6c8a9c");
                setValue('logo', response.data.logo);
                setValue('logo_white', response.data.logo_white);
                setValue('favicon', response.data.favicon);
                setValue('diploma', response.data.diploma);
                setValue('flag', response.data.flag);
                
                setLoading(false);
            } catch (error) {
                console.error("Erro ao carregar ajustes:", error);
                setToast({ 
                    header: 'Erro!', 
                    body: error.response?.data?.message || 'Erro ao carregar ajustes da plataforma' 
                });
                setShowToast(true);
                setLoading(false);
            }
        }
        fetchAjustes();
    }, [token, setValue]);

    // Função para validar URLs
    const isValidUrl = (url) => {
        if (!url) return true; // URLs vazias são permitidas
        
        try {
            new URL(url);
            return true;
        } catch (e) {
            return false;
        }
    };

    const onSubmit = async (data) => {
        try {
            // Validar URLs
            const camposUrl = ['logo', 'logo_white', 'favicon', 'diploma', 'flag'];
            for (const campo of camposUrl) {
                if (data[campo] && !isValidUrl(data[campo])) {
                    setToast({ 
                        header: 'Erro!', 
                        body: `O campo ${campo} contém uma URL inválida` 
                    });
                    setShowToast(true);
                    return;
                }
            }
            
            // Limpar valores vazios ou nulos para evitar problemas na API
            const dadosLimpos = {};
            
            // Processar apenas campos com valores válidos
            Object.keys(data).forEach(key => {
                // Se o valor não for nulo, vazio ou undefined, inclui no payload
                if (data[key] !== null && data[key] !== "" && data[key] !== undefined) {
                    dadosLimpos[key] = data[key];
                }
            });
            
            // Garantir que os campos obrigatórios estejam presentes
            if (!dadosLimpos.titulo) {
                setToast({ 
                    header: 'Erro!', 
                    body: 'O título da plataforma é obrigatório' 
                });
                setShowToast(true);
                return;
            }
            
            console.log("Enviando dados:", dadosLimpos);
            
            const response = await Api.post(`api/ajustes`, dadosLimpos, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            setToast({ header: 'Sucesso!', body: response.data.message });
            setShowToast(true);
            
            // Recarregar os ajustes
            const ajustesResponse = await Api.get(`api/ajustes`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setAjustes(ajustesResponse.data);
            
            // Recarregar os ajustes globais
            recarregarAjustes();
        } catch (error) {
            console.error("Erro ao atualizar ajustes:", error);
            setToast({ 
                header: 'Erro!', 
                body: error.response?.data?.message || 'Erro ao atualizar ajustes da plataforma' 
            });
            setShowToast(true);
        }
    };

    const handleRemoveLink = async (campo) => {
        try {
            // Confirmar com o usuário antes de remover
            if (!window.confirm(`Tem certeza que deseja remover este ${campo}?`)) {
                return;
            }
            
            console.log(`Removendo campo: ${campo}`);
            
            const response = await Api.delete(`api/ajustes/arquivo/${campo}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            // Atualizar o estado e o formulário
            setAjustes(prev => ({ ...prev, [campo]: null }));
            setValue(campo, null);
            
            // Se o campo for uma cor, atualizar o estado correspondente
            if (campo === 'cor_primaria') {
                setCorPrimaria("#3a5a6c"); // Valor padrão
                setValue('cor_primaria', "#3a5a6c");
            } else if (campo === 'cor_secundaria') {
                setCorSecundaria("#6c8a9c"); // Valor padrão
                setValue('cor_secundaria', "#6c8a9c");
            }
            
            setToast({ header: 'Sucesso!', body: response.data.message });
            setShowToast(true);
            
            // Recarregar os ajustes globais
            recarregarAjustes();
        } catch (error) {
            console.error(`Erro ao remover ${campo}:`, error);
            setToast({ 
                header: 'Erro!', 
                body: error.response?.data?.message || `Erro ao remover ${campo}` 
            });
            setShowToast(true);
        }
    };

    // Função para lidar com a mudança no color picker
    const handleColorChange = (e, field) => {
        const color = e.target.value;
        setValue(field, color);
        if (field === 'cor_primaria') {
            setCorPrimaria(color);
        } else if (field === 'cor_secundaria') {
            setCorSecundaria(color);
        }
    };

    // Função para lidar com a mudança no input de texto da cor
    const handleColorInputChange = (e, field) => {
        const color = e.target.value;
        setValue(field, color);
        if (field === 'cor_primaria') {
            setCorPrimaria(color);
        } else if (field === 'cor_secundaria') {
            setCorSecundaria(color);
        }
    };

    return (
        <>
            <Toast 
                show={showToast} 
                onClose={() => setShowToast(false)} 
                delay={3000} 
                autohide 
                style={{ 
                    position: 'fixed', 
                    left: '95%', 
                    bottom: '10px', 
                    transform: 'translateX(-95%)', 
                    zIndex: 9999 
                }}
            >
                <Toast.Header>
                    <strong className="mr-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>

            <div className="fade-in w-100 mb-5">

                <div className="card shadow-sm border-0">
                    <div className="card-header bg-primary text-white py-3">
                        <h5 className="mb-0"><i className="bi bi-gear-wide-connected me-2"></i>Ajustes da Plataforma</h5>
                    </div>
                    <div className="card-body p-4">
                        {loading ? (
                            <div className="d-flex justify-content-center my-5">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Carregando...</span>
                                </div>
                            </div>
                        ) : (
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row mb-4">
                                    <div className="col-md-12">
                                        <h6 className="text-primary mb-3 border-bottom pb-2">
                                            <i className="bi bi-info-circle me-2"></i>
                                            Informações Básicas
                                        </h6>
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <label className="form-label fw-bold">Título da Plataforma</label>
                                        <input 
                                            type="text" 
                                            className={`form-control ${errors.titulo ? 'is-invalid' : ''}`}
                                            {...register('titulo', { required: 'Título é obrigatório' })}
                                        />
                                        {errors.titulo && <div className="invalid-feedback">{errors.titulo.message}</div>}
                                    </div>
                                    
                                    <div className="col-md-12 mb-3">
                                        <h6 className="text-primary mb-3 border-bottom pb-2">
                                            <i className="bi bi-palette me-2"></i>
                                            Cores do Sistema
                                        </h6>
                                    </div>
                                    
                                    <div className="col-md-6 mb-4">
                                        <label className="form-label fw-bold">Cor Primária</label>
                                        <div className="input-group">
                                            <input 
                                                type="color" 
                                                className="form-control form-control-color"
                                                value={corPrimaria}
                                                onChange={(e) => handleColorChange(e, 'cor_primaria')}
                                            />
                                            <input 
                                                type="text" 
                                                className="form-control"
                                                value={corPrimaria}
                                                onChange={(e) => handleColorInputChange(e, 'cor_primaria')}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-6 mb-4">
                                        <label className="form-label fw-bold">Cor Secundária</label>
                                        <div className="input-group">
                                            <input 
                                                type="color" 
                                                className="form-control form-control-color"
                                                value={corSecundaria}
                                                onChange={(e) => handleColorChange(e, 'cor_secundaria')}
                                            />
                                            <input 
                                                type="text" 
                                                className="form-control"
                                                value={corSecundaria}
                                                onChange={(e) => handleColorInputChange(e, 'cor_secundaria')}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-md-12">
                                        <h6 className="text-primary mb-3 border-bottom pb-2">
                                            <i className="bi bi-image me-2"></i>
                                            Imagens e Logos (Links Externos)
                                        </h6>
                                    </div>
                                    
                                    <div className="col-md-6 mb-4">
                                        <label className="form-label fw-bold">Logo Principal</label>
                                        <div className="input-group">
                                            <span className="input-group-text bg-light">
                                                <i className="bi bi-image text-primary"></i>
                                            </span>
                                            <input 
                                                type="text" 
                                                className="form-control"
                                                placeholder="URL da imagem"
                                                {...register('logo')}
                                            />
                                            {ajustes.logo && (
                                                <button 
                                                    type="button" 
                                                    className="btn btn-outline-danger"
                                                    onClick={() => handleRemoveLink('logo')}
                                                >
                                                    <i className="bi bi-trash"></i>
                                                </button>
                                            )}
                                        </div>
                                        {ajustes.logo && (
                                            <div className="mt-2 d-flex align-items-center">
                                                <a href={ajustes.logo} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-primary">
                                                    <i className="bi bi-link-45deg me-1"></i> Visualizar
                                                </a>
                                                <div className="ms-3 border rounded p-2 d-flex align-items-center justify-content-center" style={{ maxWidth: '150px', maxHeight: '60px', overflow: 'hidden' }}>
                                                    <img 
                                                        src={ajustes.logo} 
                                                        alt="Logo" 
                                                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                                                        onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktaW1hZ2UiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTYuMDAyIDUuNWExLjUgMS41IDAgMSAxLTMgMCAxLjUgMS41IDAgMCAxIDMgMHoiLz48cGF0aCBkPSJNMi4wMDIgMWEyIDIgMCAwIDAtMiAydjEwYTIgMiAwIDAgMCAyIDJoMTJhMiAyIDAgMCAwIDItMlYzYTIgMiAwIDAgMC0yLTJoLTEyem0xMiAxYzU1NSAwIDEgLjQ0OCAxIDF2Ni41bC0zLjc3Ny0xLjk0N2EuNS41IDAgMCAwLS41NzcuMDkzbC0zLjcxIDMuNzEtMi42Ni0xLjc3MmEuNS41IDAgMCAwLS42My4wNjJMMS4wMDIgMTJWM2EuOTk2Ljk5NiAwIDAgMSAxLTFoMTJ6Ii8+PC9zdmc+';
                                                            e.target.style.padding = '10px';
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    
                                    <div className="col-md-6 mb-4">
                                        <label className="form-label fw-bold">Logo Branco</label>
                                        <div className="input-group">
                                            <span className="input-group-text bg-light">
                                                <i className="bi bi-image text-primary"></i>
                                            </span>
                                            <input 
                                                type="text" 
                                                className="form-control"
                                                placeholder="URL da imagem"
                                                {...register('logo_white')}
                                            />
                                            {ajustes.logo_white && (
                                                <button 
                                                    type="button" 
                                                    className="btn btn-outline-danger"
                                                    onClick={() => handleRemoveLink('logo_white')}
                                                >
                                                    <i className="bi bi-trash"></i>
                                                </button>
                                            )}
                                        </div>
                                        {ajustes.logo_white && (
                                            <div className="mt-2 d-flex align-items-center">
                                                <a href={ajustes.logo_white} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-primary">
                                                    <i className="bi bi-link-45deg me-1"></i> Visualizar
                                                </a>
                                                <div className="ms-3 border rounded p-2 bg-dark d-flex align-items-center justify-content-center" style={{ maxWidth: '150px', maxHeight: '60px', overflow: 'hidden' }}>
                                                    <img 
                                                        src={ajustes.logo_white} 
                                                        alt="Logo Branco" 
                                                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                                                        onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0id2hpdGUiIGNsYXNzPSJiaSBiaS1pbWFnZSIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNNi4wMDIgNS41YTEuNSAxLjUgMCAxIDEtMyAwIDEuNSAxLjUgMCAwIDEgMyAweiIvPjxwYXRoIGQ9Ik0yLjAwMiAxYTIgMiAwIDAgMC0yIDJ2MTBhMiAyIDAgMCAwIDIgMmgxMmEyIDIgMCAwIDAgMi0yVjNhMiAyIDAgMCAwLTItMmgtMTJ6bTEyIDFjNTU1IDAgMSAuNDQ4IDEgMXY2LjVsLTMuNzc3LTEuOTQ3YS41LjUgMCAwIDAtLjU3Ny4wOTNsLTMuNzEgMy43MS0yLjY2LTEuNzcyYS41LjUgMCAwIDAtLjYzLjA2MkwxLjAwMiAxMlYzYS45OTYuOTk2IDAgMCAxIDEtMWgxMnoiLz48L3N2Zz4=';
                                                            e.target.style.padding = '10px';
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    
                                    <div className="col-md-6 mb-4">
                                        <label className="form-label fw-bold">Favicon</label>
                                        <div className="input-group">
                                            <span className="input-group-text bg-light">
                                                <i className="bi bi-bookmark text-primary"></i>
                                            </span>
                                            <input 
                                                type="text" 
                                                className="form-control"
                                                placeholder="URL da imagem"
                                                {...register('favicon')}
                                            />
                                            {ajustes.favicon && (
                                                <button 
                                                    type="button" 
                                                    className="btn btn-outline-danger"
                                                    onClick={() => handleRemoveLink('favicon')}
                                                >
                                                    <i className="bi bi-trash"></i>
                                                </button>
                                            )}
                                        </div>
                                        {ajustes.favicon && (
                                            <div className="mt-2 d-flex align-items-center">
                                                <a href={ajustes.favicon} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-primary">
                                                    <i className="bi bi-link-45deg me-1"></i> Visualizar
                                                </a>
                                                <div className="ms-3 border rounded p-2 d-flex align-items-center justify-content-center" style={{ maxWidth: '60px', maxHeight: '60px', overflow: 'hidden' }}>
                                                    <img 
                                                        src={ajustes.favicon} 
                                                        alt="Favicon" 
                                                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                                                        onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktYm9va21hcmsiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTIgMmE1IDUgMCAwIDEgNSAwdjEyYTEgMSAwIDAgMS0uOC45NzlMMiAxM1YyeiIvPjxwYXRoIGQ9Ik0xNCAyYTEgMSAwIDAgMS0uOC45NzlMMTAgM1YyYTUgNSAwIDAgMSA1IDB2MTJhMSAxIDAgMCAxLS44Ljk3OUwxMCAxM1YyeiIvPjwvc3ZnPg==';
                                                            e.target.style.padding = '10px';
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    
                                    <div className="col-md-6 mb-4">
                                        <label className="form-label fw-bold">Diploma</label>
                                        <div className="input-group">
                                            <span className="input-group-text bg-light">
                                                <i className="bi bi-award text-primary"></i>
                                            </span>
                                            <input 
                                                type="text" 
                                                className="form-control"
                                                placeholder="URL da imagem"
                                                {...register('diploma')}
                                            />
                                            {ajustes.diploma && (
                                                <button 
                                                    type="button" 
                                                    className="btn btn-outline-danger"
                                                    onClick={() => handleRemoveLink('diploma')}
                                                >
                                                    <i className="bi bi-trash"></i>
                                                </button>
                                            )}
                                        </div>
                                        {ajustes.diploma && (
                                            <div className="mt-2 d-flex align-items-center">
                                                <a href={ajustes.diploma} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-primary">
                                                    <i className="bi bi-link-45deg me-1"></i> Visualizar
                                                </a>
                                                <div className="ms-3 border rounded p-2 d-flex align-items-center justify-content-center" style={{ maxWidth: '150px', maxHeight: '60px', overflow: 'hidden' }}>
                                                    <img 
                                                        src={ajustes.diploma} 
                                                        alt="Diploma" 
                                                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                                                        onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktYXdhcmQiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTkuNjY5LjU2NGE4IDggMCAwIDAtMy4zMzggMGMtLjA0LjAxNS0uMDQ0LjAzMi0uMDUxLjA3bC0uNDUgNC45NTdhLjc1Ljc1IDAgMCAwIC42OTIuODAyLjc1Ljc1IDAgMCAwIC44MDItLjY5MmxuMDg0LS45MmMuMDc3LS44NTIuNTQ0LTEuNjEzIDEuMjM4LTIuMDQzLjI5My0uMTguNjMtLjI3Ljk5Mi0uMjcuMzYzIDAgLjcuMDkuOTkzLjI3LjY5NC40My0xLjE2IDEuMTkyIDEuMjM4IDIuMDQzbC4wODQuOTJhLjc1Ljc1IDAgMCAwIC44MDIuNjkyLjc1Ljc1IDAgMCAwIC42OTItLjgwMkw5LjcyLjYzNGMtLjAwNy0uMDM4LS4wMS0uMDU1LS4wNTEtLjA3ek04IDEuNzgzQzcuMDE1LjkzNiA1LjU4Ny43ODEgNC4yODcuOTRjLTEuNTE0LjE4Ni0zLjA0LjkzLTQuMTc0IDIuMDYzQTUuMzkyIDUuMzkyIDAgMCAwIC4wMzMgNS4zOGEuNzUuNzUgMCAwIDAgMS40NTQuMzY0Yy4wNjktLjI3Ny4xODktLjUzOS4zNTktLjc3LjY0LS44NjEgMS42OTEtMS40NDMgMi43NS0xLjU0NSAxLjA2LS4xMDEgMi4xMjEuMDc2IDIuOTgyLjcxNi4yODYuMjEzLjUwOC40ODUuNjcuODAzYS43NS43NSAwIDAgMCAxLjUwNCAwYy4xNjItLjMxOC4zODQtLjU5LjY3LS44MDMuODYtLjY0IDEuOTIyLS44MTcgMi45ODItLjcxNiAxLjA2LjEwMiAyLjExLjY4NCAyLjc1IDEuNTQ0LjE3LjIzMi4yOS40OTQuMzYuNzcxYS43NS43NSAwIDAgMCAxLjQ1My0uMzY0QTUuMzk3IDUuMzk3IDAgMCAwIDE1Ljg4NyAzYy0xLjEzNC0xLjEzNC0yLjY2LTEuODc3LTQuMTc0LTIuMDYzLTEuMy0uMTU5LTIuNzI5LS4wMDQtMy43MTMuODQzek03LjUgMTIuNWEuNS41IDAgMCAwIDEgMFY4LjVhLjUuNSAwIDAgMC0xIDB2NHoiLz48cGF0aCBkPSJNNy41IDEwYS41LjUgMCAwIDEgLjUtLjVoMWEuNS41IDAgMCAxIDAgMWgtMWEuNS41IDAgMCAxLS41LS41eiIvPjwvc3ZnPg==';
                                                            e.target.style.padding = '10px';
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    
                                    <div className="col-md-6 mb-4">
                                        <label className="form-label fw-bold">Flag</label>
                                        <div className="input-group">
                                            <span className="input-group-text bg-light">
                                                <i className="bi bi-flag text-primary"></i>
                                            </span>
                                            <input 
                                                type="text" 
                                                className="form-control"
                                                placeholder="URL da imagem"
                                                {...register('flag')}
                                            />
                                            {ajustes.flag && (
                                                <button 
                                                    type="button" 
                                                    className="btn btn-outline-danger"
                                                    onClick={() => handleRemoveLink('flag')}
                                                >
                                                    <i className="bi bi-trash"></i>
                                                </button>
                                            )}
                                        </div>
                                        {ajustes.flag && (
                                            <div className="mt-2 d-flex align-items-center">
                                                <a href={ajustes.flag} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-primary">
                                                    <i className="bi bi-link-45deg me-1"></i> Visualizar
                                                </a>
                                                <div className="ms-3 border rounded p-2 d-flex align-items-center justify-content-center" style={{ maxWidth: '150px', maxHeight: '60px', overflow: 'hidden' }}>
                                                    <img 
                                                        src={ajustes.flag} 
                                                        alt="Flag" 
                                                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                                                        onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktZmxhZyIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNMTQuNzc4LjA4NUExLjUgMS41IDAgMCAxIDE2IDEuNXY3YTEuNSAxLjUgMCAwIDEtMS41IDEuNWgtMy4wMzlsLjUzMiAzLjIxOGEuNS41IDAgMCAxLS45OTMuMTY0bC0uOTUtNS43YS41LjUgMCAwIDEgLjQ5NS0uNTgyaDQuOTU1YS41LjUgMCAwIDAgLjUtLjV2LTdhLjUuNSAwIDAgMC0uNS0uNUgxLjVhLjUuNSAwIDAgMC0uNS41djlhLjUuNSAwIDAgMCAxIDBWMS41YS41LjUgMCAwIDEgLjUtLjVoMTIuNzc4eiIvPjwvc3ZnPg==';
                                                            e.target.style.padding = '10px';
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end">
                                    <button type="submit" className="btn btn-primary">
                                        <i className="bi bi-save me-2"></i>
                                        Salvar Ajustes
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Ajustes; 