import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Api from "../Auth/Api";
import { Modal, Toast } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
import { PDFDownloadLink } from '@react-pdf/renderer';
import GerarPDF from "../Components/GerarPDF";
import { decodeJWT, truncateString } from '../Components/jwtUtils';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import TextareaAutosize from "react-textarea-autosize";
import TableUsuariosProvas from "../Components/TableUsuariosProvas";
import { useMemo } from "react";
import QRCode from 'qrcode.react';

function Aulas() {

    const navigate = useNavigate();
    const { id } = useParams();
    const token = JSON.parse(localStorage.getItem("user_token"));
    const nivel = decodeJWT(token).nivel;
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [dataInfoModulo, setDataInfoModulo] = useState([]);
    const [dataAulas, setDataAulas] = useState([]);
    const [showModalMinhasNotas, setShowModalMinhasNotas] = useState(false);
    const [showModalAtividadeExtra, setShowModalAtividadeExtra] = useState(false);
    const [showModalEditarAula, setShowModalEditarAula] = useState(false);
    const [file0Selected, setFile0Selected] = useState(false);
    const [file1Selected, setFile1Selected] = useState(false);
    const [file2Selected, setFile2Selected] = useState(false);
    const [uploadAulaProgress, setUploadAulaProgress] = useState(0);
    const [showModalDeletar, setShowModalDeletar] = useState(false);
    const [deletarAula, setDeletarAula] = useState([]);
    const [showModalAtribuirNotas, setShowModalAtribuirNotas] = useState(false);
    const [showModalAlunosComNotas, setShowModalAlunosComNotas] = useState(false);
    const [dataUsuarios, setDataUsuarios] = useState([]);
    const [filteredUsuarios, setFilteredUsuarios] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [notas, setNotas] = useState({});
    const { register, handleSubmit, control, setValue, reset, formState: { errors } } = useForm();
    const [alternatives, setAlternatives] = useState([]);
    const [selectedQuestao, setSelectedQuestao] = useState(null);
    const [qrCodeData, setQrCodeData] = useState('');
    const [showModalQrCode, setShowModalQrCode] = useState(false);
    const [arquivos, setArquivos] = useState({});
    const [todosArquivosEnviados, setTodosArquivosEnviados] = useState(false);
    const [showModalFinalGrupo, setShowModalFinalGrupo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalTemAvaliacao, setModalTemAvaliacao] = useState(false);
    const statusAvaliacao = checkStatusAvaliacao(dataInfoModulo);

    ////// Inicio Avaliação P1

    const [showModalAvaliacao, setShowModalAvaliacao] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [cursoEditarFile, setCursoEditarFile] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [reloadQuestoes, setReloadQuestoes] = useState(false);
    const [correctAnswer, setCorrectAnswer] = useState('');
    const [progressoAvaliacao, setProgressoAvaliacao] = useState('');
    const [dataQuestoes, setDataQuestoes] = useState([]);
    const [isCreating, setIsCreating] = useState(false);

    const handleCreateNewQuestion = () => {
        setSelectedQuestao(null);
        setIsCreating(true); // Entre no modo de criação
        setAlternatives([]); // Limpa a lista de alternativas
        setValue("questao", ''); // Limpe o campo de questão
        setValue("resposta", ''); // Limpe a resposta correta
        setValue("imagem", '');
        setCursoEditarFile(false);
        setPreviewUrl(null);
        setCorrectAnswer('');
        setSelectedValue(null);
        for (let index = 0; index < 10; index++) {
            setValue(`alternativa_${index}`, '');
        }
        async function fetchData() {
            try {
                const response = await Api.get(`api/configuracao-avaliacao/${id}`, { headers: { Authorization: `Bearer ${token}` } });
                setValue("data_disponibilidade", response.data[0].data_disponibilidade);
                setValue("data_disponibilidade_fim", response.data[0].data_disponibilidade_fim);
                setValue("tempo", response.data[0].tempo);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    };

    function checkStatusAvaliacao(dataInfoModulo) {
        if (!dataInfoModulo || dataInfoModulo.length === 0) {
            return "Avaliação indisponível";
        }

        const { porcentagem, disponivel, avaliacao_status } = dataInfoModulo[0].avaliacao_p1;

        // Se o modulo foi completado (porcentagem === 100)
        if (porcentagem === 100 && nivel !== 'Administrador') {
            // Se a avaliação está disponível para ser feita
            if (disponivel === 1 && avaliacao_status === 0) {
                return "Fazer avaliação";
            }
            // Se a avaliação já foi feita e está disponível para consulta
            if (avaliacao_status === 1) {
                return "Consultar avaliação";
            }
        }

        // Caso a avaliação não esteja disponível ou completa
        return "Avaliação indisponível";
    }

    // Buscar questoes
    useEffect(() => {
        setDataQuestoes([]); // Limpa a questão antes de ler a api
        async function fetchData() {
            try {
                const response = await Api.get(`api/questoes/${id}`, { headers: { Authorization: `Bearer ${token}` } });
                setDataQuestoes(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        nivel === 'Administrador' && fetchData();
    }, [token, id, reloadQuestoes, nivel]);

    // O que faz quando uma questão é selecionada
    useEffect(() => {
        if (selectedQuestao) {
            setAlternatives(JSON.parse(selectedQuestao.alternativas));
            // Use setValue para definir a alternativa correta
            setValue("resposta", selectedQuestao.resposta ? parseInt(selectedQuestao.resposta) : '');
        }
    }, [selectedQuestao, setValue, dataQuestoes]);

    const addAlternative = () => {
        setAlternatives([...alternatives, '']);
    };

    // Atualize a função removeAlternative para manter a resposta correta atualizada
    const removeAlternative = (index) => {
        const updatedAlternatives = [...alternatives];
        updatedAlternatives.splice(index, 1);
        setAlternatives(updatedAlternatives);
        setValue(`alternativa_${index}`, '');

        // Atualize a resposta correta se a alternativa removida for a correta
        if (index + 1 === correctAnswer) {
            setCorrectAnswer('');
        }
    };

    // Atualize setSelectedValues para definir o valor correto para correctAnswer
    const setSelectedValues = useCallback((selected) => {
        setValue("questao", selected.questao);
        setAlternatives(JSON.parse(selected.alternativas));
        setCorrectAnswer(selected.resposta ? parseInt(selected.resposta) : '');
    }, [setValue]);

    useEffect(() => {
        if (selectedQuestao) {
            setSelectedValues(selectedQuestao);
            setAlternatives(JSON.parse(selectedQuestao.alternativas));
            JSON.parse(selectedQuestao.alternativas).forEach((alt, index) => {
                setValue(`alternativa_${index}`, alt);
            });
        }
    }, [selectedQuestao, setSelectedValues, setValue]);

    const onSubmit = async (data) => {

        const alternativas = alternatives.map((_, index) => data[`alternativa_${index}`]);
        const resultJson = {
            questao: data.questao,
            alternativas: JSON.stringify(alternativas),
            resposta: data.resposta.toString(),
            tempo: data.tempo,
            data_disponibilidade: data.data_disponibilidade,
            data_disponibilidade_fim: data.data_disponibilidade_fim,
            imagem: data.imagem,
            idModulo: id
        };

        const formData = new FormData();

        Object.keys(resultJson).forEach(key => {
            const value = resultJson[key];  // Use resultJson aqui
            if (typeof value === 'object' && value !== null && !(value instanceof FileList)) {
                formData.append(key, JSON.stringify(value));
            } else if (value instanceof FileList) {
                Array.from(value).forEach(file => {
                    formData.append(key, file);
                });
            } else {
                formData.append(key, value);
            }
        });

        // function logFormData(fd) {
        //     for (let pair of fd.entries()) {
        //         console.log(pair[0] + ': ' + pair[1]);
        //     }
        // }

        // logFormData(formData);


        if (isCreating) {
            // Lógica para criar uma nova questão
            try {
                const response = await Api.post(`api/criar-questao/${id}`, formData, { headers: { Authorization: `Bearer ${token}` } });
                setToast({ header: 'Sucesso!', body: response.data.message });
            } catch (error) {
                setToast({ header: 'Erro!', body: error.response.data.message });
                console.log(error);
            }

        } else {
            // Lógica para editar uma questão existente
            try {
                const response = await Api.post(`api/editar-questao/${selectedQuestao.id}`, formData, { headers: { Authorization: `Bearer ${token}` } });
                setToast({ header: 'Sucesso!', body: response.data.message });
            } catch (error) {
                setToast({ header: 'Erro!', body: error.response.data.message });
                console.log(error);
            }
        }


        setShowToast(true);
        setPreviewUrl(null);
        setReloadQuestoes(prevState => !prevState)
        setSelectedQuestao(null);
        setIsCreating(false);
        setAlternatives([]); // Limpa a lista de alternativas
        setValue("imagem", '');
        setValue("questao", ''); // Limpe o campo de questão
        setValue("resposta", ''); // Limpe a resposta correta
        setCorrectAnswer(''); // Limpa questao correta
        setSelectedValue(null); // Limpe o valor selecionado
        for (let index = 0; index < 20; index++) {
            setValue(`alternativa_${index}`, '');
        }
    };

    const {
        register: register_A,
        handleSubmit: handleSubmit_A,
        control: control_A,
        setValue: setValue_A,
        formState: { errors: errors_A },
    } = useForm();

    const handleDeleteQuestion = (id) => {

        setSelectedQuestao(null);
        setIsCreating(false);
        setAlternatives([]); // Limpa a lista de alternativas
        setValue("questao", ''); // Limpe o campo de questão
        setValue("resposta", ''); // Limpe a resposta correta
        setCorrectAnswer(''); // Limpa questao correta
        setSelectedValue(null); // Limpe o valor selecionado
        for (let index = 0; index < 20; index++) {
            setValue(`alternativa_${index}`, '');
        }

        async function fetchData() {
            try {
                const response = await Api.delete(`api/deletar-questao/${id}`, { headers: { Authorization: `Bearer ${token}` } });
                setSelectedValue(null)
                setReloadQuestoes(prevState => !prevState)
                setToast({ header: 'Sucesso!', body: response.data.message })
                setShowToast(true)
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }

    //Listar avaliacao e respostas
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/info-progresso-avaliacao/${id}`, { headers: { Authorization: `Bearer ${token}` } });
                setProgressoAvaliacao(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }

        nivel !== 'Administrador' && fetchData();
    }, [token, id, nivel]);

    const handleThumbnailChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setPreviewUrl(e.target.result);
            }
            reader.readAsDataURL(file);
        }
    }

    /////// Fim Avaliação P1


    // Buscar info do modulo
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/info-modulo/${id}`, { headers: { Authorization: `Bearer ${token}` } });
                setDataInfoModulo(response.data);
            } catch (error) {
                console.error(error.response.data.message)
                navigate("/");
            }
        }
        fetchData();
    }, [token, id, navigate]);

    // Listar aulas
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-aulas/${id}`, { headers: { Authorization: `Bearer ${token}` } });
                setDataAulas(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token, id, toast]);

    const customStyles = {
        control: (base, state) => ({
            ...base,
            fontSize: "1rem",
            backgroundColor: "#ffffff",
            borderRadius: "0.25rem",
            boxShadow: state.isFocused ? "0 0 0 0.25rem #27485b73" : null,
            borderColor: "#233734",
            "&:hover": {
                borderColor: "#3a6883"
            },
        }),
        singleValue: (base) => ({
            ...base,
            color: '#000',
        }),
        input: (base) => ({
            ...base,
            color: '#adb5bd',
            height: "52px",
            margin: '0px',
            padding: '0px !important',

        }),
        menu: (base) => ({
            ...base,
            backgroundColor: '#fff',
        }),
        option: (base, state) => ({
            ...base,
            color: state.isSelected ? '#3a5a6c' : null,
            backgroundColor: state.isFocused ? '#27485b73' : null,
            "&:active": {
                backgroundColor: "#27485b73"
            },
        }),
    };

    // Buscar provas e atividades extras
    const [dataProvasExtras, setDataProvasExtras] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`/api/provas-extras-aluno/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataProvasExtras(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token, id])

    const columns = useMemo(
        () => [
            {
                Header: () => null, // Sem título para esta coluna
                id: 'nome_avaliacao',
                accessor: row => row.nomeAvaliacao,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className="d-flex flex-column">
                        <span className="text-bold text-wrap">{value}</span>
                        <span className="text-muted fs-7">por {original.nomeGestor}</span>
                    </div>
                )
            },
            {
                Header: () => null,
                id: 'status',
                accessor: row => row.status,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <>
                        {value === 1 ?
                            <div className="badge bg-success text-white">Concluido</div>
                            :
                            <div className="badge bg-dark text-white">Pendente</div>
                        }
                    </>
                )
            },
            {
                Header: () => null, // Sem título para esta coluna
                id: 'id',
                accessor: row => row.id,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className="d-flex justify-content-end align-items-end">
                        {original.status === 0 ? (
                            <button onClick={() => { navigate(`/questionario/extra/${original.idAvaliacao}`) }} className="btn link text-primary rounded px-2">
                                Fazer avaliação
                            </button>
                        ) : (
                            <PDFDownloadLink document={<GerarPDF data={original.idAvaliacao} tipo="avaliacao_extra" titulo={original.nomeAvaliacao} />} fileName={`avaliacao_${original.nomeAvaliacao}.pdf`}>
                                <div className="btn link text-primary rounded px-2">
                                    Consultar
                                </div>
                            </PDFDownloadLink>
                        )
                        }

                    </div>
                )
            }
        ], [navigate]
    )

    const handleDeletarAula = async (id) => {
        try {
            const response = await Api.delete(`api/deletar-aula/${id}`, { headers: { Authorization: `Bearer ${token}` } });
            setToast({ header: 'Sucesso!', body: response.data.message })
        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error(error.response.data.message)
        }
        setShowModalDeletar(false)
        setShowToast(true)
    }

    const onSubmitEditarAula = async (data) => {

        const formData = new FormData();
        Object.keys(data).forEach(key => {
            const value = data[key];
            if (typeof value === 'object' && value !== null && !(value instanceof FileList)) {
                formData.append(key, JSON.stringify(value));
            } else if (value instanceof FileList) {
                Array.from(value).forEach(file => {
                    formData.append(key, file)
                });
            } else {
                formData.append(key, value)
            }
        });

        try {
            const response = await Api.post("/api/editar-aula", formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setUploadAulaProgress(percentCompleted + "%")
                    if (percentCompleted === 100) {
                        setTimeout(() => {
                            setUploadAulaProgress('Aguarde... finalizando...')
                        }, 1000)
                    }
                }
            });

            setToast({ header: 'Sucesso!', body: response.data.message })
            setFile0Selected(false)
            setFile1Selected(false)
            setFile2Selected(false)
            setUploadAulaProgress(0)

        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao criar nova aula:", error.response.data.message)
        }
        setShowToast(true)
    };

    const handleAtribuirNotas = async () => {
        try {
            const response = await Api.get(`/api/listar-usuarios-curso-por-modulo/${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setDataUsuarios(response.data);
            setFilteredUsuarios(response.data);
            setShowModalAtribuirNotas(true);
            setSearchTerm('');
        } catch (error) {
            console.error(error.response.data.message)
        }
    };

    const handleNotaChange = (id, value) => {
        setNotas(prevNotas => ({
            ...prevNotas,
            [id]: value
        }));
    };

    const handleSearch = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);
        if (term) {
            setFilteredUsuarios(dataUsuarios.filter(usuario =>
                usuario.nome_completo.toLowerCase().includes(term)
            ));
        } else {
            setFilteredUsuarios(dataUsuarios);
        }
    };

    const handleSubmitNotas = () => {
        // console.log('Notas atribuídas:', notas);
        setShowModalAtribuirNotas(false);
        setShowModalAlunosComNotas(true);
    };

    const alunosComNotas = dataUsuarios.filter(usuario => notas[usuario.id]);

    const handleUpload = (idAluno) => {
        document.getElementById(`file-input-${idAluno}`).click();
    };

    const handleFileChange = (event, idAluno) => {
        const file = event.target.files[0];
        if (file) {
            setArquivos(prevArquivos => ({
                ...prevArquivos,
                [idAluno]: file
            }));
        }
    };

    useEffect(() => {
        const todosArquivosEnviados = alunosComNotas.every(usuario => arquivos[usuario.id]);
        setTodosArquivosEnviados(todosArquivosEnviados);
    }, [arquivos, alunosComNotas]);


    const handleGenerateQrCode = (idAluno) => {
        const url = `https://api.com/${id}/${idAluno}`;
        setQrCodeData(url);
        setShowModalQrCode(true);
        setShowModalAlunosComNotas(false);
    };

    const handleConcluir = async () => {
        setLoading(true)
        const dadosParaEnvio = alunosComNotas.map(usuario => ({
            idUsuario: usuario.id,
            acerto: notas[usuario.id],
            nota: notas[usuario.id]
        }));

        const formData = new FormData();
        formData.append('idModulo', id);
        formData.append('avaliacoes', JSON.stringify(dadosParaEnvio));

        alunosComNotas.forEach(usuario => {
            if (arquivos[usuario.id]) {
                formData.append(`file_${usuario.id}`, arquivos[usuario.id]);
            }
        });

        try {
            await Api.post('/api/salvar-avaliacoes', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            setShowModalFinalGrupo(true);
            setShowModalAlunosComNotas(false);
            setLoading(false);
        } catch (error) {
            console.error('Erro ao enviar dados:', error.response.data);
        }

        // function logFormData(fd) {
        //     for (let pair of fd.entries()) {
        //         console.log(pair[0] + ': ' + pair[1]);
        //     }
        // }
        // logFormData(formData);

    };

    useEffect(() => {
        if (statusAvaliacao === 'Fazer avaliação') {
            setTimeout(() => {
                setModalTemAvaliacao(true);
            }, 2000);
        }
    }, [statusAvaliacao]);

    return (
        <>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide style={{ position: 'fixed', left: '95%', bottom: '10px', transform: 'translateX(-95%)', zIndex: 9999 }}>
                <Toast.Header>
                    <strong className="mr-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>

            {dataInfoModulo[0] &&

                <div className="fade-in w-100 mb-5">
                    <Link onClick={() => navigate(-1)} className="btn text-primary link border-0 fs-6"><i className="bi bi-chevron-left"></i>Voltar</Link>

                    {/* Banner responsivo com versões para desktop e mobile */}
                    <div className="card card-flag rounded-5 w-100 overflow-hidden mb-4 border-none position-relative">
                        {/* Imagem e overlay do banner */}
                        <img src={`${process.env.REACT_APP_IMG_URL}/${dataInfoModulo[0].thumbnail}`} className="card-img w-100 card-img-banner" alt={dataInfoModulo[0].nome} />
                        <div className="position-absolute w-100 h-100" style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}></div>
                        
                        {/* Conteúdo adaptativo do banner */}
                        <div className="card-img-overlay d-flex flex-column justify-content-between">
                            {/* Cabeçalho com título */}
                            <div className="text-white text-center my-auto">
                                <h6 className="mb-0">{dataInfoModulo[0].nome}</h6>
                            </div>
                            
                            {/* Opções de navegação - Forma diferente para desktop e mobile */}
                            <div className="d-flex flex-column d-md-none align-items-center gap-2">
                                {nivel !== 'Administrador' && (
                                    (() => {
                                        if (statusAvaliacao === "Fazer avaliação") {
                                            return (
                                                <div className="rounded-pill p-1 px-3 bg-primary fw-semibold w-100 text-center">
                                                    <span><Link to={`/questionario/${id}`} className="text-white">Fazer avaliação</Link></span>
                                                </div>
                                            );
                                        } else if (statusAvaliacao === "Consultar avaliação" && progressoAvaliacao.progresso_avaliacao) {
                                            return (
                                                <PDFDownloadLink
                                                    document={<GerarPDF data={progressoAvaliacao.progresso_avaliacao} titulo={dataInfoModulo[0].nome} tipo="avaliacao" />}
                                                    fileName={`avaliacao_p1_${dataInfoModulo[0].nome}.pdf`}
                                                >
                                                    <div className="rounded-pill p-1 px-3 bg-warning fw-semibold w-100 text-center text-dark">
                                                        Consultar avaliação
                                                    </div>
                                                </PDFDownloadLink>
                                            );
                                        } else {
                                            return (
                                                <div className="text-center w-100">
                                                    <Tippy content="Avaliação não está disponível">
                                                        <div className="rounded-pill p-1 px-3 bg-dark fw-semibold cursor-disabled">
                                                            <span className="text-white text-opacity-50">Avaliação indisponível</span>
                                                        </div>
                                                    </Tippy>
                                                </div>
                                            );
                                        }
                                    })()
                                )}

                                {(nivel === 'Administrador' || nivel === 'Gestor' || nivel === 'RH') && (
                                    <div className="rounded-pill p-1 px-3 bg-white fw-semibold w-100 text-center">
                                        <Link to="#" onClick={handleAtribuirNotas} className="text-primary">Atribuir notas em grupo</Link>
                                    </div>
                                )}
                                
                                {nivel === 'Administrador' && (
                                    <div className="rounded-pill p-1 px-3 bg-primary fw-semibold w-100 text-center">
                                        <span><Link onClick={() => setShowModalAvaliacao(true)} className="text-white">Configurar Avaliação</Link></span>
                                    </div>
                                )}

                                {(nivel === 'Administrador' || nivel === 'Gestor' || nivel === 'RH') && (
                                    <PDFDownloadLink
                                        document={<GerarPDF data={dataInfoModulo && dataInfoModulo[0].id} tipo="avaliacao_normal_visualizacao" titulo={dataInfoModulo && dataInfoModulo[0].nome} />}
                                        fileName={`avaliacao_${dataInfoModulo && dataInfoModulo[0].nome}.pdf`}
                                    >
                                        <div className="rounded-pill p-1 px-3 cursor-pointer bg-white fw-normal w-100 text-center">
                                            <div className="text-primary">Imprimir Avaliação</div>
                                        </div>
                                    </PDFDownloadLink>
                                )}

                                {nivel !== 'Administrador' && (
                                    <>
                                        <div className="rounded-pill p-1 px-3 bg-light fw-semibold w-100 text-center">
                                            <span><Link onClick={() => setShowModalAtividadeExtra(true)} className="text-primary">Atividades / Provas extras</Link></span>
                                        </div>

                                        <div className="cursor-pointer rounded-pill p-1 px-3 bg-primary fw-semibold w-100 text-center" onClick={() => setShowModalMinhasNotas(true)}>
                                            <span className="text-white">Minhas notas</span>
                                        </div>
                                    </>
                                )}
                            </div>
                            
                            {/* Versão desktop com layout horizontal */}
                            <div className="d-none d-md-flex flex-row flex-wrap justify-content-start align-items-start gap-2">
                                {nivel !== 'Administrador' && (
                                    (() => {
                                        if (statusAvaliacao === "Fazer avaliação") {
                                            return (
                                                <div className="rounded-pill p-1 px-3 bg-primary fw-semibold me-3">
                                                    <span><Link to={`/questionario/${id}`} className="text-white">Fazer avaliação</Link></span>
                                                </div>
                                            );
                                        } else if (statusAvaliacao === "Consultar avaliação" && progressoAvaliacao.progresso_avaliacao) {
                                            return (
                                                <PDFDownloadLink
                                                    document={<GerarPDF data={progressoAvaliacao.progresso_avaliacao} titulo={dataInfoModulo[0].nome} tipo="avaliacao" />}
                                                    fileName={`avaliacao_p1_${dataInfoModulo[0].nome}.pdf`}
                                                >
                                                    <div className="rounded-pill p-1 px-3 bg-warning fw-semibold me-3 text-dark">
                                                        Consultar avaliação
                                                    </div>
                                                </PDFDownloadLink>
                                            );
                                        } else {
                                            return (
                                                <div className="text-center">
                                                    <Tippy content="Avaliação não está disponível">
                                                        <div className="rounded-pill p-1 px-3 bg-dark fw-semibold me-3 cursor-disabled">
                                                            <span className="text-white text-opacity-50">Avaliação indisponível</span>
                                                        </div>
                                                    </Tippy>
                                                </div>
                                            );
                                        }
                                    })()
                                )}

                                {(nivel === 'Administrador' || nivel === 'Gestor' || nivel === 'RH') && (
                                    <div className="rounded-pill p-1 px-3 bg-white fw-semibold me-3">
                                        <Link to="#" onClick={handleAtribuirNotas} className="text-primary">Atribuir notas em grupo</Link>
                                    </div>
                                )}
                                
                                {nivel === 'Administrador' && (
                                    <div className="rounded-pill p-1 px-3 bg-primary fw-semibold me-3">
                                        <span><Link onClick={() => setShowModalAvaliacao(true)} className="text-white">Configurar Avaliação</Link></span>
                                    </div>
                                )}

                                {(nivel === 'Administrador' || nivel === 'Gestor' || nivel === 'RH') && (
                                    <PDFDownloadLink
                                        document={<GerarPDF data={dataInfoModulo && dataInfoModulo[0].id} tipo="avaliacao_normal_visualizacao" titulo={dataInfoModulo && dataInfoModulo[0].nome} />}
                                        fileName={`avaliacao_${dataInfoModulo && dataInfoModulo[0].nome}.pdf`}
                                    >
                                        <div className="rounded-pill p-1 px-3 cursor-pointer bg-white fw-normal me-3">
                                            <div className="text-primary">Imprimir Avaliação</div>
                                        </div>
                                    </PDFDownloadLink>
                                )}

                                {nivel !== 'Administrador' && (
                                    <>
                                        <div className="rounded-pill p-1 px-3 bg-light fw-semibold me-3">
                                            <span><Link onClick={() => setShowModalAtividadeExtra(true)} className="text-primary">Atividades / Provas extras</Link></span>
                                        </div>

                                        <div className="cursor-pointer rounded-pill p-1 px-3 bg-primary fw-semibold me-3" onClick={() => setShowModalMinhasNotas(true)}>
                                            <span className="text-white">Minhas notas</span>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="body p-1 p-md-3">
                        <section className="w-100">

                            {dataInfoModulo[0].descricao &&
                                <div className="row mb-3">
                                    <h5 className="text-primary fw-bold">Descrição do Módulo</h5>
                                    <p>{dataInfoModulo[0].descricao}</p>
                                </div>
                            }

                            <div className="row mb-3 pb-5 pb-md-0">
                                {dataAulas.map((value, index) => {
                                    return (
                                        <div key={value.id} className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-4 mt-2">
                                            <div className={`card-body mt-3 shadow-sm rounded-4 p-3 ${value.aula_assistida === 1 && (nivel !== 'Administrador' || nivel !== 'RH')
                                                    ? 'bg-success'
                                                    : 'bg-white'
                                                }`}>
                                                {/* Cabeçalho do Card */}
                                                <div className="d-flex flex-column mb-3">
                                                    <div className={`card-title ${value.aula_assistida === 1 && (nivel !== 'Administrador' || nivel !== 'RH')
                                                            ? 'text-white'
                                                            : 'text-primary'
                                                        }`}>
                                                        <span className={`small border border-2 rounded-3 px-2 fw-bold fs-6 ${value.aula_assistida === 1 && (nivel !== 'Administrador' || nivel !== 'RH')
                                                                ? 'text-white border-white'
                                                                : 'text-primary border-primary'
                                                            }`}>
                                                            Aula {index + 1}
                                                        </span>
                                                        <span className="ms-2 d-inline-block text-truncate" style={{ maxWidth: '100%', position: 'relative', top: 5 }}>
                                                            {value.titulo}
                                                        </span>
                                                    </div>
                                                </div>

                                                {/* Área de Ações */}
                                                <div className="d-flex flex-wrap align-items-center gap-2">
                                                    {/* Badge Assistida */}
                                                    {value.aula_assistida === 1 && (nivel !== 'Administrador' || nivel !== 'RH') && (
                                                        <span className="bg-white text-success rounded-3 p-1 fs-8">
                                                            <i className="bi bi-check-circle-fill"></i> Assistida
                                                        </span>
                                                    )}

                                                    {/* Botões de Acesso */}
                                                    <div className="d-flex flex-wrap gap-2 ms-auto">
                                                        <Link
                                                            to={`/aula/${value.id}`}
                                                            className={`btn btn-sm ${value.aula_assistida === 1 && (nivel !== 'Administrador' || nivel !== 'RH')
                                                                    ? 'text-white'
                                                                    : 'text-dark link'
                                                                }`}
                                                        >
                                                            Acessar
                                                        </Link>

                                                        {(nivel !== 'Aluno' || (nivel !== 'Administrador' || nivel !== 'RH')) && (
                                                            <Link
                                                                to={`/aula-grupo/${value.id}`}
                                                                className={`btn btn-sm ${value.aula_assistida === 1 && (nivel !== 'Administrador' || nivel !== 'RH')
                                                                        ? 'text-white'
                                                                        : 'text-dark link'
                                                                    }`}
                                                            >
                                                                Acessar em Grupo
                                                            </Link>
                                                        )}
                                                    </div>

                                                    {/* Ações do Administrador */}
                                                    {nivel === 'Administrador' && (
                                                        <div className="d-flex gap-2">
                                                            <Tippy content="Editar Aula">
                                                                <button
                                                                    onClick={() => {
                                                                        setShowModalEditarAula(true)
                                                                        setValue_A('id', value.id)
                                                                        setValue_A('titulo', value.titulo)
                                                                        setValue_A('descricao', value.descricao)
                                                                        setValue_A('resumo', value.resumo)
                                                                        setUploadAulaProgress(0)
                                                                    }}
                                                                    className="btn btn-sm btn-primary"
                                                                >
                                                                    <i className="bi bi-pencil"></i>
                                                                </button>
                                                            </Tippy>

                                                            <Tippy content="Excluir Aula">
                                                                <button
                                                                    onClick={() => { setShowModalDeletar(true); setDeletarAula({ id: value.id, titulo: value.titulo }) }}
                                                                    className="btn btn-sm btn-primary"
                                                                >
                                                                    <i className="bi bi-trash"></i>
                                                                </button>
                                                            </Tippy>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>

                        </section>
                    </div>

                </div>
            }

            <Modal size="lg" show={modalTemAvaliacao} onHide={() => setModalTemAvaliacao(false)} centered>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">📋 Avaliação Disponível</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="d-flex flex-column w-100 text-center">
                            <div className="row mb-3">
                                <div className="col-12">
                                    <p className="fs-5">🎉 A avaliação do módulo <strong>{dataInfoModulo[0] && dataInfoModulo[0].nome}</strong> está disponível!</p>
                                    <p className="fs-6">Clique no botão abaixo para iniciar sua avaliação.</p>
                                    <Link to={`/questionario/${id}`} className="btn btn-secondary text-white mt-3">Iniciar Avaliação</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={showModalAvaliacao} onHide={() => {
                setShowModalAvaliacao(false); setSelectedQuestao(null);
                setIsCreating(false);
                setSelectedValue(null);
                reset()
                for (let index = 0; index < 10; index++) {
                    setValue(`alternativa_${index}`, '');
                }
            }}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Avaliação <span className="text-dark fs-5">({dataInfoModulo[0] && dataInfoModulo[0].nome})</span></span>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="body">
                                    <div className="d-flex mb-1">
                                        <div className="d-flex flex-column w-100">

                                            <div className="row mb-3">

                                                <div className="col-12">
                                                    {!isCreating && (
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Selecione a questão</label>

                                                            <Controller
                                                                name="questaoSelecionada"
                                                                control={control}
                                                                defaultValue={[]}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        options={dataQuestoes.map((value, index) => {
                                                                            setValue("tempo", dataQuestoes[0].tempo);
                                                                            return {
                                                                                value: value.id,
                                                                                label: `${index + 1} - ${truncateString(value.questao)}`,
                                                                            };
                                                                        })}
                                                                        value={selectedValue} // Use o estado aqui
                                                                        styles={customStyles}
                                                                        onChange={(value) => {
                                                                            if (value && selectedQuestao && value.value === selectedQuestao.id) return;
                                                                            field.onChange(value);
                                                                            setSelectedValue(value); // Atualize o estado aqui
                                                                            const selected = dataQuestoes.find((q) => q.id === value.value);
                                                                            setSelectedQuestao(selected);
                                                                            setValue("data_disponibilidade", dataQuestoes[0].data_disponibilidade);
                                                                            setValue("data_disponibilidade_fim", dataQuestoes[0].data_disponibilidade_fim);
                                                                        }}
                                                                        placeholder="Selecione uma questão"
                                                                        isSearchable
                                                                        noOptionsMessage={() => "Nenhuma questão encontrada."}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    )}

                                                    <p className="btn-link fs-7 ms-2 cursor-pointer" onClick={handleCreateNewQuestion}>Adicionar nova questão</p>
                                                    {dataInfoModulo[0] && nivel === "Administrador" &&
                                                        <PDFDownloadLink
                                                            document={<GerarPDF data={dataInfoModulo[0].id} tipo="avaliacao_completa_visualizacao" titulo={dataInfoModulo[0].nome} />}
                                                            fileName={`avaliacao_${dataInfoModulo[0].nome}.pdf`}
                                                        >
                                                            <p className="btn-link fs-7 ms-2 cursor-pointer">Visualizar avaliação</p>
                                                        </PDFDownloadLink>
                                                    }

                                                </div>

                                            </div>
                                            {(selectedQuestao || isCreating) && (
                                                <>
                                                    <div className="row mb-3">
                                                        <div className="col-12 col-md-12">
                                                            <div className="mb-3">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <label className="form-label text-primary fw-medium mb-0">Questão</label>
                                                                    {!isCreating && (
                                                                        <>
                                                                            <i className="bi bi-trash cursor-pointer tt-deletar" onClick={() => handleDeleteQuestion(selectedQuestao.id)}></i>
                                                                            <Tippy content="Deletar essa questão">
                                                                                <i className="bi bi-trash cursor-pointer tt-deletar" onClick={() => handleDeleteQuestion(selectedQuestao.id)}></i>
                                                                            </Tippy>
                                                                        </>
                                                                    )}
                                                                </div>

                                                                <TextareaAutosize
                                                                    rows={3}
                                                                    {...register("questao", { required: true, defaultValue: selectedQuestao ? selectedQuestao.questao : '' })}
                                                                    className="form-control border-primary"
                                                                />
                                                                {errors.questao && <span className='text-danger fs-7'>Questão é obrigatório.</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-12">
                                                            <div className="mb-3">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <label className="form-label text-primary fw-medium mb-0">Imagem</label>
                                                                </div>

                                                                <label htmlFor="imagem" className={`form-control label-input ${cursoEditarFile ? 'border-success bg-success' : 'border-primary bg-primary'} text-white fw-medium text-center`}>
                                                                    <i className="bi bi-card-image me-2"></i> Enviar Imagem
                                                                </label>

                                                                <Controller
                                                                    control={control}
                                                                    name="imagem"
                                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                        <input
                                                                            id='imagem'
                                                                            type="file"
                                                                            onBlur={onBlur}
                                                                            ref={ref}
                                                                            onChange={(e) => {
                                                                                const files = e.target.files;
                                                                                handleThumbnailChange(e);
                                                                                onChange(files);
                                                                                if (files && files.length > 0) {
                                                                                    setCursoEditarFile(true);
                                                                                } else {
                                                                                    setCursoEditarFile(false);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )}
                                                                />

                                                                {previewUrl ?
                                                                    <div className="d-flex justify-content-center mt-2">
                                                                        <img src={previewUrl} className='card-img-question-edit rounded-3' alt="Thumbnail" />
                                                                    </div>
                                                                    :
                                                                    <div className="d-flex justify-content-center mt-2">
                                                                        {selectedQuestao && selectedQuestao.imagem &&
                                                                            <img src={`${process.env.REACT_APP_IMG_URL}/${selectedQuestao.imagem}`} className='card-img-question-edit rounded-3' alt="Thumbnail" />
                                                                        }
                                                                    </div>
                                                                }


                                                            </div>
                                                        </div>

                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium col-12">Alternativas</label>
                                                            {alternatives.map((_, index) => (
                                                                <div className="input-group mb-3" key={index}>
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text bg-primary text-white" id="basic-addon1">{index + 1}</span>
                                                                    </div>
                                                                    <input type="text" {...register(`alternativa_${index}`, { required: true })} className="form-control border-primary" />
                                                                    <button type="button" className="btn btn-primary text-white" onClick={() => removeAlternative(index)}>-</button>
                                                                </div>
                                                            ))}
                                                            <button className="btn btn-primary text-white" type="button" onClick={addAlternative}>+</button>
                                                        </div>


                                                        <div className="col-12 col-md-6">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Alternativa Correta</label>
                                                                <select
                                                                    {...register("resposta")}
                                                                    value={correctAnswer} // Use correctAnswer aqui
                                                                    onChange={(e) => setCorrectAnswer(Number(e.target.value))}
                                                                    aria-label="Default select example"
                                                                    className="form-select border-primary"
                                                                >
                                                                    <option value="">Selecione a alternativa correta</option>
                                                                    {alternatives.map((_, index) => (
                                                                        <option key={index} value={index + 1}>
                                                                            Alternativa {index + 1}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-12 col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Tempo da avaliação</label>
                                                                <input
                                                                    type="number"
                                                                    {...register("tempo", { required: true })}
                                                                    className="form-control border-primary"
                                                                    placeholder="Digite o tempo da avaliação em minutos"
                                                                />
                                                                <label className="form-label text-dark fw-normal fs-7">em minutos</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Data de Disponibilidade</label>
                                                                <input
                                                                    type="date"
                                                                    {...register("data_disponibilidade", { required: true })}
                                                                    className="form-control border-primary"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Disponível até</label>
                                                                <input
                                                                    type="date"
                                                                    {...register("data_disponibilidade_fim", { required: true })}
                                                                    className="form-control border-primary"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {(selectedQuestao || isCreating) && (

                            <div className="d-flex justify-content-center mb-3">
                                <button type="submit" className="btn btn-primary text-white px-5 py-2">
                                    Salvar
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary text-primary px-5 py-2 ms-4"
                                    onClick={() => {
                                        setShowModalAvaliacao(false);
                                        setSelectedQuestao(null);
                                        setIsCreating(false);
                                        reset();
                                        setSelectedValue(null);
                                        for (let index = 0; index < 10; index++) {
                                            setValue(`alternativa_${index}`, '');
                                        }
                                    }}
                                >
                                    Voltar
                                </button>
                            </div>
                        )}


                    </form>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={showModalEditarAula} onHide={() => setShowModalEditarAula(false)}>
                <form onSubmit={handleSubmit_A(onSubmitEditarAula)}>
                    <Modal.Header closeButton>
                        <span className="fw-semibold fs-4 text-primary">Editar Aula</span>
                    </Modal.Header>
                    <Modal.Body>
                        <input type="hidden" {...register_A('id', { required: true })} />
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="body">
                                        <div className="d-flex mb-5">
                                            <div className="d-flex flex-column w-100">
                                                <div className="row">

                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className='col-12 h-100 red-bg d-flex align-items-center justify-content-center rounded-3'>
                                                                <i className="bi bi-cloud-upload icon-large text-white"></i>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 mt-3">
                                                            <div className="row">
                                                                <div className="col-12 col-md-12">
                                                                    <div className="mb-3">
                                                                        <input type="text" {...register_A("titulo", { required: true })} placeholder="Título da aula" className="form-control border-primary" />
                                                                        {errors_A.titulo && <span className='text-danger fs-7'>Aula é obrigatório.</span>}
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-12">
                                                                    <div className="mb-3">
                                                                        <TextareaAutosize type="text" {...register_A("descricao", { required: true })} placeholder="Descrição da aula" className="form-control border-primary" />
                                                                        {errors_A.descricao && <span className='text-danger fs-7'>Aula é obrigatório.</span>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row mt-3'>
                                                        <div className="col-6 col-md-4">
                                                            <div className="mb-3">
                                                                <div className="card border-none">
                                                                    <p className='fs-7'>Arquivo da aula</p>
                                                                    <h5 className="card-header card-upload d-flex align-items-center justify-content-center ">
                                                                        <i className="bi bi-file-earmark-fill"></i>
                                                                    </h5>
                                                                    <div className="card-body d-flex justify-content-center bg-light">
                                                                        <p className="card-text fs-7">
                                                                            <label htmlFor="file_0" className={`cursor-pointer ${file0Selected ? 'text-success' : 'text-primary'}`}>Fazer Upload <i className="bi bi-cloud-upload"></i></label>
                                                                            <Controller
                                                                                control={control_A}
                                                                                name="file_0"
                                                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                                    <input
                                                                                        id='file_0'
                                                                                        type="file"
                                                                                        onBlur={onBlur}
                                                                                        ref={ref}
                                                                                        onChange={(e) => {
                                                                                            const files = e.target.files;
                                                                                            onChange(files);
                                                                                            if (files && files.length > 0) {
                                                                                                setFile0Selected(true);
                                                                                            } else {
                                                                                                setFile0Selected(false);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-4 border-start border-primary">
                                                            <div className="mb-3">
                                                                <div className="card border-none">
                                                                    <p className='fs-7'>Arquivo Auxiliar 1</p>
                                                                    <h5 className="card-header card-upload d-flex align-items-center justify-content-center ">
                                                                        <i className="bi bi-file-earmark-fill"></i>
                                                                    </h5>
                                                                    <div className="card-body d-flex justify-content-center bg-light">
                                                                        <p className="card-text fs-7">
                                                                            <label htmlFor="file_1" className={`cursor-pointer ${file1Selected ? 'text-success' : 'text-primary'}`}>Fazer Upload  <i className="bi bi-cloud-upload"></i></label>
                                                                            <Controller
                                                                                control={control_A}
                                                                                name="file_1"
                                                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                                    <input
                                                                                        id='file_1'
                                                                                        type="file"
                                                                                        onBlur={onBlur}
                                                                                        ref={ref}
                                                                                        onChange={(e) => {
                                                                                            const files = e.target.files;
                                                                                            onChange(files);
                                                                                            if (files && files.length > 0) {
                                                                                                setFile1Selected(true);
                                                                                            } else {
                                                                                                setFile1Selected(false);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4">
                                                            <div className="mb-3">
                                                                <div className="card border-none">
                                                                    <p className='fs-7'>Arquivo Auxiliar 2</p>
                                                                    <h5 className="card-header card-upload d-flex align-items-center justify-content-center ">
                                                                        <i className="bi bi-file-earmark-fill"></i>
                                                                    </h5>
                                                                    <div className="card-body d-flex justify-content-center bg-light">
                                                                        <p className="card-text text-primary fs-7">
                                                                            <label htmlFor="file_2" className={`cursor-pointer ${file2Selected ? 'text-success' : 'text-primary'}`}>Fazer Upload  <i className="bi bi-cloud-upload"></i></label>

                                                                            <Controller
                                                                                control={control_A}
                                                                                name="file_2"
                                                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                                    <input
                                                                                        id='file_2'
                                                                                        type="file"
                                                                                        onBlur={onBlur}
                                                                                        ref={ref}
                                                                                        onChange={(e) => {
                                                                                            const files = e.target.files;
                                                                                            onChange(files);
                                                                                            if (files && files.length > 0) {
                                                                                                setFile2Selected(true);
                                                                                            } else {
                                                                                                setFile2Selected(false);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {errors_A.file_0 && <span className='text-danger fs-7'>Arquivo da aula é obrigatório.</span>}
                                                    </div>


                                                </div>
                                                <div className="progress col-12 mt-4">
                                                    <div className="progress-bar" role="progressbar" style={{ width: `${uploadAulaProgress}` }} aria-valuenow={uploadAulaProgress} aria-valuemin="0" aria-valuemax="100">{uploadAulaProgress}</div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center bg-light">
                        <button type="submit" className="btn btn-primary text-white px-5 py-2">Salvar</button>
                        <button type="button" className="btn btn-outline-primary text-primary px-5 py-2" onClick={() => setShowModalEditarAula(false)}> Cancelar </button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal size='lg' show={showModalDeletar} onHide={() => setShowModalDeletar(false)}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Deletar aula</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="body">
                                    <div className="d-flex">
                                        <div className="d-flex flex-column w-100">
                                            <div className="row">
                                                <div className="col-12">
                                                    <p>Tem certeza que deseja deletar a aula <b>{deletarAula.titulo}</b>?</p>
                                                    <p>Essa ação ira deletar a aula e seu conteúdo? Não será possível recuperar.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center bg-light">
                    <button type="submit" className="btn btn-primary text-white px-5 py-2" onClick={() => handleDeletarAula(deletarAula.id)}>Confirmar</button>
                    <button type="button" className="btn btn-outline-primary text-primary px-5 py-2" onClick={() => setShowModalDeletar(false)}>Cancelar</button>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={showModalMinhasNotas} onHide={() => setShowModalMinhasNotas(false)}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Minhas notas</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="body">
                                    <div className="d-flex">
                                        <div className="d-flex flex-column w-100">
                                            <div className="row">
                                                <div className="table-responsive">
                                                    <table className="table table-sm">
                                                        <thead>
                                                            <tr className="border-1 border-white">
                                                                <th scope="col" className="fw-bold fs-8 text-start text-uppercase text-dark text-opacity-75">Módulo</th>
                                                                <th scope="col" className="fw-bold fs-8 text-center text-uppercase text-dark text-opacity-75">Acertos</th>
                                                                <th scope="col" className="fw-bold fs-8 text-center text-uppercase text-dark text-opacity-75">Nota</th>
                                                            </tr>
                                                        </thead>
                                                        {
                                                            progressoAvaliacao &&
                                                            <tbody>
                                                                {
                                                                    progressoAvaliacao.progresso_avaliacao &&
                                                                    <tr>
                                                                        <th className="text-nowrap text-start fw-normal" scope="row">{progressoAvaliacao.progresso_avaliacao.nomeModulo} - <b>Avaliação</b></th>
                                                                        <th className="text-center">{progressoAvaliacao.progresso_avaliacao.acerto}</th>
                                                                        <th className="text-center">{progressoAvaliacao.progresso_avaliacao.nota ? progressoAvaliacao.progresso_avaliacao.nota : '0'}</th>
                                                                    </tr>
                                                                }

                                                                {
                                                                    progressoAvaliacao.progresso_avaliacao_extra && progressoAvaliacao.progresso_avaliacao_extra.map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <th className="text-start fw-normal text-wrap" scope="row">{item.nomeAvaliacaoExtra}</th>
                                                                                <th className="text-center">{item.acerto}</th>
                                                                                <th className="text-center">{item.nota ? item.nota : '-'}</th>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size='lg' show={showModalAtividadeExtra} onHide={() => setShowModalAtividadeExtra(false)}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Provas extras e atividades</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="body">
                                    <div className="d-flex">
                                        <div className="d-flex flex-column w-100">
                                            <div className="row">
                                                <TableUsuariosProvas columns={columns} data={dataProvasExtras} titulo="Lista de provas" search={true} paging={false} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-light">
                    <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-outline-dark px-5 py-2" onClick={() => setShowModalAtividadeExtra(false)}>Fechar</button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={showModalAtribuirNotas} onHide={() => setShowModalAtribuirNotas(false)}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Atribuir Notas</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row mb-3">
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Buscar usuário"
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                            </div>
                        </div>
                        <div className="row" style={{ maxHeight: 400, overflow: "auto" }}>
                            {filteredUsuarios.map(usuario => (
                                <div key={usuario.id} className="col-12 my-1 d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        {usuario.nome_completo}
                                    </div>
                                    <input
                                        type="number"
                                        className="form-control w-25"
                                        style={{ height: '35px' }}
                                        placeholder="Nota"
                                        value={notas[usuario.id] || ''}
                                        onChange={(e) => handleNotaChange(usuario.id, e.target.value)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-light">
                    <div className="d-flex justify-content-center gap-2">
                        <button type="button" className="btn btn-outline-dark" onClick={() => { setShowModalAtribuirNotas(false); setSearchTerm(null); setNotas(false) }}>Cancelar</button>
                        <button type="button" className="btn btn-primary text-white px-5 py-2" onClick={handleSubmitNotas}>Próximo</button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={showModalAlunosComNotas} onHide={() => setShowModalAlunosComNotas(false)}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Enviar arquivo da prova</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="body">
                                    <div className="d-flex flex-column w-100">
                                        <div className="row" style={{ maxHeight: 400, overflow: "auto" }}>
                                            {alunosComNotas.map(usuario => (
                                                <div key={usuario.id} className="col-12 my-3 d-flex align-items-center">
                                                    <div className="flex-grow-1">
                                                        {usuario.nome_completo} <span className="badge bg-success fs-7">Nota: {notas[usuario.id]}</span>
                                                    </div>
                                                    <i className={`bi ${arquivos[usuario.id] ? 'bi-check-square-fill text-success' : 'bi-square'} me-3`}></i>
                                                    <span className="me-3" style={{ color: "rgb(185 185 185)" }}> | </span>
                                                    <div className="d-flex justify-content-center gap-2">
                                                        <input
                                                            type="file"
                                                            id={`file-input-${usuario.id}`}
                                                            accept=".pdf,image/*"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => handleFileChange(e, usuario.id)}
                                                        />
                                                        <Tippy content="Escolher arquivo do computador">
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={() => handleUpload(usuario.id)}
                                                            >
                                                                <i className="bi bi-file-earmark-richtext"></i>
                                                            </button>
                                                        </Tippy>
                                                        <Tippy content="Tirar foto pelo celular">
                                                            <button
                                                                className="btn btn-primary d-none"
                                                                onClick={() => handleGenerateQrCode(usuario.id)}
                                                            >
                                                                <i className="bi bi-phone"></i>
                                                            </button>
                                                        </Tippy>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-light">
                    <div className="d-flex justify-content-center gap-2">
                        <button type="button" className="btn btn-outline-dark" onClick={() => { setShowModalAlunosComNotas(false); setShowModalAtribuirNotas(true); setTodosArquivosEnviados(false); setArquivos(false) }}>Voltar</button>
                        <button type="button" className="btn btn-primary text-white px-5 py-2" onClick={handleConcluir} disabled={!todosArquivosEnviados || loading}> {loading ? <div className="spinner-border fs-7" style={{ width: 20, height: 20, top: 2, position: 'relative' }} role="status"></div> : 'Finalizar'}</button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal size="md" id="qrCodeModal" show={showModalQrCode} onHide={() => setShowModalQrCode(false)}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">QR Code</span>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {qrCodeData && <QRCode value={qrCodeData} size={256} />}
                    <p className="mt-3">Escaneie este QR code para abrir o link no celular.</p>
                </Modal.Body>
                <Modal.Footer className="bg-light">
                    <div className="d-flex justify-content-center gap-2">
                        <button type="button" className="btn btn-outline-dark" onClick={() => { setShowModalQrCode(false); setShowModalAlunosComNotas(true) }}>Voltar</button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={showModalFinalGrupo} onHide={() => setShowModalFinalGrupo(false)}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Prova Final</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="h-100 bg-white rounded p-5 mt-3 text-center">
                        <div className="row">
                            <div className="col-12">
                                <i className="bi bi-check-circle text-success mb-3" style={{ fontSize: 60 }}></i>
                                <h1 className="text-success">Envios Concluidos!</h1>
                                <p className="lead mt-3">Conluido! Notas e arquivos foram enviados com sucesso.</p>
                                <Link className="btn btn-primary mt-4" onClick={() => setShowModalFinalGrupo(false)}>
                                    Voltar para a aula
                                </Link>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Aulas;