import React, { useState } from 'react';
import { Toast } from 'react-bootstrap';
import perguntas from '../data/perguntas.json';
import { useNavigate } from 'react-router-dom';
import Api from '../Auth/Api';

function PesquisaSatisfacao() {
    const [respostas, setRespostas] = useState({});
    const [toast, setToast] = useState({ show: false, mensagem: '', tipo: '' });
    const navigate = useNavigate();

    const token = JSON.parse(localStorage.getItem("user_token"));

    const opcoes = {
        concordancia: [
            { value: '1', label: 'Discordo Totalmente' },
            { value: '2', label: 'Discordo Parcialmente' },
            { value: '3', label: 'Neutro' },
            { value: '4', label: 'Concordo Parcialmente' },
            { value: '5', label: 'Concordo Totalmente' }
        ],
        dificuldade: [
            { value: '1', label: 'Muito Difícil' },
            { value: '2', label: 'Difícil' },
            { value: '3', label: 'Regular' },
            { value: '4', label: 'Fácil' },
            { value: '5', label: 'Muito Fácil' }
        ],
        satisfacao: [
            { value: '1', label: 'Muito Insatisfeito' },
            { value: '2', label: 'Insatisfeito' },
            { value: '3', label: 'Neutro' },
            { value: '4', label: 'Satisfeito' },
            { value: '5', label: 'Muito Satisfeito' }
        ],
        eficiencia: [
            { value: '1', label: 'Muito Ineficiente' },
            { value: '2', label: 'Ineficiente' },
            { value: '3', label: 'Regular' },
            { value: '4', label: 'Eficiente' },
            { value: '5', label: 'Muito Eficiente' }
        ],
        recomendacao: [
            { value: '1', label: 'Não Recomendaria' },
            { value: '2', label: 'Provavelmente Não' },
            { value: '3', label: 'Talvez' },
            { value: '4', label: 'Provavelmente Sim' },
            { value: '5', label: 'Recomendaria Com Certeza' }
        ]
    };

    const handleChange = (e) => {
        setRespostas(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    const todasRespondidas = () => {
        return perguntas.categorias.every(categoria =>
            categoria.perguntas.every(pergunta =>
                respostas[pergunta.id]
            )
        );
    };

    const formatPayload = () => {
        const formattedPayload = {};
        perguntas.categorias.forEach(categoria => {
            categoria.perguntas.forEach(pergunta => {
                const resposta = respostas[pergunta.id];
                if (resposta) {
                    const opcao = opcoes[pergunta.tipo].find(opt => opt.value === resposta);
                    formattedPayload[pergunta.id] = {
                        pergunta: pergunta.texto,
                        resposta: opcao.label
                    };
                }
            });
        });
        return formattedPayload;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (todasRespondidas()) {
            try {
                const payload = formatPayload();
                
                await Api.post("/api/salvar-pesquisa-satisfacao", payload, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setToast({
                    show: true,
                    mensagem: 'Pesquisa enviada com sucesso!',
                    tipo: 'success'
                });

                localStorage.setItem("pesquisa_satisfacao", true);

                setTimeout(() => {
                    navigate('/');
                }, 2000);
            } catch (error) {
                setToast({
                    show: true,
                    mensagem: error.response?.data?.message || 'Erro ao enviar pesquisa. Tente novamente.',
                    tipo: 'danger'
                });
            }
        }
    };

    return (
        <>
            <Toast 
                show={toast.show} 
                onClose={() => setToast({ ...toast, show: false })}
                delay={3000}
                autohide
                className={`position-fixed bottom-0 end-0 m-4 bg-${toast.tipo}`}
            >
                <Toast.Body className="text-white">{toast.mensagem}</Toast.Body>
            </Toast>

            <div className="fade-in w-100 mb-5">
                <h2 className="text-primary mb-4">Pesquisa de Satisfação</h2>
                <p className="text-muted">Avalie nossos serviços e ajude-nos a melhorar</p>

                <form onSubmit={handleSubmit}>
                    {perguntas.categorias.map((categoria, idx) => (
                        <div key={idx} className="card mb-4 shadow-sm">
                            <div className="card-header bg-primary text-white">
                                <h5 className="mb-0">{categoria.titulo}</h5>
                            </div>
                            <div className="card-body">
                                {categoria.perguntas.map((pergunta) => (
                                    <div key={pergunta.id} className="mb-4">
                                        <label className="form-label fw-bold">{pergunta.texto}</label>
                                        <div className="d-flex flex-column gap-2">
                                            {opcoes[pergunta.tipo].map((opcao) => (
                                                <div className="form-check" key={opcao.value}>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={pergunta.id}
                                                        value={opcao.value}
                                                        onChange={handleChange}
                                                        checked={respostas[pergunta.id] === opcao.value}
                                                    />
                                                    <label className="form-check-label">
                                                        {opcao.label}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}

                    <button 
                        type="submit" 
                        className="btn btn-primary btn-lg"
                        disabled={!todasRespondidas()}
                    >
                        Enviar Pesquisa
                    </button>
                </form>
            </div>
        </>
    );
}

export default PesquisaSatisfacao;