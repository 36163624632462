import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import Api from '../Auth/Api';

// Criando o contexto
const AjustesContext = createContext();

// Hook personalizado para usar o contexto
export const useAjustes = () => useContext(AjustesContext);

// Função auxiliar para converter cor hex para RGB
const hexToRgb = (hex) => {
    // Remover o # se existir
    hex = hex.replace('#', '');
    
    // Converter para RGB
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    
    return `${r}, ${g}, ${b}`;
};

// Provedor do contexto
export const AjustesProvider = ({ children }) => {
    const [ajustes, setAjustes] = useState({
        titulo: 'Bem vindo!',
        cor_primaria: '#fff',
        cor_secundaria: '#fff',
        logo: null,
        logo_white: null,
        favicon: null,
        diploma: null,
        flag: null,
        carregado: false
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Função para aplicar as cores ao CSS
    const aplicarCores = useCallback((primaria, secundaria) => {
        document.documentElement.style.setProperty('--bs-primary', primaria);
        document.documentElement.style.setProperty('--bs-primary-rgb', hexToRgb(primaria));
        document.documentElement.style.setProperty('--bs-secondary', secundaria);
        document.documentElement.style.setProperty('--bs-secondary-rgb', hexToRgb(secundaria));
    }, []);

    // Função para aplicar o favicon
    const aplicarFavicon = useCallback((faviconUrl) => {
        let link = document.querySelector("link#favicon");
        if (!link) {
            link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                link.id = 'favicon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
        }
        link.href = faviconUrl;
        
        // Também atualiza o apple-touch-icon se existir
        let appleIcon = document.querySelector("link[rel~='apple-touch-icon']");
        if (appleIcon) {
            appleIcon.href = faviconUrl;
        }
    }, []);

    // Função para carregar os ajustes da API
    const carregarAjustes = useCallback(async () => {
        try {
            setLoading(true);
            setError(null);
            
            // Usar a rota pública para obter os ajustes
            const response = await Api.get('public/ajustes');
            
            if (response.data) {
                // Definir valores padrão para campos que podem ser nulos
                const dadosAjustes = {
                    ...response.data,
                    cor_primaria: response.data.cor_primaria || '#3a5a6c',
                    cor_secundaria: response.data.cor_secundaria || '#6c8a9c',
                    carregado: true
                };
                
                setAjustes(dadosAjustes);
                
                // Aplicar as cores ao CSS da página
                aplicarCores(dadosAjustes.cor_primaria, dadosAjustes.cor_secundaria);
                
                // Aplicar o título da página
                if (dadosAjustes.titulo) {
                    document.title = `${dadosAjustes.titulo}`;
                    
                    // Atualizar a meta description
                    const metaDescription = document.querySelector('meta[name="description"]');
                    if (metaDescription) {
                        metaDescription.setAttribute('content', `${dadosAjustes.titulo} - Plataforma de treinamentos e certificações`);
                    }
                }
                
                // Aplicar o favicon
                if (dadosAjustes.favicon) {
                    aplicarFavicon(dadosAjustes.favicon);
                }
            }
            
            setLoading(false);
        } catch (error) {
            console.error("Erro ao carregar ajustes:", error);
            setError("Não foi possível carregar as configurações da plataforma");
            setLoading(false);
        }
    }, [aplicarCores, aplicarFavicon]);

    // Carregar ajustes ao montar o componente
    useEffect(() => {
        carregarAjustes();
    }, [carregarAjustes]);

    // Valor do contexto
    const value = {
        ajustes,
        loading,
        error,
        recarregarAjustes: carregarAjustes
    };

    return (
        <AjustesContext.Provider value={value}>
            {children}
        </AjustesContext.Provider>
    );
}; 