import { useMemo, useState, useEffect } from "react";
import Select from "react-select";
import TableRelatoriosAcompanhamento from "../../Components/TableRelatoriosAcompanhamento";
import Api from "../../Auth/Api";
import * as XLSX from 'xlsx';

function Acompanhamento() {
	const token = JSON.parse(localStorage.getItem("user_token"));

	const [data, setDatas] = useState([]);
	const [filters, setFilters] = useState({
		dataInicio: '',
		dataFim: '',
		treinamento: null,
		unidade: null
	});
	const [hasFiltered, setHasFiltered] = useState(false);
	const [treinamentos, setTreinamentos] = useState([]);
	const [unidades, setUnidades] = useState([]);
	const [verificarFiltro, setVerificarFiltro] = useState(false);
	const [loading, setLoading] = useState(false);

	// Carregar treinamentos e unidades disponíveis
	useEffect(() => {
		async function fetchOptions() {
			try {
				// Carregar treinamentos
				const responseTreinamentos = await Api.get(`/api/show/treinamentos`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setTreinamentos(responseTreinamentos.data.map(t => ({ value: t.nome, label: t.nome })));

				// Carregar unidades
				const responseUnidades = await Api.get(`/api/show/unidades`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setUnidades(responseUnidades.data.map(u => ({ value: u.nome, label: u.nome })));
			} catch (error) {
				console.log("Erro ao carregar opções: ", error.response.data.message);
			}
		}
		fetchOptions();
	}, [token]);

	const handleInputChange = (name, value) => {
		setFilters((prevFilters) => ({
			...prevFilters,
			[name]: value
		}));
		setVerificarFiltro(true);
	};

	const handleApplyFilters = async () => {
		setLoading(true);
		try {
			const response = await Api.get(`api/relatorio/acompanhamento`, {
				headers: {
					Authorization: `Bearer ${token}`
				},
				params: {
					dataInicio: filters.dataInicio || undefined,
					dataFim: filters.dataFim || undefined,
					treinamento: filters.treinamento ? filters.treinamento.value : undefined,
					unidade: filters.unidade ? filters.unidade.value : undefined,
				}
			});
			setDatas(response.data);
			setHasFiltered(true);
			setLoading(false);
		} catch (error) {
			console.log(error.response.data.message);
		}
	};

	// Função para exportar para Excel
	const handleExportExcel = () => {

		const dataForExcel = data.map(row => ({
			"Matricula": row.matricula,
			"Nome do Colaborador": row.nome_colaborador,
			"Unidade": row.unidade,
			"Nome do Curso": row.nome_topico,
			"Nome do Módulo": row.nome_modulo,
			"Tipo de Curso": row.tipo_curso ? row.tipo_curso.charAt(0).toUpperCase() + row.tipo_curso.slice(1) : '',
			"Status Teórico": row.status_teorica,
			"Data Teórico": row.data_ultima_aula,
			"Data Avaliação": row.data_avaliacao,
			"Nota de Avaliação": row.nota_avaliacao,
			"Data Prática": row.data_pratica || "-",
			"Data de Emissão do Certificado": row.emissao_certificado,
		}));

		// Cria uma nova planilha
		const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Relatório");

		// Gera um arquivo Excel e faz o download
		XLSX.writeFile(workbook, `relatorio_acompanhamento.xlsx`);
	};


	const columns = useMemo(
		() => [
			{
				Header: () => null,
				id: 'matricula',
				accessor: row => row.matricula,
				Cell: ({ cell: { value }, row: { original } }) => (
					<div className="d-flex">
						{original.matricula}
					</div>
				)
			},
			{
				Header: () => null,
				id: 'nome_colaborador',
				accessor: row => row.nome_colaborador,
				Cell: ({ cell: { value }, row: { original } }) => (
					<div className="d-flex">
						{original.nome_colaborador}
					</div>
				)
			},
			{
				Header: () => null,
				id: 'unidade',
				accessor: row => row.unidade,
				Cell: ({ cell: { value }, row: { original } }) => (
					<div className="d-flex">
						{original.unidade}
					</div>
				)
			},
			{
				Header: () => null,
				id: 'nome_topico',
				accessor: row => row.nome_topico,
				Cell: ({ cell: { value }, row: { original } }) => (
					<div className="d-flex">
						{original.nome_topico}
					</div>
				)
			},
			{
				Header: () => null,
				id: 'nome_modulo',
				accessor: row => row.nome_modulo,
				Cell: ({ cell: { value }, row: { original } }) => (
					<div className="d-flex">
						{original.nome_modulo}
					</div>
				)
			},
			{
				Header: () => null,
				id: 'tipo_curso',
				accessor: row => row.tipo_curso,
				Cell: ({ cell: { value }, row: { original } }) => (
					<div className="d-flex text-capitalize">
						{original.tipo_curso}
					</div>
				)
			},
			{
				Header: () => null,
				id: 'status_teorica',
				accessor: row => row.status_teorica,
				Cell: ({ cell: { value }, row: { original } }) => (
					<div className="d-flex">
						{original.status_teorica}
					</div>
				)
			},
			{
				Header: () => null,
				id: 'data_ultima_aula',
				accessor: row => row.data_ultima_aula,
				Cell: ({ cell: { value }, row: { original } }) => (
					<div className="d-flex">
						{original.data_ultima_aula}
					</div>
				)
			},
			{
				Header: () => null,
				id: 'data_avaliacao',
				accessor: row => row.data_avaliacao,
				Cell: ({ cell: { value }, row: { original } }) => (
					<div className="d-flex">
						{original.data_avaliacao}
					</div>
				)
			},
			{
				Header: () => null,
				id: 'nota_avaliacao',
				accessor: row => row.nota_avaliacao,
				Cell: ({ cell: { value }, row: { original } }) => (
					<div className="d-flex text-center justify-content-center">
						{original.nota_avaliacao}
					</div>
				)
			},
			{
				Header: () => null,
				id: 'data_pratica',
				accessor: row => row.data_pratica,
				Cell: ({ cell: { value }, row: { original } }) => (
					<div className="d-flex">
						{original.data_pratica}
					</div>
				)
			},
			{
				Header: () => null,
				id: 'emissao_certificado',
				accessor: row => row.emissao_certificado,
				Cell: ({ cell: { value }, row: { original } }) => (
					<div className="d-flex">
						{original.emissao_certificado}
					</div>
				)
			},

		],
		[]
	);

	const customStyles = {
		container: (base) => ({
			...base,
			width: '100%',
		}),
		control: (base, state) => ({
			...base,
			fontSize: "1rem",
			backgroundColor: "#ffffff",
			borderRadius: "0.25rem",
			boxShadow: state.isFocused ? "0 0 0 0.25rem #27485b73" : null,
			borderColor: "#dee2e6",
			"&:hover": {
				borderColor: "#dee2e6"
			},
		}),
		singleValue: (base) => ({
			...base,
			color: '#000',
			marginTop: '5px',
		}),
		input: (base) => ({
			...base,
			height: "46px",
			margin: '0px',
			padding: '0px !important',

		}),
		menu: (base) => ({
			...base,
			backgroundColor: '#fff',
		}),
		option: (base, state) => ({
			...base,
			color: state.isSelected ? '#3a5a6c' : null,
			backgroundColor: state.isFocused ? '#27485b73' : null,
			"&:active": {
				backgroundColor: "#27485b73"
			},
		}),
	};

	return (
		<>
			<div className="fade-in w-100 mt-3" style={{ marginBottom: '100px' }}>
				<span className="text-primary fs-1">Relatório de Acompanhamento</span>
				<p className="fs-6">Gere e consulte relatórios detalhados sobre o progresso dos usuários, aulas concluídas e certificados emitidos.</p>

				{/* Filtros */}
				<div className="filters mb-4">
					<div className="row g-3">
						<div className="col-12 col-md-6 col-lg-3">
							<label htmlFor="dataInicio">Data Início</label>
							<input
								type="date"
								name="dataInicio"
								className="form-control"
								value={filters.dataInicio}
								onChange={e => handleInputChange('dataInicio', e.target.value)}
							/>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<label htmlFor="dataFim">Data Fim</label>
							<input
								type="date"
								name="dataFim"
								className="form-control"
								value={filters.dataFim}
								onChange={e => handleInputChange('dataFim', e.target.value)}
							/>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<label htmlFor="treinamento">Treinamento</label>
							<Select
								name="treinamento"
								options={treinamentos}
								styles={customStyles}
								classNamePrefix="select"
								placeholder="Selecione um Treinamento"
								value={filters.treinamento}
								onChange={value => handleInputChange('treinamento', value)}
							/>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<label htmlFor="unidade">Unidade</label>
							<Select
								name="unidade"
								options={unidades}
								styles={customStyles}
								classNamePrefix="select"
								placeholder="Selecione uma Unidade"
								value={filters.unidade}
								onChange={value => handleInputChange('unidade', value)}
							/>
						</div>
					</div>
					<div className="d-flex gap-2 mt-3">
						<button className={`btn btn-primary ${!verificarFiltro ? 'disabled cursor-disabled' : ''}`} disabled={!verificarFiltro} onClick={handleApplyFilters}>
							Aplicar Filtros
						</button>
						<button className={`btn btn-secondary ${!hasFiltered ? 'disabled cursor-disabled' : ''}`} disabled={!hasFiltered} onClick={handleExportExcel}>
							<i className="bi bi-table"></i> | Baixa Excel
						</button>
					</div>
				</div>

				{loading && (
					<div className="d-flex justify-content-center">
						<div class="loader"></div>
					</div>
				)
				}

				{/* Tabela - só exibe após aplicar os filtros */}
				{hasFiltered && (
					<TableRelatoriosAcompanhamento titulo="Relatórios em grupo" columns={columns} data={data} />
				)}
			</div>
		</>
	);
}

export default Acompanhamento;
