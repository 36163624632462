import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Modal, Toast } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Api from '../Auth/Api';
import Table from '../Components/Table';
import { useForm } from 'react-hook-form';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import GerarPDF from '../Components/GerarPDF';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { decodeJWT } from '../Components/jwtUtils';
import { Controller } from 'react-hook-form';

function ListaAlunos() {

    const [showModalNotasInd, setShowModalNotasInd] = useState(false);
    const [showModalEnviarProva, setShowModalEnviarProva] = useState(false);
    const [showModalNota, setShowModalNota] = useState({ show: false });
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [usuarioSelecionado, setUsuarioSelecionado] = useState({});
    const [progressoAvaliacao, setProgressoAvaliacao] = useState('');
    const [shouldGeneratePDF, setShouldGeneratePDF] = useState({ show: false });
    const [progressoAvaliacaoUserID, setProgressoAvaliacaoUserID] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [selectedTipo, setSelectedTipo] = useState('favoritos');
    
    // Form para Notas
    const { 
        register: registerNota, 
        handleSubmit: handleSubmitNota, 
        formState: { errors: errorsNota }
    } = useForm();

    // Form para Prática
    const { 
        register: registerPratica, 
        handleSubmit: handleSubmitPratica, 
        formState: { errors: errorsPratica }, 
        control: controlPratica,
        reset: resetPratica 
    } = useForm({
        defaultValues: {
            data_treinamento: '',
            local_treinamento: '',
            gestor: '',
            lista_presenca: null,
            fotos: null
        }
    });

    const [checkedStates, setCheckedStates] = useState({});
    const [loading, setLoading] = useState(false);
    const [showModalPratica, setShowModalPratica] = useState(false);
    const [showModalVisualizarPratica, setShowModalVisualizarPratica] = useState(false);
    const [praticaAtual, setPraticaAtual] = useState(null);
    const [praticasRegistradas, setPraticasRegistradas] = useState({});
    const [listaPresencaSelected, setListaPresencaSelected] = useState(false);
    const [fotosSelected, setFotosSelected] = useState(false);
    const [showModalConfirmacao, setShowModalConfirmacao] = useState(false);
    const [usuarioConfirmacao, setUsuarioConfirmacao] = useState(null);
    const [novoStatusCertificado, setNovoStatusCertificado] = useState(false);
    const [showModalEditarPratica, setShowModalEditarPratica] = useState(false);
    const [showModalConfirmacaoDeletar, setShowModalConfirmacaoDeletar] = useState(false);

    const navigate = useNavigate();
    const token = JSON.parse(localStorage.getItem("user_token"));
    const nivel = decodeJWT(token).nivel;
    const { id } = useParams();

    const handleSwitchChange = useCallback(async (idUsuario, novoStatus) => {
        setUsuarioConfirmacao(idUsuario);
        setNovoStatusCertificado(novoStatus);
        setShowModalConfirmacao(true);
    }, []);

    const confirmarMudancaCertificado = useCallback(async () => {
        try {
            const response = await Api.post(`api/permissao-certificado/${id}/${usuarioConfirmacao}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response && response.data) {
                setCheckedStates(prevState => ({
                    ...prevState,
                    [usuarioConfirmacao]: response.data.permissao === 1
                }));
                setToast({ 
                    header: 'Sucesso!', 
                    body: `Certificado ${novoStatusCertificado ? 'liberado' : 'bloqueado'} com sucesso!` 
                });
                setShowToast(true);
            }
        } catch (error) {
            setToast({ 
                header: 'Ops!', 
                body: error.response?.data?.message || 'Erro ao alterar permissão do certificado' 
            });
            setShowToast(true);
        }
        setShowModalConfirmacao(false);
    }, [id, token, usuarioConfirmacao, novoStatusCertificado]);

    const onSubmitNota = async (data) => {

        data['tipo'] = showModalNota.tipo;

        try {
            const response = await Api.post(`/api/editar-nota-avaliacao/${showModalNota.id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message })

        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao editar a nota:", error);
        }

        setShowToast(true);
        setShowModalNota({ show: false })
        setShowModalNotasInd(true)
    };

    const onSubmitPratica = async (data) => {
        const formData = new FormData();
        formData.append('data_treinamento', data.data_treinamento);
        formData.append('local_treinamento', data.local_treinamento);
        formData.append('gestor', data.gestor);
        
        if (data.lista_presenca instanceof FileList) {
            formData.append('lista_presenca', data.lista_presenca[0]);
        }
        
        if (data.fotos instanceof FileList) {
            for (let i = 0; i < data.fotos.length; i++) {
                formData.append(`fotos[${i}]`, data.fotos[i]);
            }
        }

        try {
            const response = await Api.post(`/api/registrar-pratica/${id}/${usuarioSelecionado.id}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message });
            setShowModalPratica(false);
            resetPratica();
            setListaPresencaSelected(false);
            setFotosSelected(false);
        } catch (error) {
            setToast({ header: 'Ops!', body: error.response?.data?.message || 'Erro ao registrar prática' });
        }
        setShowToast(true);
    };

    const [dataInfoCurso, setDataInfoCurso] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/info-curso/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataInfoCurso(response.data);
            } catch (error) {
                console.error(error.response.data.message)
                // navigate("/");
            }
        }

        fetchData();
    }, [token, id, navigate]);

    const [alunos, setAlunos] = useState([]);
    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-usuarios-por-curso/${id}/${selectedTipo}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                // Aqui você inicializa o estado checkedStates
                const initialStates = {};
                response.data.forEach(usuario => {
                    initialStates[usuario.id] = usuario.certificado === 1;
                });
                setCheckedStates(initialStates);
                setLoading(false);
                setAlunos(response.data);
            } catch (error) {
                setLoading(false);
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token, id, navigate, toast, selectedTipo]);

    const filterSelect = [
        { value: 'todos', label: 'Todos' },
        { value: 'andamento', label: 'Em andamento' },
        { value: 'finalizados', label: 'Finalizados' }
    ]

    const filterTipo = [
        { value: 'favoritos', label: 'Favoritos' },
        { value: 'todos', label: 'Todos' }
    ]

    // Criando os filtros a partir da api do Alunos
    let filterAlunos = alunos;
    switch (selectedFilter) {
        case "todos":
            filterAlunos = alunos;
            break;
        case "andamento":
            filterAlunos = alunos.filter(value => parseInt(value.aulas_finalizadas) > 0 && parseInt(value.porcentagem_concluida) !== 100);
            break;
        case "finalizados":
            filterAlunos = alunos.filter(value => parseInt(value.porcentagem_concluida) === 100);
            break;
        default:
            break;
    }

    useEffect(() => {
        setProgressoAvaliacao('') // Limpa antes de recarregar
        if (shouldGeneratePDF.show && progressoAvaliacao && usuarioSelecionado.id === progressoAvaliacaoUserID) {
     
            const doc = <GerarPDF data={progressoAvaliacao} titulo={shouldGeneratePDF.nome} tipo='avaliacao' />;
            pdf(doc).toBlob().then(blob => {
                saveAs(blob, `avaliacao_${usuarioSelecionado.nome_completo}_${shouldGeneratePDF.nome}.pdf`);
                setShouldGeneratePDF({ show: false });  // Reset após gerar o PDF
            });
        }
    }, [shouldGeneratePDF, progressoAvaliacao, usuarioSelecionado.nome_completo, usuarioSelecionado.id, progressoAvaliacaoUserID]);

    //Listar avaliacao e respostas do usuario
    useEffect(() => {
        async function fetchData(idUsuario, tipo) {
            try {
                const response = await Api.get(`api/${tipo === 'EX' ? 'info-progresso-avaliacao-extra-usuario' : 'info-progresso-avaliacao-usuario'}/${shouldGeneratePDF.id}/${idUsuario}`, { headers: { Authorization: `Bearer ${token}` } });
                setProgressoAvaliacao(response.data);
                setProgressoAvaliacaoUserID(idUsuario); // Defina o ID do usuário para o qual os dados se aplicam
   
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        if (usuarioSelecionado.id && shouldGeneratePDF.id) {
            fetchData(usuarioSelecionado.id, shouldGeneratePDF.tipo);
        }
    }, [usuarioSelecionado.id, id, token, shouldGeneratePDF]);

    const [dataModulos, setDataModulos] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-progresso-avaliacao-curso/${id}/${usuarioSelecionado.id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataModulos(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        usuarioSelecionado.id && fetchData();
    }, [id, token, usuarioSelecionado.id, showModalNotasInd])

    const verificarPraticaExistente = useCallback(async (idCurso, idUsuario) => {
        try {
            const response = await Api.get(`/api/cursos/${idCurso}/praticas`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            const praticaUsuario = response.data.find(pratica => pratica.idUsuario === idUsuario);
            
            if (praticaUsuario) {
                // Se encontrou uma prática, busca os detalhes
                const detalhes = await Api.get(`/api/praticas/${praticaUsuario.id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setPraticaAtual(detalhes.data);
                setShowModalVisualizarPratica(true);
            } else {
                // Se não encontrou, abre o modal de registro
                setShowModalPratica(true);
            }
        } catch (error) {
            setToast({ 
                header: 'Ops!', 
                body: error.response?.data?.message || 'Erro ao verificar prática existente' 
            });
            setShowToast(true);
        }
    }, [token, setPraticaAtual, setShowModalVisualizarPratica, setShowModalPratica, setToast, setShowToast]);

    useEffect(() => {
        async function fetchPraticas() {
            try {
                const response = await Api.get(`/api/cursos/${id}/praticas`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                
                const praticasMap = {};
                response.data.forEach(pratica => {
                    praticasMap[pratica.idUsuario] = true;
                });
                setPraticasRegistradas(praticasMap);
            } catch (error) {
                console.error('Erro ao buscar práticas:', error);
            }
        }
        
        if (id) {
            fetchPraticas();
        }
    }, [id, token, showModalPratica, showModalVisualizarPratica]);

    const columns = useMemo(
        () => [
            {
                Header: () => null,
                id: 'foto',
                Cell: () => (
                    <div className="d-flex align-items-center">
                        <div className="picture text-white bg-grey" style={{ fontSize: '22px', height: '42px', marginRight: '0px', width: '42px' }}>
                            <i className="bi bi-person" />
                        </div>
                    </div>
                )
            },
            {
                Header: () => null,
                accessor: 'nome_completo',
                Cell: ({ cell: { value }, row: { original } }) => (
                    <>
                        <div className=''>{value}</div>
                        <div className='d-flex align-items-center gap-2'>
                            <div className='badge bg-primary'>{original.codigo}</div>
                            <Tippy content={original.email}>
                                <i className="bi bi-envelope-fill text-primary cursor-pointer"></i>
                            </Tippy>
                        </div>
                    </>
                )
            },
            {
                Header: () => null,
                accessor: 'status',
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className="text-center">
                        <div className={`badge bg-${value === 'Pendente' ? 'dark' : value === 'Aprovado' ? 'success' : 'primary'}`}>{value}</div>
                    </div>
                )
            },
            {
                Header: () => null,
                accessor: 'email',
                visible: false
            },
            {
                Header: () => null,
                accessor: 'porcentagem_concluida',
                Cell: ({ cell: { value }, row: { original } }) => {
                    let texto = '';
                    let color = 'primary';
                    let width = 0;
                    let textColor = 'text-light';

                    if (parseInt(value) === 100) {
                        texto = 'Concluído';
                        color = 'success';
                        width = 100;
                    }
                    if (parseInt(value) < 100) {
                        texto = `${value}%`;
                        width = value;
                    }
                    if (parseInt(original.aulas_finalizadas) > 0 && parseInt(value) !== 100) {
                        texto = `Em andamento`;
                        width = 100;
                        color = 'none';
                        textColor = 'text-dark';
                    }

                    return (
                        <div className="progress">
                            <div className={`progress-bar progress-bar-striped progress-bar-animated bg-${color} ${textColor}`} style={{ width: width + '%' }}>
                                {texto}
                            </div>
                        </div>
                    )
                }
            },
            {
                Header: () => null,
                id: 'pratica',
                Cell: ({ row: { original } }) => (
                    dataInfoCurso[0].tipo === 'misto' && (
                        <div className="text-center">
                            <Tippy content={
                                praticasRegistradas[original.id] 
                                    ? "Visualizar Prática"
                                    : nivel !== "Administrador"
                                        ? "Sem permissão"
                                        : "Registrar Prática"
                            }>
                                <button
                                    onClick={() => {
                                        if (nivel !== "Administrador" && !praticasRegistradas[original.id]) return;
                                        setUsuarioSelecionado(original);
                                        verificarPraticaExistente(id, original.id);
                                    }}
                                    className={`btn btn-outline-${praticasRegistradas[original.id] ? 'success' : 'primary'} text-${praticasRegistradas[original.id] ? 'success' : 'primary'} rounded px-2 ${nivel !== "Administrador" && !praticasRegistradas[original.id] ? 'opacity-50 cursor-not-allowed' : ''}`}
                                >
                                    <i className={`bi bi-${praticasRegistradas[original.id] ? 'check-circle' : 'person-workspace'}`}></i>
                                </button>
                            </Tippy>
                        </div>
                    )
                )
            },
            {
                Header: () => null,
                id: 'notas',
                accessor: row => row.id,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div>
                        <Tippy content={original.status === 'Pendente' ? "Notas não disponíveis" : "Conferir notas"}>
                            <button 
                                className={`btn btn-outline-primary justify-content-center align-items-center rounded px-2 me-2 ${original.status === 'Pendente' ? 'cursor-not-allowed opacity-50' : ''}`}
                                onClick={() => {
                                    if (original.status === 'Pendente') return;
                                    setShowModalNotasInd(true)
                                    setUsuarioSelecionado({ id: original.id, nome_completo: original.nome_completo });
                                }}>
                                <i className="bi bi-file-text" />
                            </button>
                        </Tippy>
                    </div>
                )
            },
            {
                Header: () => null,
                id: 'certificado',
                accessor: row => row.id,
                Cell: ({ cell: { value }, row: { original } }) => {
                    const isDisabled = !(original.status === 'Aprovado' && 
                        (dataInfoCurso[0].tipo !== 'misto' || praticasRegistradas[original.id]) && 
                        nivel === "Administrador");
                    return (
                        <div className='d-flex align-items-center justify-content-center'>
                            <div className="form-check form-switch">
                                <Tippy content={
                                    original.status !== 'Aprovado' 
                                        ? "Usuário precisa estar aprovado" 
                                        : dataInfoCurso[0].tipo === 'misto' && !praticasRegistradas[original.id] 
                                            ? "Usuário precisa concluir a prática" 
                                            : nivel !== "Administrador" 
                                                ? "Apenas administradores podem alterar"
                                                : "Permitir certificado"
                                }>
                                    {isDisabled ? (
                                        <div className="cursor-not-allowed text-muted" style={{ marginLeft: "-2em" }}>
                                            <i className="bi bi-lock-fill fs-5"></i>
                                        </div>
                                    ) : (
                                        <input
                                            className="form-check-input cursor-pointer"
                                            type="checkbox"
                                            role="switch"
                                            checked={checkedStates[original.id] || false}
                                            onChange={() => handleSwitchChange(original.id, !checkedStates[original.id])}
                                        />
                                    )}
                                </Tippy>
                            </div>
                        </div>
                    )
                }
            },
            {
                Header: 'Codigo',
                accessor: 'codigo',
                visible: false  // Esta coluna será filtrada, mas não exibida
            }
        ],
        [checkedStates, handleSwitchChange, nivel, dataInfoCurso, praticasRegistradas, id, verificarPraticaExistente]
    );

    const LoadingScreen = () => {
        return (
            <div className="d-flex align-items-center justify-content-center p-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando...</span>
                </div>
            </div>
        )
    }

    const { 
        register: registerEditarPratica, 
        handleSubmit: handleSubmitEditarPratica, 
        formState: { errors: errorsEditarPratica }, 
        control: controlEditarPratica,
        reset: resetEditarPratica 
    } = useForm();

    const handleEditarPratica = () => {
        resetEditarPratica({
            data_treinamento: praticaAtual.data_treinamento,
            local_treinamento: praticaAtual.local_treinamento,
            gestor: praticaAtual.gestor
        });
        setShowModalVisualizarPratica(false);
        setShowModalEditarPratica(true);
    };

    const onSubmitEditarPratica = async (data) => {
        const formData = new FormData();
        formData.append('data_treinamento', data.data_treinamento);
        formData.append('local_treinamento', data.local_treinamento);
        formData.append('gestor', data.gestor);
        
        if (data.lista_presenca instanceof FileList) {
            formData.append('lista_presenca', data.lista_presenca[0]);
        }
        
        if (data.fotos instanceof FileList) {
            for (let i = 0; i < data.fotos.length; i++) {
                formData.append(`fotos[${i}]`, data.fotos[i]);
            }
        }

        try {
            const response = await Api.post(`/api/editar-praticas/${praticaAtual.id}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message });
            setShowModalEditarPratica(false);
            verificarPraticaExistente(id, usuarioSelecionado.id);
        } catch (error) {
            setToast({ header: 'Ops!', body: error.response?.data?.message || 'Erro ao editar prática' });
        }
        setShowToast(true);
    };

    const handleDeletarPratica = async () => {
        try {
            const response = await Api.delete(`/api/praticas/${praticaAtual.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message });
            setShowModalConfirmacaoDeletar(false);
            setShowModalVisualizarPratica(false);
            // Atualiza a lista de práticas
            const praticasResponse = await Api.get(`/api/cursos/${id}/praticas`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const praticasMap = {};
            praticasResponse.data.forEach(pratica => {
                praticasMap[pratica.idUsuario] = true;
            });
            setPraticasRegistradas(praticasMap);
        } catch (error) {
            setToast({ header: 'Ops!', body: error.response?.data?.message || 'Erro ao deletar prática' });
        }
        setShowToast(true);
    };

    return (
        <>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide style={{ position: 'fixed', left: '95%', bottom: '10px', transform: 'translateX(-95%)', zIndex: 9999 }}>
                <Toast.Header>
                    <strong className="mr-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>

            <Link onClick={() => navigate(-1)} className="btn text-primary link border-0 fs-6 mb-4"><i className="bi bi-chevron-left"></i>Voltar</Link>

            {dataInfoCurso[0] &&
                <div className="fade-in w-100">
                    <span className="text-primary fs-2">Análise geral dos alunos</span>
                    <p className="fs-6">
                        Lista de alunos disponíveis para o curso <span className='fw-bold'>{dataInfoCurso[0].nome}</span>
                    </p>

                    <div className={loading ? "" : "d-none"}><LoadingScreen /></div>
                    <div className={loading ? "d-none" : ""}>
                        <Table columns={columns} data={filterAlunos} filterSelect={filterSelect} filterTipo={filterTipo} setSelectedTipo={setSelectedTipo} setSelectedFilter={setSelectedFilter} titulo={dataInfoCurso[0].nome} />
                    </div>

                </div>
            }

            <Modal size="lg" show={showModalNota.show} onHide={() => { setShowModalNota({ show: false }); setShowModalNotasInd(true) }}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Trocar Nota <span className='text-dark fs-5'>({usuarioSelecionado.nome_completo})</span></span>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmitNota(onSubmitNota)}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="body">
                                    <div className="row">
                                        <div className='label text-start fs-6 py-2 px-3'>Qual a nota para <span className='fw-bold'>{showModalNota.nome}</span></div>
                                        <div className="col">
                                            <input type="number" {...registerNota('nota', { required: true })} className="form-control" placeholder="Digite a nota" />
                                            <input type="hidden" {...registerNota('idUsuario', { required: true })} />
                                        </div>
                                        <div className="col">
                                            <input type="submit" className="btn btn-primary text-white w-100" value="Trocar nota" />
                                        </div>
                                        {errorsNota.nota && <span className='text-danger fs-7'>Nota é obrigatório.</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal size="xl" show={showModalNotasInd} onHide={() => { setShowModalNotasInd(false); setUsuarioSelecionado({}); setDataModulos([]) }}>
                <Modal.Body>
                    <div className='row col-12'>
                        <p className="fw-semibold fs-2 text-primary text-center" style={{ marginBottom: '-5px' }}>{usuarioSelecionado.nome_completo}</p>
                        <p className="fw-semibold fs-4 text-dark text-center text-uppercase">{dataInfoCurso[0] && dataInfoCurso[0].nome}</p>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="body">
                                <div className="table-full-width mt-3 mb-3">
                                    <div className="table-responsive">
                                        <table className="table align-middle mt-1">
                                            <thead>
                                                <tr className='border border-white'>
                                                    <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Nome</th>
                                                    <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted text-center'>Tipo</th>
                                                    <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted text-center'>Nota</th>
                                                    <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted text-center'>Nota Final</th>
                                                    <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted text-center'>Avaliação</th>
                                                    <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted text-center'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    dataModulos && dataModulos.map((value, index) => {
                                                        const rowClass = index % 2 === 0 ? 'even-row' : 'odd-row';
                                                        return (
                                                            (value.progresso_avaliacao || value.progresso_avaliacao_extras) &&
                                                            <>
                                                                {value.progresso_avaliacao &&
                                                                    <tr key={`pa-${value.id}`}>
                                                                        <td className={`${rowClass} fw-semibold`}>{value.nome}</td>
                                                                        <td className={`${rowClass} text-center`}>{value.progresso_avaliacao.tipo}</td>
                                                                        <td className={`${rowClass} text-center fw-bold ${value.progresso_avaliacao.nota < 6 ? 'text-primary' : 'text-dark'}`}>{value.progresso_avaliacao.nota || 'N/A'}</td>
                                                                        <td className={`${rowClass} text-center fw-regular`}><span className={`badge bg-${value.nota_final >= 6 ? 'success' : 'primary'}`}>{value.nota_final && value.nota_final}</span></td>
                                                                        <td className={`${rowClass} text-center`}>
                                                                            <span className='text-primary cursor-pointer' onClick={() => { setShouldGeneratePDF({ show: true, id: value.id, nome: value.nome, tipo: value.progresso_avaliacao.tipo }) }}>Visualizar</span>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    value.progresso_avaliacao_extras && value.progresso_avaliacao_extras.map((value, index) => {
                                                                        return (
                                                                            <tr key={`pae-${index}`}>
                                                                                <td className={rowClass}><div>{value.nomeAvaliacaoExtra}</div><span className='text-muted fw-bold fs-7'>{value.nomeModulo}</span></td>
                                                                                <td className={`${rowClass} text-center fw-regular`}>{value.tipo}</td>
                                                                                <td className={`${rowClass} text-wrap text-center fw-bold ${value.nota < 6 ? 'text-primary' : 'text-dark'}`}>{value.nota || 'N/A'}</td>
                                                                                <td className={`${rowClass} text-center fw-regular`}>-</td>
                                                                                <td className={`${rowClass} text-center`}>
                                                                                    <span className='text-primary cursor-pointer' onClick={() => { setShouldGeneratePDF({ show: true, id: value.idAvaliacao, nome: value.nomeAvaliacaoExtra, tipo: value.tipo }) }}>Visualizar</span>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                                <tr>
                                                                    <td colSpan={5} style={{ padding: 1 }} className='bg-dark'></td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={showModalEnviarProva} onHide={() => setShowModalEnviarProva(false)}>
                <Modal.Header closeButton>
                    <div className='row'>
                        <span className="fw-medium fs-4 text-primary">Enviar prova para...</span>
                        <span>Lorem ipsum dolor sit amet. Qui reprehenderit esse quo similique laudantium ex modi </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <section className="w-100 mt-5">
                        <div className="d-flex flex-column">
                            <div className="row d-flex align-items-center justify-content-between">
                                <div className="col-md-8">
                                    <div className="inner-addon right-addon">
                                        <i className="d-flex bi bi-search" />
                                        <input
                                            type="search"
                                            placeholder="Pesquise o aluno desejado...."
                                            className="form-control py-2"
                                        />
                                    </div>
                                </div>
                                <div className="d-flex mt-2 mt-md-0 align-items-center justify-content-end col-md-4">
                                    <select className="form-select py-2">
                                        <option disabled>Ordenação</option>
                                        <option value="1">Curso</option>
                                        <option value="2">Módulos</option>
                                        <option value="3">Em andamento</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="body">
                                <div className="table-full-width mt-3 mb-3">
                                    <div className="table-responsive">
                                        <table className="table align-middle mt-1">
                                            <tbody>
                                                <tr className='text-nowrap border-white'>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="picture text-white bg-grey" style={{ cursor: 'pointer', fontSize: '22px', height: '42px', marginRight: '0px', width: '42px' }}>
                                                                <i className="bi bi-person" />
                                                            </div>
                                                        </div></td>
                                                    <td>Lorem Ipsum</td>
                                                    <td className='text-center fw-bold'>(99) 99999-9999</td>
                                                    <td className='text-center'>email@email.com</td>
                                                    <td className='text-center fw-bold'>
                                                        <input className="form-check-input border-primary" type="checkbox" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mb-4">
                        <button type="submit" className="btn btn-primary text-white px-3 py-2">Enviar</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={showModalPratica} onHide={() => setShowModalPratica(false)}>
                <form onSubmit={handleSubmitPratica(onSubmitPratica)}>
                    <Modal.Header closeButton>
                        <span className="fw-semibold fs-4 text-primary">Registrar Prática - {usuarioSelecionado.nome_completo}</span>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Data do Treinamento Prático</label>
                                <input
                                    type="datetime-local"
                                    className={`form-control ${errorsPratica.data_treinamento ? 'is-invalid' : ''}`}
                                    {...registerPratica('data_treinamento', { 
                                        required: true
                                    })}
                                />
                                {errorsPratica.data_treinamento && <div className="invalid-feedback">Campo obrigatório</div>}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Local do Treinamento</label>
                                <input
                                    type="text"
                                    className={`form-control ${errorsPratica.local_treinamento ? 'is-invalid' : ''}`}
                                    {...registerPratica('local_treinamento', { 
                                        required: true
                                    })}
                                />
                                {errorsPratica.local_treinamento && <div className="invalid-feedback">Campo obrigatório</div>}
                            </div>
                            <div className="col-md-12 mb-3">
                                <label className="form-label">Gestor</label>
                                <input
                                    type="text"
                                    className={`form-control ${errorsPratica.gestor ? 'is-invalid' : ''}`}
                                    {...registerPratica('gestor', { 
                                        required: true
                                    })}
                                />
                                {errorsPratica.gestor && <div className="invalid-feedback">Campo obrigatório</div>}
                            </div>
                            <div className="col-md-12 mb-3">
                                <label className="form-label">Lista de Presença (PDF ou Imagem)</label>
                                <label htmlFor="lista_presenca" className={`form-control label-input ${listaPresencaSelected ? 'border-success' : 'border-primary'} cursor-pointer`}>
                                    <i className="bi bi-file-earmark-fill me-2"></i> 
                                    {listaPresencaSelected ? 'Arquivo selecionado' : 'Selecionar arquivo'}
                                </label>
                                <Controller
                                    control={controlPratica}
                                    name="lista_presenca"
                                    rules={{ 
                                        required: true
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <input
                                            id="lista_presenca"
                                            type="file"
                                            style={{ display: 'none' }}
                                            accept=".pdf,image/*"
                                            onBlur={onBlur}
                                            ref={ref}
                                            onChange={(e) => {
                                                const files = e.target.files;
                                                onChange(files);
                                                setListaPresencaSelected(files && files.length > 0);
                                            }}
                                        />
                                    )}
                                />
                                {errorsPratica.lista_presenca && <div className="invalid-feedback d-block">Campo obrigatório</div>}
                            </div>
                            <div className="col-md-12 mb-3">
                                <label className="form-label">Fotos do Treinamento</label>
                                <label htmlFor="fotos" className={`form-control label-input ${fotosSelected ? 'border-success' : 'border-primary'} cursor-pointer`}>
                                    <i className="bi bi-images me-2"></i>
                                    {fotosSelected ? 'Arquivos selecionados' : 'Selecionar fotos'}
                                </label>
                                <Controller
                                    control={controlPratica}
                                    name="fotos"
                                    rules={{ 
                                        required: true
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <input
                                            id="fotos"
                                            type="file"
                                            style={{ display: 'none' }}
                                            multiple
                                            accept="image/*"
                                            onBlur={onBlur}
                                            ref={ref}
                                            onChange={(e) => {
                                                const files = e.target.files;
                                                onChange(files);
                                                setFotosSelected(files && files.length > 0);
                                            }}
                                        />
                                    )}
                                />
                                {errorsPratica.fotos && <div className="invalid-feedback d-block">Campo obrigatório</div>}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-outline-primary" onClick={() => setShowModalPratica(false)}>
                            Cancelar
                        </button>
                        <button type="submit" className="btn btn-primary text-white">
                            Salvar
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal size="lg" show={showModalVisualizarPratica} onHide={() => setShowModalVisualizarPratica(false)}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">
                        Prática - {usuarioSelecionado.nome_completo}
                        {nivel === "Administrador" && (
                            <div className="mt-2">
                                <button className="btn btn-sm btn-outline-primary me-2" onClick={handleEditarPratica}>
                                    <i className="bi bi-pencil me-1"></i>
                                    Editar
                                </button>
                                <button className="btn btn-sm btn-outline-danger" onClick={() => {
                                    setShowModalVisualizarPratica(false);
                                    setShowModalConfirmacaoDeletar(true);
                                }}>
                                    <i className="bi bi-trash me-1"></i>
                                    Excluir
                                </button>
                            </div>
                        )}
                    </span>
                </Modal.Header>
                <Modal.Body>
                    {praticaAtual && (
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="form-label fw-bold">Data do Treinamento</label>
                                <p>{new Date(praticaAtual.data_treinamento).toLocaleString()}</p>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label fw-bold">Local do Treinamento</label>
                                <p>{praticaAtual.local_treinamento}</p>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label className="form-label fw-bold">Gestor</label>
                                <p>{praticaAtual.gestor}</p>
                            </div>
                            <div className="col-md-12 mb-4">
                                <label className="form-label fw-bold">Lista de Presença</label>
                                <div>
                                    <a 
                                        href={`${process.env.REACT_APP_API_URL}/listapresenca/${praticaAtual.lista_presenca}`} 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        className="btn btn-sm btn-outline-primary"
                                    >
                                        <i className="bi bi-file-earmark-text me-1"></i>
                                        Visualizar Lista de Presença
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <label className="form-label fw-bold">Fotos do Treinamento</label>
                                <div className="row g-3">
                                    {praticaAtual.fotos?.map((foto, index) => (
                                        <div key={index} className="col-md-4">
                                            <a 
                                                href={`${process.env.REACT_APP_API_URL}/imagens/${foto.nome_arquivo}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="d-block"
                                            >
                                                <img 
                                                    src={`${process.env.REACT_APP_API_URL}/imagens/${foto.nome_arquivo}`}
                                                    alt={`Foto ${index + 1}`}
                                                    className="img-fluid rounded"
                                                    style={{ height: '150px', width: 'auto', objectFit: 'cover' }}
                                                />
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline-primary" onClick={() => setShowModalVisualizarPratica(false)}>
                        Fechar
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalConfirmacao} onHide={() => setShowModalConfirmacao(false)} size="lg" dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Confirmação</span>
                </Modal.Header>
                <Modal.Body>
                    <p>Você tem certeza que deseja {novoStatusCertificado ? 'liberar' : 'bloquear'} o certificado para este aluno?</p>
                    <p className="text-muted">Esta ação {novoStatusCertificado ? 'permitirá' : 'impedirá'} que o aluno tenha acesso ao certificado do curso.</p>
                </Modal.Body>
                <Modal.Footer>
                    <button 
                        type="button" 
                        className="btn btn-outline-primary" 
                        onClick={() => setShowModalConfirmacao(false)}
                    >
                        Cancelar
                    </button>
                    <button 
                        type="button" 
                        className="btn btn-primary text-white" 
                        onClick={confirmarMudancaCertificado}
                    >
                        Confirmar
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={showModalEditarPratica} onHide={() => setShowModalEditarPratica(false)}>
                <form onSubmit={handleSubmitEditarPratica(onSubmitEditarPratica)}>
                    <Modal.Header closeButton>
                        <span className="fw-semibold fs-4 text-primary">Editar Prática - {usuarioSelecionado.nome_completo}</span>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Data do Treinamento Prático</label>
                                <input
                                    type="datetime-local"
                                    className={`form-control ${errorsEditarPratica.data_treinamento ? 'is-invalid' : ''}`}
                                    {...registerEditarPratica('data_treinamento', { 
                                        required: true
                                    })}
                                />
                                {errorsEditarPratica.data_treinamento && <div className="invalid-feedback">Campo obrigatório</div>}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Local do Treinamento</label>
                                <input
                                    type="text"
                                    className={`form-control ${errorsEditarPratica.local_treinamento ? 'is-invalid' : ''}`}
                                    {...registerEditarPratica('local_treinamento', { 
                                        required: true
                                    })}
                                />
                                {errorsEditarPratica.local_treinamento && <div className="invalid-feedback">Campo obrigatório</div>}
                            </div>
                            <div className="col-md-12 mb-3">
                                <label className="form-label">Gestor</label>
                                <input
                                    type="text"
                                    className={`form-control ${errorsEditarPratica.gestor ? 'is-invalid' : ''}`}
                                    {...registerEditarPratica('gestor', { 
                                        required: true
                                    })}
                                />
                                {errorsEditarPratica.gestor && <div className="invalid-feedback">Campo obrigatório</div>}
                            </div>
                            <div className="col-md-12 mb-3">
                                <label className="form-label">Nova Lista de Presença (opcional)</label>
                                <label htmlFor="lista_presenca_edit" className={`form-control label-input border-primary cursor-pointer`}>
                                    <i className="bi bi-file-earmark-fill me-2"></i> 
                                    Selecionar novo arquivo
                                </label>
                                <Controller
                                    control={controlEditarPratica}
                                    name="lista_presenca"
                                    render={({ field: { onChange, onBlur, ref } }) => (
                                        <input
                                            id="lista_presenca_edit"
                                            type="file"
                                            style={{ display: 'none' }}
                                            accept=".pdf,image/*"
                                            onBlur={onBlur}
                                            ref={ref}
                                            onChange={(e) => {
                                                const files = e.target.files;
                                                onChange(files);
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-md-12 mb-3">
                                <label className="form-label">Novas Fotos do Treinamento (opcional)</label>
                                <label htmlFor="fotos_edit" className={`form-control label-input border-primary cursor-pointer`}>
                                    <i className="bi bi-images me-2"></i>
                                    Selecionar novas fotos
                                </label>
                                <Controller
                                    control={controlEditarPratica}
                                    name="fotos"
                                    render={({ field: { onChange, onBlur, ref } }) => (
                                        <input
                                            id="fotos_edit"
                                            type="file"
                                            style={{ display: 'none' }}
                                            multiple
                                            accept="image/*"
                                            onBlur={onBlur}
                                            ref={ref}
                                            onChange={(e) => {
                                                const files = e.target.files;
                                                onChange(files);
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-outline-primary" onClick={() => {
                            setShowModalEditarPratica(false);
                            setShowModalVisualizarPratica(true);
                        }}>
                            Cancelar
                        </button>
                        <button type="submit" className="btn btn-primary text-white">
                            Salvar
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showModalConfirmacaoDeletar} onHide={() => setShowModalConfirmacaoDeletar(false)} size="lg">
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Confirmação</span>
                </Modal.Header>
                <Modal.Body>
                    <p>Você tem certeza que deseja excluir esta prática?</p>
                    <p className="text-muted">Esta ação não poderá ser desfeita e todos os arquivos associados serão removidos.</p>
                </Modal.Body>
                <Modal.Footer>
                    <button 
                        type="button" 
                        className="btn btn-outline-primary" 
                        onClick={() => {
                            setShowModalConfirmacaoDeletar(false);
                            setShowModalVisualizarPratica(true);
                        }}
                    >
                        Cancelar
                    </button>
                    <button 
                        type="button" 
                        className="btn btn-danger" 
                        onClick={handleDeletarPratica}
                    >
                        Excluir
                    </button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default ListaAlunos;