import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Api from '../Auth/Api';
import { Controller, useForm } from 'react-hook-form';
import { Modal, Toast } from 'react-bootstrap';
import Select from 'react-select';
import { decodeJWT, formatDate } from '../Components/jwtUtils';
import TextareaAutosize from "react-textarea-autosize";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

function MeusCursos() {

    const token = JSON.parse(localStorage.getItem("user_token"));
    const nivel = decodeJWT(token).nivel;
    const [showModalEditarCurso, setShowModalEditarCurso] = useState(false);
    const [showModalDeletarCurso, setShowModalDeletarCurso] = useState(false);
    const [showModalCertificado, setShowModalCertificado] = useState(false);
    const [cursoInfo, setCursoInfo] = useState([]);
    const [cursoEditarFile, setCursoEditarFile] = useState(false);
    const [cursoEditarCertificado, setCursoEditarCertificado] = useState(false);
    const [uploadCursoProgress, setUploadCursoProgress] = useState(0);
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [thumbEdit, setThumbEdit] = useState(false);
    const [certificadoEdit, setCertificadoEdit] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [previewUrlCertificado, setPreviewUrlCertificado] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [order, setOrder] = useState("");
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [showButtonCertificado, setShowButtonCertificado] = useState(false);
    const [loading, setLoading] = useState(false);
    const isMobile = window.matchMedia('(max-width: 767px)').matches;
    const [viewMode, setViewMode] = useState(() => {
        // Definir 'list' como padrão para dispositivos móveis, caso contrário usar a preferência do usuário
        if (isMobile) {
            return 'list';
        }
        return localStorage.getItem('viewMode') || 'grid';
    });

    // Estilos para transição suave entre modos de visualização
    const transitionStyle = {
        transition: 'all 0.3s ease-in-out',
        animation: 'fadeIn 0.3s'
    };

    // Estilo para o container da transição
    const containerTransitionStyle = {
        transition: 'all 0.3s ease-in-out',
        position: 'relative'
    };

    // Estilo personalizado para a imagem no modo lista
    const listImageStyle = {
        height: '180px',
        objectFit: 'cover',
        width: '100%',
        borderTopLeftRadius: 'calc(0.375rem - 1px)',
        borderTopRightRadius: 'calc(0.375rem - 1px)',
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0
    };

    // Aplicar estilos diferentes em telas médias e grandes
    const mediaQueryStyle = window.matchMedia('(min-width: 768px)').matches ? {
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 'calc(0.375rem - 1px)'
    } : {};

    // Estilo para o container da imagem
    const imageContainerStyle = {
        maxHeight: '180px',
        overflow: 'hidden'
    };

    const [dataTopicos, setDataTopicos] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-topicos`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataTopicos(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token]);

    const handleThumbnailChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setPreviewUrl(e.target.result);
            }
            reader.readAsDataURL(file);
        }
    }

    const handleCertificadoChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setPreviewUrlCertificado(e.target.result);
            }
            reader.readAsDataURL(file);
        }
    }

    // listar os cursos
    const [dataCursos, setDataCursos] = useState([]);
    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const response = await Api.get(`api/listar-cursos`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataCursos(response.data);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        }
        fetchData();
    }, [token, showToast]);

    let filteredCourses = dataCursos.filter(course => course.nome.toLowerCase().includes(searchTerm.toLowerCase()));

    switch (order) {
        case "1": // Ordem alfabética
            filteredCourses.sort((a, b) => a.nome.localeCompare(b.nome));
            break;
        case "2": // Recentes
            filteredCourses.sort((a, b) => new Date(a.dataInicio) - new Date(b.dataInicio));
            break;
        case "3": // Finalizados
            filteredCourses = filteredCourses.filter(course => parseInt(course.porcentagem_concluida) === 100);
            break;
        case "4": // Em andamento
            filteredCourses = filteredCourses.filter(course => parseInt(course.porcentagem_concluida) < 100);
            break;
        default:
            break;
    }

    const displayedCourses = filteredCourses.filter(course => {
        if (!selectedTopic) return true;
        return course.idTopico === selectedTopic;
    });

    // listar usuarios
    const [dataGestor, setDataGestor] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-usuarios/nivel/Gestor/todos`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataGestor(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        nivel === "Administrador" && fetchData();
    }, [token, nivel]);

    const {
        register: register_curso,
        handleSubmit: handleSubmit_curso,
        control: control_curso,
        // reset: reset_curso,
        setValue: setValue_curso,
        formState: { errors: errors_curso },
    } = useForm();

    async function onSubmitCertificado(e) {
        e.preventDefault();

        const data = {
            id: e.target.id.value,
            certificado: e.target.certificado.files
        }

        const formData = new FormData();

        Object.keys(data).forEach(key => {
            const value = data[key];
            if (typeof value === 'object' && value !== null && !(value instanceof FileList)) {
                formData.append(key, JSON.stringify(value));
            } else if (value instanceof FileList) {
                Array.from(value).forEach(file => {
                    formData.append(key, file);
                });
            } else {
                formData.append(key, value);
            }

        });

        try {
            const response = await Api.post("/api/editar-certificado", formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setToast({ header: 'Sucesso!', body: response.data.message })
            setUploadCursoProgress(0)
            setShowModalCertificado(false)

        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao editar curso:", error.response.data.message);
        }

        setShowToast(true);

    };

    const onSubmitEditarCurso = async (data) => {
        const formData = new FormData();

        Object.keys(data).forEach(key => {
            const value = data[key];
            if (typeof value === 'object' && value !== null && !(value instanceof FileList)) {
                formData.append(key, JSON.stringify(value));
            } else if (value instanceof FileList) {
                Array.from(value).forEach(file => {
                    formData.append(key, file);
                });
            } else {
                formData.append(key, value);
            }

        });

        try {
            const response = await Api.post("/api/editar-curso", formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setUploadCursoProgress(percentCompleted + "%")
                    if (percentCompleted === 100) {
                        setTimeout(() => {
                            setUploadCursoProgress('Aguarde... finalizando...')
                        }, 1000);
                    }
                }
            });

            setToast({ header: 'Sucesso!', body: response.data.message })
            setUploadCursoProgress(0)
            setShowModalEditarCurso(false)

        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao editar curso:", error.response.data.message);
        }

        setShowToast(true);
    };

    const deletarCurso = async (id) => {
        try {
            const response = await Api.delete(`/api/deletar-curso/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message });
            // Remova o curso deletado do estado dataCursos para atualizar a UI
            const newDataCursos = dataCursos.filter(curso => curso.id !== id);
            setDataCursos(newDataCursos);
        } catch (error) {
            console.error("Erro ao deletar curso:", error.response.data.message);
            setToast({ header: 'Ops!', body: error.response.data.message });
        }
        setShowToast(true);
        setShowModalDeletarCurso(false);
    }

    const customStyles = {
        container: (base) => ({
            ...base,
            width: "100%",
        }),
        control: (base, state) => ({
            ...base,
            fontSize: "1rem",
            backgroundColor: "#ffffff",
            borderRadius: "0.25rem",
            boxShadow: state.isFocused ? "0 0 0 0.25rem #27485b73" : null,
            borderColor: "#233734",
            "&:hover": {
                borderColor: "#3a6883"
            },
        }),
        singleValue: (base) => ({
            ...base,
            color: '#000',
        }),
        input: (base) => ({
            ...base,
            color: '#adb5bd',
            height: "52px",
            margin: '0px',
            padding: '0px !important',

        }),
        menu: (base) => ({
            ...base,
            backgroundColor: '#fff',
        }),
        option: (base, state) => ({
            ...base,
            color: state.isSelected ? '#3a5a6c' : null,
            backgroundColor: state.isFocused ? '#27485b73' : null,
            "&:active": {
                backgroundColor: "#27485b73"
            },
        }),
    };

    const customStylesTopico = {
        container: (base) => ({
            ...base,
            width: "100%",
        }),
        control: (base, state) => ({
            ...base,
            fontSize: "1rem",
            backgroundColor: "#ffffff",
            boxShadow: state.isFocused ? "0 0 0 0.25rem #27485b73" : null,
            borderColor: "#dee2e6",
            "&:hover": {
                borderColor: "#dee2e6"
            },
        }),
        singleValue: (base) => ({
            ...base,
            color: '#000',
        }),
        valueContainer: (base) => ({
            ...base,
            padding: '0px 0px 0px 8px;',
        }),
        input: (base) => ({
            ...base,
            height: "48px",
            margin: '0px',
            padding: '0px !important',

        }),
        menu: (base) => ({
            ...base,
            backgroundColor: '#fff',
        }),
        option: (base, state) => ({
            ...base,
            color: state.isSelected ? '#3a5a6c' : null,
            backgroundColor: state.isFocused ? '#27485b73' : null,
            "&:active": {
                backgroundColor: "#27485b73"
            },
        }),
    };

    const optionsOrdenacao = [
        { value: "1", label: "Ordem alfabética" },
        { value: "2", label: "Recentes" },
        { value: "3", label: "Finalizados" },
        { value: "4", label: "Em andamento" }
    ];

    //Remover opção finalizados e Em andamento caso o nivel seja Administrador
    if (nivel === 'Administrador' || nivel === 'RH') {
        optionsOrdenacao.splice(2, 2);
    }

    const LoadingScreen = () => {
        return (
            <div className="d-flex align-items-center justify-content-center p-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando...</span>
                </div>
            </div>
        )
    }

    // Componente para visualização em lista
    const ListViewItem = ({ value }) => {
        const hoje = new Date();
        const dataInicio = new Date(value.dataInicio);
        const dataFinal = new Date(value.dataFinal);

        // Combinar os estilos base com os estilos específicos para o tamanho da tela
        const combinedImageStyle = {...listImageStyle, ...mediaQueryStyle};

        return (
            (nivel !== 'Aluno' || (hoje >= dataInicio && hoje <= dataFinal)) &&
            <div key={value.id} className={`col-12 mb-3 ${value.disponivel === 1 ? '' : 'd-none'}`} style={transitionStyle}>
                <div className="card h-100 border-0 shadow-sm">
                    <div className="row g-0">
                        <div className="col-md-3 position-relative">
                            <div style={imageContainerStyle} className="d-flex align-items-center justify-content-center">
                                <img src={`${process.env.REACT_APP_IMG_URL}/${value.thumbnail}`} 
                                    className="img-fluid d-block w-100" 
                                    style={combinedImageStyle} 
                                    alt="Course cover" />
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="card-body d-flex flex-column h-100">
                                <div className="d-flex justify-content-between align-items-start mb-2">
                                    <div>
                                        <h6 className="card-title fw-semibold text-primary mb-1">{value.nome}</h6>
                                        <span className='fs-7 fw-light badge bg-success'>{value.nomeTopico}</span>
                                    </div>
                                    
                                </div>
                                
                                <div className="d-flex flex-wrap gap-3 mb-2">
                                    <span className='d-flex align-items-center'>
                                        <i className="bi bi-book me-1 text-primary"></i>
                                        <small>{value.qtd_aulas} {value.qtd_aulas === 1 ? "Aula" : "Aulas"}</small>
                                    </span>
                                    <span className='d-flex align-items-center'>
                                        <i className="bi bi-bookmark-check-fill me-1 text-primary"></i>
                                        <small>{value.qtd_modulos} {value.qtd_modulos === 1 ? "Módulo" : "Módulos"}</small>
                                    </span>
                                    <span className='d-flex align-items-center'>
                                        <i className="bi bi-clock me-1 text-primary"></i>
                                        <small>Carga Horária: <span className='fw-bold'>{value.carga_horaria_total > 1 ? `${value.carga_horaria_total} horas` : `${value.carga_horaria_total} hora`}</span></small>
                                    </span>
                                </div>
                                
                                {nivel === 'Aluno' &&
                                    <div className="progress w-100 mb-2">
                                        <div className={`progress-bar ${parseInt(value.porcentagem_concluida) === 100 ? 'bg-success' : 'bg-primary'}`} style={{ width: parseInt(value.porcentagem_concluida) + '%' }}>{parseInt(value.porcentagem_concluida)}%</div>
                                    </div>
                                }
                                
                                <div className='fs-7 fw-light mb-2 text-muted'>Disponível até {formatDate(value.dataFinal)}</div>
                                
                                <div className="d-flex justify-content-between align-items-center gap-2 mt-auto">
                                    <Link to={`/modulos/${value.id}`} className="btn btn-primary text-white rounded py-1 px-4">
                                        Acessar
                                    </Link>
                                    {nivel === 'Administrador' && (
                                        <div className="d-flex gap-2">
                                            <Tippy content="Editar Curso">
                                                <span onClick={() => {
                                                    setShowModalEditarCurso(true)
                                                    setValue_curso('id', value.id)
                                                    setValue_curso('nome', value.nome)
                                                    setValue_curso('nome_certificado', value.nome_certificado)
                                                    setValue_curso('descricao_certificado', value.descricao_certificado)
                                                    setValue_curso('descricao', value.descricao)
                                                    setValue_curso('resumo', value.resumo)
                                                    setValue_curso('thumbnail', value.thumbnail)
                                                    setValue_curso('tipo', value.tipo)
                                                    setValue_curso('dataInicio', value.dataInicio)
                                                    setValue_curso('dataFinal', value.dataFinal)
                                                    const selectedGestor = dataGestor.find(tutor => tutor.id === value.idGestor);
                                                    setValue_curso('idGestor', { value: selectedGestor.id, label: selectedGestor.nome_completo })
                                                    const selectedTopico = dataTopicos.find(topicos => topicos.id === value.idTopico);
                                                    setValue_curso('idTopico', { value: selectedTopico.id, label: selectedTopico.nome })
                                                    setValue_curso('prazo_validade', value.prazo_validade)
                                                    setCursoEditarFile(null)
                                                    setThumbEdit(value.thumbnail)
                                                    setUploadCursoProgress(0)
                                                    setPreviewUrl(null)
                                                }} className="d-flex align-items-center rounded link bg-white text-primary py-1 px-2 cursor-pointer">
                                                    <i className="bi bi-pencil"></i>
                                                </span>
                                            </Tippy>

                                            <Tippy content="Excluir Curso">
                                                <span onClick={() => { setShowModalDeletarCurso(true); setCursoInfo({ id: value.id, nome: value.nome }) }} className="d-flex align-items-center rounded link bg-white text-primary py-1 px-2 cursor-pointer">
                                                    <i className="bi bi-trash"></i>
                                                </span>
                                            </Tippy>

                                            <Tippy content={value.certificado !== null ? 'Editar Certificado' : 'Adicionar Certificado'}>
                                                <span onClick={() => { setShowModalCertificado(true); setCertificadoEdit(value.certificado); setCursoEditarCertificado(null); setPreviewUrlCertificado(null); setCursoInfo({ id: value.id, nome: value.nome, certificado: value.certificado }) }} className={`d-flex align-items-center rounded link py-1 px-2 cursor-pointer ${value.certificado !== null ? 'bg-success text-white link-white' : 'bg-white text-dark'}`}>
                                                    <i className="bi bi-patch-check"></i>
                                                </span>
                                            </Tippy>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    // Componente para visualização em grid (mantém o layout original)
    const GridViewItem = ({ value }) => {
        const hoje = new Date();
        const dataInicio = new Date(value.dataInicio);
        const dataFinal = new Date(value.dataFinal);

        return (
            (nivel !== 'Aluno' || (hoje >= dataInicio && hoje <= dataFinal)) &&
            <div key={value.id} className={`col-md-12 col-lg-6 col-xxl-4 ${value.disponivel === 1 ? '' : 'd-none'}`} style={transitionStyle}>
                <div className="mb-4">
                    <div className="card text-white h-100">
                        <div className="card border-0">
                            <img src={`${process.env.REACT_APP_IMG_URL}/${value.thumbnail}`} className="card-img img-banner border-bottom-radius-0" style={{ height: '200px', objectFit: 'cover' }} alt="Course cover" />
                            <div className="card-img-overlay gradient border-bottom-radius-0 d-flex flex-column justify-content-between py-2">
                                <div>
                                    <h5 className="fs-6 fw-semibold text-white mb-2">{value.nome}</h5>
                                    <div className="d-flex flex-wrap gap-2 mb-2">
                                        <span className='d-flex align-items-center text-white'>
                                            <i className="bi bi-book me-1"></i>
                                            <small className="text-white">{value.qtd_aulas} {value.qtd_aulas === 1 ? "Aula" : "Aulas"}</small>
                                        </span>
                                        <span className='d-flex align-items-center text-white'>
                                            <i className="bi bi-bookmark-check-fill me-1"></i>
                                            <small className="text-white">{value.qtd_modulos} {value.qtd_modulos === 1 ? "Módulo" : "Módulos"}</small>
                                        </span>
                                    </div>
                                    <span className='fs-7 fw-light badge bg-success'>{value.nomeTopico}</span>
                                </div>
                                
                                <div className='d-flex flex-wrap justify-content-between align-items-center gap-2'>
                                    <span className='fs-7 fw-normal text-white'>
                                        Carga Horária: <span className='fw-bold'>{value.carga_horaria_total > 1 ? `${value.carga_horaria_total} horas` : `${value.carga_horaria_total} hora`}</span>
                                    </span>
                                    <span className='badge bg-light text-dark'>{value.tipo === 'online' ? 'ONLINE' : 'HÍBRIDO'}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-dark py-2">
                            {nivel === 'Aluno' &&
                                <div className="progress w-100 mb-2">
                                    <div className={`progress-bar ${parseInt(value.porcentagem_concluida) === 100 ? 'bg-success' : 'bg-primary'}`} style={{ width: parseInt(value.porcentagem_concluida) + '%' }}>{parseInt(value.porcentagem_concluida)}%</div>
                                </div>
                            }

                            <div className='fs-7 fw-light mb-2 text-white-50'>Disponível até {formatDate(value.dataFinal)}</div>

                            <div className="d-flex justify-content-between align-items-center gap-2">
                                <Link to={`/modulos/${value.id}`} className="btn btn-outline-light link rounded py-1 flex-grow-1">
                                    Acessar
                                </Link>
                                {nivel === 'Administrador' && (
                                    <div className="d-flex gap-2">
                                        <Tippy content="Editar Curso">
                                            <span onClick={() => {
                                                setShowModalEditarCurso(true)
                                                setValue_curso('id', value.id)
                                                setValue_curso('nome', value.nome)
                                                setValue_curso('nome_certificado', value.nome_certificado)
                                                setValue_curso('descricao_certificado', value.descricao_certificado)
                                                setValue_curso('descricao', value.descricao)
                                                setValue_curso('resumo', value.resumo)
                                                setValue_curso('thumbnail', value.thumbnail)
                                                setValue_curso('tipo', value.tipo)
                                                setValue_curso('dataInicio', value.dataInicio)
                                                setValue_curso('dataFinal', value.dataFinal)
                                                const selectedGestor = dataGestor.find(tutor => tutor.id === value.idGestor);
                                                setValue_curso('idGestor', { value: selectedGestor.id, label: selectedGestor.nome_completo })
                                                const selectedTopico = dataTopicos.find(topicos => topicos.id === value.idTopico);
                                                setValue_curso('idTopico', { value: selectedTopico.id, label: selectedTopico.nome })
                                                setValue_curso('prazo_validade', value.prazo_validade)
                                                setCursoEditarFile(null)
                                                setThumbEdit(value.thumbnail)
                                                setUploadCursoProgress(0)
                                                setPreviewUrl(null)
                                            }} className="d-flex align-items-center rounded link bg-white text-primary py-1 px-2 cursor-pointer">
                                                <i className="bi bi-pencil"></i>
                                            </span>
                                        </Tippy>

                                        <Tippy content="Excluir Curso">
                                            <span onClick={() => { setShowModalDeletarCurso(true); setCursoInfo({ id: value.id, nome: value.nome }) }} className="d-flex align-items-center rounded link bg-white text-primary py-1 px-2 cursor-pointer">
                                                <i className="bi bi-trash"></i>
                                            </span>
                                        </Tippy>

                                        <Tippy content={value.certificado !== null ? 'Editar Certificado' : 'Adicionar Certificado'}>
                                            <span onClick={() => { setShowModalCertificado(true); setCertificadoEdit(value.certificado); setCursoEditarCertificado(null); setPreviewUrlCertificado(null); setCursoInfo({ id: value.id, nome: value.nome, certificado: value.certificado }) }} className={`d-flex align-items-center rounded link py-1 px-2 cursor-pointer ${value.certificado !== null ? 'bg-success text-white link-white' : 'bg-white text-dark'}`}>
                                                <i className="bi bi-patch-check"></i>
                                            </span>
                                        </Tippy>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide style={{ position: 'fixed', left: '95%', bottom: '10px', transform: 'translateX(-95%)', zIndex: 9999 }}>
                <Toast.Header>
                    <strong className="mr-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>

            <div className="fade-in w-100 mt-3" style={{ marginBottom: '100px' }}>
                <section className="w-100">
                    <div className="row d-flex align-items-start justify-content-between">

                        <div className="col-md-5 mb-3 mb-md-0">
                            <label className="form-label text-primary small mb-1">Pesquisar cursos</label>
                            <div className="inner-addon right-addon">
                                <i className="d-flex bi bi-search" />
                                <input
                                    type="search"
                                    placeholder="Pesquise o curso desejado...."
                                    className="form-control py-2"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="d-flex flex-column col-md-3 col-sm-6 mb-3 mb-md-0">
                            <label className="form-label text-primary small mb-1">Filtrar por tema</label>
                            <Select
                                options={dataTopicos.map((value) => {
                                    return {
                                        value: value.id,
                                        label: `${value.nome}`,
                                    };
                                })}
                                onChange={(option) => setSelectedTopic(option ? option.value : null)}
                                styles={customStylesTopico}
                                rules={{ required: true }}
                                isClearable={true}
                                placeholder="Selecione o tema"
                                noOptionsMessage={() => "Nenhum tema encontrado."}
                            />
                        </div>

                        <div className="d-flex flex-column col-md-2 col-sm-6 mb-3 mb-md-0">
                            <label className="form-label text-primary small mb-1">Ordenar por</label>
                            <Controller
                                name="ordenacao"
                                control={control_curso}
                                defaultValue={order}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={optionsOrdenacao.map((option) => ({
                                            value: option.value,
                                            label: option.label,
                                        }))}
                                        value={optionsOrdenacao.find(option => option.value === field.value)}
                                        styles={customStylesTopico}
                                        onChange={(selectedOption) => {
                                            field.onChange(selectedOption.value);
                                            setOrder(selectedOption.value);
                                        }}
                                        placeholder="Ordenação"
                                    />
                                )}
                            />
                        </div>

                        {/* Mostrar os botões de alternância apenas em desktop */}
                        {!isMobile && (
                            <div className="d-flex flex-column col-md-2 col-sm-6 mb-3 mb-md-0">
                                <label className="form-label text-primary small mb-1">Visualização</label>
                                <div className="btn-group w-100">
                                    <Tippy content="Visualização em Grid">
                                        <button 
                                            type="button" 
                                            className={`btn ${viewMode === 'grid' ? 'btn-primary text-white' : 'btn-outline-primary'} py-2`}
                                            onClick={() => {
                                                setViewMode('grid');
                                                localStorage.setItem('viewMode', 'grid');
                                            }}
                                        >
                                            <i className="bi bi-grid-3x3-gap-fill"></i>
                                        </button>
                                    </Tippy>
                                    <Tippy content="Visualização em Lista">
                                        <button 
                                            type="button" 
                                            className={`btn ${viewMode === 'list' ? 'btn-primary text-white' : 'btn-outline-primary'} py-2`}
                                            onClick={() => {
                                                setViewMode('list');
                                                localStorage.setItem('viewMode', 'list');
                                            }}
                                        >
                                            <i className="bi bi-list-ul"></i>
                                        </button>
                                    </Tippy>
                                </div>
                            </div>
                        )}
                    </div>
                </section>

                <section className="clearfix w-100 mt-4 d-block" style={{ marginBottom: '100px' }}>
                    <p className='fs-2 text-primary mb-3'>Meus Cursos</p>
                    <div className="row mt-2 transition-container" style={containerTransitionStyle}>
                        {loading ? (
                            <LoadingScreen />
                        ) : (
                            displayedCourses.map((value) => (
                                (isMobile || viewMode === 'list') 
                                ? <ListViewItem key={value.id} value={value} /> 
                                : <GridViewItem key={value.id} value={value} />
                            ))
                        )}
                    </div>
                </section>
            </div>

            <Modal size="lg" show={showModalEditarCurso} onHide={() => setShowModalEditarCurso(false)}>
                <form onSubmit={handleSubmit_curso(onSubmitEditarCurso)}>
                    <Modal.Header closeButton>
                        <span className="fw-semibold fs-4 text-primary">Editar Curso</span>
                    </Modal.Header>
                    <Modal.Body>
                        <input type="hidden" {...register_curso('id', { required: true })} />
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="body">
                                        <div className="d-flex mb-5">
                                            <div className="d-flex flex-column w-100">
                                                <div className="row">

                                                    <div className="col-12 col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Nome</label>
                                                            <input type="text" {...register_curso('nome', { required: true })} placeholder="Nome do curso" className="form-control border-primary" />
                                                            {errors_curso.nome && <span className='text-primary fs-7'>Nome do curso é obrigatório.</span>}
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Descrição</label>
                                                            <TextareaAutosize minRows={1.5} {...register_curso('descricao')} placeholder="Breve descrição do curso" className="form-control border-primary"></TextareaAutosize>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Tema</label>
                                                            <Controller
                                                                name="idTopico"
                                                                control={control_curso}
                                                                defaultValue={[]}
                                                                rules={{ required: true }}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        options={dataTopicos.map((value) => {
                                                                            return {
                                                                                value: value.id,
                                                                                label: `${value.nome}`,
                                                                            };
                                                                        })}
                                                                        value={field.value}
                                                                        styles={customStyles}
                                                                        rules={{ required: true }}
                                                                        onChange={value => {
                                                                            field.onChange(value)
                                                                        }}
                                                                        placeholder="Selecione o tema"
                                                                        noOptionsMessage={() => "Nenhum tema encontrado."}
                                                                    />
                                                                )}
                                                            />
                                                            {errors_curso.topico && <span className='text-primary fs-7'>Selecione um tema.</span>}
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-4">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Thumbnail</label>
                                                            <label htmlFor="thumbnail" className={`form-control label-input ${cursoEditarFile ? 'border-success bg-success' : 'border-primary bg-primary'} text-white fw-medium text-center`}><i className="bi bi-card-image me-2"></i> Trocar Imagem</label>
                                                            <Controller
                                                                control={control_curso}
                                                                name="thumbnail"
                                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                    <input
                                                                        id='thumbnail'
                                                                        type="file"
                                                                        onBlur={onBlur}
                                                                        ref={ref}
                                                                        onChange={(e) => {
                                                                            const files = e.target.files;
                                                                            handleThumbnailChange(e);
                                                                            onChange(files);
                                                                            if (files && files.length > 0) {
                                                                                setCursoEditarFile(true);
                                                                            } else {
                                                                                setCursoEditarFile(false);
                                                                            }
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            {errors_curso.thumbnail && <span className='text-primary fs-7'>Imagem do curso é obrigatório.</span>}
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-8">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Gestor</label>
                                                            <Controller
                                                                name="idGestor"
                                                                control={control_curso}
                                                                rules={{ required: true }}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        options={dataGestor.map((value) => {
                                                                            return {
                                                                                value: value.id,
                                                                                label: `${value.nome_completo}`,
                                                                            };
                                                                        })}
                                                                        value={field.value}
                                                                        styles={customStyles}
                                                                        onChange={value => field.onChange(value)}
                                                                        placeholder="Selecione o Gestor"
                                                                        isSearchable
                                                                        isDisabled={nivel === 'Gestor'}  // Desativa o Select se o nível for 'Gestor'
                                                                        noOptionsMessage={() => "Nenhum gestor encontrado."}
                                                                    />
                                                                )}
                                                            />
                                                            {errors_curso.idGestor && <span className='text-primary fs-7'>Gestor é obrigatório.</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="mb-3">
                                                            {previewUrl ?
                                                                <img src={previewUrl} className='card-img card-img-edit rounded-3' alt="Thumbnail" />
                                                                :
                                                                <img src={`${process.env.REACT_APP_IMG_URL}/${thumbEdit}`} className='card-img card-img-edit rounded-3' alt="Thumbnail" />
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Resumo</label>
                                                            <TextareaAutosize {...register_curso('resumo')} placeholder="Informações gerais sobre o curso" className="form-control border-primary"></TextareaAutosize>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-4">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Disponibilidade do curso</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-8">
                                                        <div className="mb-3">
                                                            <div className="d-flex justify-content-between">
                                                                <div className='w-50 pe-2'>
                                                                    <label className="form-label d-block text-dark">Data de Início</label>
                                                                    <input
                                                                        type="date"
                                                                        {...register_curso('dataInicio', { required: true })}
                                                                        className="form-control border-primary"
                                                                    />
                                                                    {errors_curso.dataInicio && <span className='text-primary fs-7'>Data de início é obrigatória.</span>}
                                                                </div>
                                                                <div className='w-50'>
                                                                    <label className="form-label d-block text-dark">Data Final</label>
                                                                    <input
                                                                        type="date"
                                                                        {...register_curso('dataFinal', { required: true })}
                                                                        className="form-control border-primary"
                                                                    />
                                                                    {errors_curso.dataFinal && <span className='text-primary fs-7'>Data final é obrigatória.</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <div className="mb-1">
                                                            <label className="form-label text-primary fw-medium">Tipo do curso</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-8">
                                                        <div className="mb-3">
                                                            <div className="d-flex justify-content-between">
                                                                <div className='w-50 pe-2'>
                                                                    <label className="form-label d-block text-dark">Híbrido</label>
                                                                    <input
                                                                        type="radio"
                                                                        {...register_curso('tipo', { required: true })}
                                                                        value="misto"
                                                                        className="form-check-input"
                                                                    />
                                                                </div>
                                                                <div className='w-50'>
                                                                    <label className="form-label d-block text-dark">Online</label>
                                                                    <input
                                                                        type="radio"
                                                                        {...register_curso('tipo', { required: true })}
                                                                        value="online"
                                                                        className="form-check-input"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {errors_curso.tipo && <span className='text-primary fs-7'>Tipo do curso é obrigatório.</span>}
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Prazo de Validade <span className="text-dark">(em meses)</span></label>
                                                            <input
                                                                type="number"
                                                                {...register_curso("prazo_validade", { required: true })}
                                                                placeholder="Ex: 12"
                                                                className="form-control border-primary"
                                                            />
                                                            {errors_curso.prazo_validade && <span className='text-primary fs-7'>Prazo de Validade é obrigatório.</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="progress col-12">
                                                    <div className="progress-bar" role="progressbar" style={{ width: `${uploadCursoProgress}` }} aria-valuenow={uploadCursoProgress} aria-valuemin="0" aria-valuemax="100">{uploadCursoProgress}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center bg-light">
                        <button type="submit" className="btn btn-primary text-white px-5 py-2">Salvar</button>
                        <button type="button" className="btn btn-outline-primary text-primary px-5 py-2" onClick={() => setShowModalEditarCurso(false)}> Cancelar </button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal size="lg" show={showModalCertificado} onHide={() => setShowModalCertificado(false)}>
                <form onSubmit={(e) => onSubmitCertificado(e)}>
                    <Modal.Header closeButton>
                        <span className="fw-semibold fs-4 text-primary">Editar Certificado</span>
                    </Modal.Header>
                    <Modal.Body>
                        <input type="hidden" id="id" value={cursoInfo.id} />
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="body">
                                        <div className="d-flex mb-5">
                                            <div className="d-flex flex-column w-100">
                                                <div className="row">

                                                    <div className="col-12 col-md-4">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Conteúdo programático</label>
                                                            <label htmlFor="certificado" className={`form-control label-input ${cursoEditarCertificado ? 'border-success bg-success' : 'border-primary bg-primary'} text-white fw-medium text-center`}><i className="bi bi-card-image me-2"></i> Fazer Upload</label>
                                                            <input
                                                                id='certificado'
                                                                type="file"
                                                                onChange={(e) => {
                                                                    const files = e.target.files;
                                                                    handleCertificadoChange(e); // Você precisa definir essa função para tratar a mudança de estado
                                                                    if (files && files.length > 0) {
                                                                        setCursoEditarCertificado(true);
                                                                        setShowButtonCertificado(true);
                                                                    } else {
                                                                        setCursoEditarCertificado(false);
                                                                        setShowButtonCertificado(false);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="mb-3">
                                                            {previewUrlCertificado ?
                                                                <img src={previewUrlCertificado} className='card-img card-img-edit rounded-3' alt="Certificado" />
                                                                :
                                                                <img src={`${process.env.REACT_APP_IMG_URL}/${certificadoEdit}`} className='card-img card-img-edit rounded-3' alt="Certificado" />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center bg-light">
                        <button type="submit" className={`btn btn-primary text-white px-5 py-2 ${showButtonCertificado ? '' : 'd-none'}`}>Salvar</button>
                        <button type="button" className="btn btn-outline-primary text-primary px-5 py-2" onClick={() => setShowModalCertificado(false)}> Cancelar </button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal size='lg' show={showModalDeletarCurso} onHide={() => setShowModalDeletarCurso(false)}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Deletar curso</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="body">
                                    <div className="d-flex">
                                        <div className="d-flex flex-column w-100">
                                            <div className="row">
                                                <div className="col-12">
                                                    <p>Tem certeza que deseja deletar o curso <b>{cursoInfo.nome}</b>?</p>
                                                    <p>Essa ação ira deletar o curso e todos seus módulos e aulas, e não poderá ser revertida.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center bg-light">
                    <button type="submit" className="btn btn-primary text-white px-5 py-2" onClick={() => deletarCurso(cursoInfo.id)}>Confirmar</button>
                    <button type="button" className="btn btn-outline-primary text-primary px-5 py-2" onClick={() => setShowModalDeletarCurso(false)}>Cancelar</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default MeusCursos;