import { useState, useEffect, useMemo, useCallback } from "react";
import Api from "../Auth/Api";
import { pdf } from "@react-pdf/renderer";
import GerarHistoricoEscolar from "../Components/GerarHistoricoEscolar";
import TableHistoricoEscolar from "../Components/TableHistoricoEscolar";
import { Modal } from "react-bootstrap";
import Tippy from "@tippyjs/react";
import { formatDateTime } from "../Components/jwtUtils";

function HistoricoEscolar() {

    const token = JSON.parse(localStorage.getItem("user_token"));
    const [selectedTipo, setSelectedTipo] = useState('favoritos');

    const filterTipo = [
        { value: 'favoritos', label: 'Favoritos' },
        { value: 'todos', label: 'Todos' }
    ]

    //listar todos os cursos
    const [dataTopicos, setDataTopicos] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`/api/listar-topicos`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataTopicos(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token])

    // listar certificados
    const [dataHistoricoEscolar, setDataHistoricoEscolar] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`/api/listar-historico-escolar/${selectedTipo}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataHistoricoEscolar(response.data);
            } catch (error) {
                setDataHistoricoEscolar([]); // Se não tiver dados, limpa a lista
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token, selectedTipo])

    // Filtro para seleção por curso
    const [selectTopico, setSelectTopico] = useState(null);
    const FilterDataProvas = selectTopico ? dataHistoricoEscolar.filter(item => item.idTopico === selectTopico) : dataHistoricoEscolar;

    // Gerar PDF
    const loadPDFData = useCallback(async (data) => {
        try {
            // Buscar os dados para gerar o PDF
            const response = await Api.get(`api/info-historico-escolar/${data.idUsuario}/${data.idTopico}`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            // Gerar o PDF e obter como Blob
            const blob = await pdf(<GerarHistoricoEscolar data={response.data} />).toBlob();

            // Criar uma URL para o Blob e abrir em uma nova aba
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');  // Abre o PDF em uma nova guia
        } catch (error) {
            console.error(error.response.data.message);
        }
    }, [token]);

    const [showModalDetalhes, setShowModalDetalhes] = useState(false);
    const [historicoSelecionado, setHistoricoSelecionado] = useState(null);
    const [detalhesHistorico, setDetalhesHistorico] = useState(null);

    const carregarDetalhesHistorico = useCallback(async (idUsuario, idTopico) => {
        try {
            const response = await Api.get(`api/info-historico-escolar/${idUsuario}/${idTopico}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setDetalhesHistorico(response.data);
            setShowModalDetalhes(true);
        } catch (error) {
            console.error(error.response.data.message);
        }
    }, [token]);

    const columns = useMemo(
        () => [
            {
                Header: () => null, // Sem título para esta coluna
                id: 'foto',
                accessor: row => row.foto,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className="d-flex align-items-center justify-content-center">
                        {value ? <img className="object-fit-cover rounded-5" src={`${process.env.REACT_APP_API_URL}/fotos/${value}`} alt="Profile" style={{ height: '42px', width: '42px' }} /> : <i className="bi bi-person-circle fs-2" />}
                    </div>
                )
            },
            {
                Header: 'Aluno',
                accessor: 'nomeAluno'
            },
            {
                Header: 'Topico',
                accessor: 'nomeTopico'
            },
            {
                Header: () => null,
                id: 'acoes',
                accessor: row => row.id,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className="d-flex align-items-center justify-content-center gap-2">
                        <Tippy content="Baixar Histórico">
                            <button
                                onClick={() => loadPDFData({
                                    idUsuario: original.idAluno,
                                    idTopico: original.idTopico,
                                    topico: original.nomeTopico
                                })}
                                className="btn btn-outline-primary text-primary rounded px-2"
                            > 
                                <i className="bi bi-box-arrow-in-down"></i>
                            </button>
                        </Tippy>
                        <Tippy content="Ver Detalhes">
                            <button
                                onClick={() => {
                                    setHistoricoSelecionado(original);
                                    carregarDetalhesHistorico(original.idAluno, original.idTopico);
                                }}
                                className="btn btn-outline-primary text-primary rounded px-2"
                            >
                                <i className="bi bi-eye"></i>
                            </button>
                        </Tippy>
                    </div>
                )
            }
        ],
        [loadPDFData, carregarDetalhesHistorico]
    )

    return (
        <>
            <div className="fade-in w-100 mt-3" style={{ marginBottom: '100px' }}>
                <span className="text-primary fs-1">Histórico Escolar</span>
                <TableHistoricoEscolar columns={columns} data={FilterDataProvas} filterSelect={dataTopicos} setSelectTopico={setSelectTopico} filterTipo={filterTipo} setSelectedTipo={setSelectedTipo} titulo="Lista de histórico escolar" />
            </div>

            <Modal size="lg" show={showModalDetalhes} onHide={() => setShowModalDetalhes(false)}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Detalhes do Histórico Escolar</span>
                </Modal.Header>
                <Modal.Body>
                    {detalhesHistorico && (
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card border-0 shadow-sm">
                                        <div className="card-body">
                                            <div className="mb-4">
                                                <h5 className="text-primary">Informações do Aluno</h5>
                                                <p className="mb-1"><strong>Nome:</strong> {detalhesHistorico.nomeAluno}</p>
                                                <p className="mb-1"><strong>Código:</strong> {detalhesHistorico.codigo}</p>
                                                <p className="mb-1"><strong>Unidade:</strong> {detalhesHistorico.unidade}</p>
                                            </div>

                                            {detalhesHistorico.topico && detalhesHistorico.topico.map(topic => (
                                                <div key={topic.idTopico} className="mb-4">
                                                    <h5 className="text-primary">Tópico: {topic.nomeTopico}</h5>
                                                    
                                                    {topic.cursos && topic.cursos.map(course => (
                                                        <div key={course.idCurso} className="mb-3">
                                                            <div className="card bg-light">
                                                                <div className="card-header">
                                                                    <h6 className="mb-0">{course.nomeCurso}</h6>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-sm">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Módulo</th>
                                                                                    <th className="text-center">Carga Horária</th>
                                                                                    <th className="text-center">Nota Final</th>
                                                                                    <th>Período</th>
                                                                                    <th className="text-center">Situação</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {course.modulos && course.modulos.map(modulo => (
                                                                                    <tr key={modulo.idModulo}>
                                                                                        <td>{modulo.nomeModulo}</td>
                                                                                        <td className="text-center">{modulo.cargaHoraria.split(':')[0] || '-'}</td>
                                                                                        <td className="text-center">{modulo.nota !== null ? modulo.nota : "CUR"}</td>
                                                                                        <td>{formatDateTime(course.dataInicio)} até {formatDateTime(course.dataFinal)}</td>
                                                                                        <td className="text-center">
                                                                                            <span className={`badge ${modulo.situacao === 'Aprovado' ? 'bg-success' : 'bg-danger'}`}>
                                                                                                {modulo.situacao}
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer className="bg-light">
                    <div className="d-flex justify-content-center gap-2">
                        <button 
                            className="btn btn-primary text-white px-4"
                            onClick={() => loadPDFData({
                                idUsuario: historicoSelecionado.idAluno,
                                idTopico: historicoSelecionado.idTopico,
                                topico: historicoSelecionado.nomeTopico
                            })}
                        >
                            Baixar Histórico
                        </button>
                        <button 
                            className="btn btn-outline-primary"
                            onClick={() => setShowModalDetalhes(false)}
                        >
                            Fechar
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default HistoricoEscolar;