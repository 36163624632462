import { useState, useEffect } from "react";
import Api from "../../Auth/Api";
import { formatDate, truncateString } from "../../Components/jwtUtils";
import { Link } from "react-router-dom";
import { useAjustes } from '../../Context/AjustesContext';

function DashboardAluno() {

    const token = JSON.parse(localStorage.getItem("user_token"));
    const nome_completo = localStorage.getItem("nome_completo");
    const { ajustes } = useAjustes();

    const [dataCursos, setDataCursos] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-cursos`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataCursos(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [token]);

    const [dataNotificacoes, setDataNotificacoes] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`/api/notificacoes`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataNotificacoes(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token])

    return (
        <div className="fade-in w-100 mb-5">
            <div className="row">
                <div className="col-12">
                    <div className="card card-flag-aluno rounded-5 w-100 d-none d-md-block overflow-hidden mb-4 border-none">
                        <div className="position-absolute w-100 h-100" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
                        <img 
                            src={ajustes.flag ? ajustes.flag : "./../assets/flag.png"} 
                            className="card-img w-100 card-img card-img-banner" 
                            alt="OK" 
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "./../assets/flag.png";
                            }}
                        />
                        <div className="card-img-overlay d-flex flex-column justify-content-center">
                            <div className="text-white fs-1 fw-bold text-center mb-3">
                                Seja bem vindo!
                                <p className="fs-6 fw-normal">{nome_completo}</p>
                            </div>
                        </div>
                    </div>

                    <div className="mt-3">
                        <span className="text-primary fs-2 fw-bold">Meus Cursos</span>
                    </div>
                    
                    <div className="body rounded py-3" style={{marginBottom: '100px'}}>
                        <section className="w-100">
                            <div className="row">

                                {/* Lado esquerdo - Cursos */}
                                <div className="col-md-8 col-12">   

                                    {dataCursos && dataCursos.map((value) => (
                                        <div key={value.id} className={`border-0 p-3 shadow mb-3 rounded-3 ${value.disponivel === 1 ? '' : 'd-none'}`}>
                                            <div className="d-flex flex-column flex-md-row align-items-center">
                                                <div className="flex-shrink-0 mb-3 mb-md-0" style={{ width: 'auto', height: '120px' }}>
                                                    <img src={`${process.env.REACT_APP_IMG_URL}/${value.thumbnail}`} alt="Curso Imagem" className="box-card-curso rounded-3" />
                                                </div>
                                                <div className="flex-grow-1 ms-md-3">
                                                    <div className="d-flex justify-content-between align-items-start mb-2">
                                                        <span className="card-title mb-1 position-relative fs-5 fw-bold">{value.nome}</span>
                                                    </div>
                                                    <p className="card-text position-relative">{truncateString(value.resumo)}</p>
                                                    <p className="card-text fs-7 text-end border-1 border-top pt-1">Disponibilidade: {formatDate(value.dataInicio)} - {formatDate(value.dataFinal)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>

                                {/* Lado direito - Aulas Adicionadas */}
                                <div className="col-md-4 col-12">
                                    <div className="rounded bg-primary flex-fill text-white p-4">
                                        <span className="d-flex justify-content-center fs-5 fw-500 text-center">Atividades pendentes</span>
                                        <div>
                                            {dataNotificacoes.notificacoes && dataNotificacoes.notificacoes.map((value) => (
                                                <div key={value.id} className="d-flex flex-column rounded bg-white text-dark p-3 my-2">
                                                    <span className="fw-normal fs-6 text-pretty">{value.nome_avaliacao}</span>
                                                    <span className="fw-normal fs-7 text-pretty text-dark">{value.nome_modulo}</span>
                                                    <Link className="fw-bold fs-7 text-nowrap cursor-pointer text-end text-dark" to={`/aulas/${value.id_modulo}`}>Acessar</Link>
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>

                    </div>





                </div>

            </div>
        </div >
    )
}

export default DashboardAluno;